import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, centered } from '../../theme'

const useStyles = createUseStyles({
  chip: {
    display: 'flex',
    flexDirection: 'column',
    margin: [0, -15],
    padding: [3, 5],
    width: '100%',
    minWidth: 327,
    height: 'fit-content',
    borderRadius: 5,
    background: colors.dark_grey,
  },
  chipRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    '& > p': {
      width: '100%',
      fontSize: 11,
      lineHeight: '130%',
      display: 'flex',
      justifyContent: 'space-between',
      color: colors.background_white,
      verticalAlign: '-1px',
      '& > strong': {
        fontSize: 12,
        fontWeight: 300
      }
    }
  },
  jCenter: {
    justifyContent: 'center!important'
  }
})

export const PodcastMetaChip = ({
  className,
  email,
  userId,
  prefixUrl,
  podcastId,
  podcastStatus
}) => {
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.chip, className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.chipRow}>
        <p className={classes.jCenter}><span>{prefixUrl}</span></p>
        <p><strong>podcast_id:</strong><span>{podcastId}</span></p>
      </div>
      <div className={classes.chipRow}>
        <p><strong>email:</strong><span>{email}</span></p>
        <p><strong>user_id:</strong><span>{userId}</span></p>
        <p><strong>status:</strong><span>{podcastStatus}</span></p>
      </div>
    </div>
  )
}