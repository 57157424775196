import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  block: {
    flexWrap: 'wrap',
    display: 'flex',
    padding: [8, 16],
    borderRadius: 8,
    border: [1, 'solid', colors.input_border],
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
    '& p': {
      color: colors.dark_grey,
      fontSize: 14,
      fontWeight: '130%',
    }
  },
  chips: {
    borderRadius: 50,
    padding: [10, 16],
    background: colors.background_white,
    margin: [5, 16, 0, 0],
    textWrap: 'nowrap',
    '& p': {
      color: colors.text_black,
      fontSize: 12,
      fontWeight: '150%',
    }
  }
})

export const CategoriesBlock = ({
  title,
  categories,
}) => {
  const classes = useStyles()

  return <div className={classes.block}>
    <div className={classes.title}>
      <p><strong>{title}</strong></p>
    </div>
    {categories && categories.map((cat, idx) => {
      return <div key={idx} className={classes.chips}>
        <p>{cat}</p>
      </div>
    })}
  </div>
}
