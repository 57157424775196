import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../theme'
import { Template, Page } from "../../containers";
import { BreadCrumbs, Button, Checkbox, Input, Loader, Notification } from '../../components'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useActions } from "../../hooks/useActions"
import { H1, H4, H3 } from '../../components/Typography'
import { isEmpty } from 'lodash'
import { EpisodeCard } from '../PodcastPage/EpisodeCard'
import { InteractiveSoundwave } from './InteractiveSoundwave'
import { AudioPlayer } from '../../components'
import { transformTimeMaskToMs, generateAudioLink } from '../../utils'

const useStyles = createUseStyles({
  buttonBlock: {
    display: 'flex',
    alignItems: 'end',
    margin: [20, 0]
  },
  addPlacementBlock: {
    display: 'flex',
    marginRight: 50,
  },
  offsetInput: {
    marginRight: 71,
    '& input': {
      width: 200,
    }
  },
  placementWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& input': {
      width: 200,
      marginRight: 72,
    }
  },
  placementWrapperNew: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    '& input': {
      width: 200,
      marginRight: 10,
    },
    '& > span': {
      backgroundColor: colors.primary_green,
      color: 'white',
      borderRadius: 8,
      marginRight: 10,
      padding: [5, 10]
    }
  },
  btn: {
    marginRight: 20
  },
  link: {
    color: colors.dark_grey,
  },
  cta: {
    marginTop: 5
  },
  placement: {
    display: 'flex'
  },
  card: {
    background: colors.light_grey,
    marginTop: 24,
    width: 1390
  },
  autoOpt: {
    marginTop: 22,
    width: 'fit-content'
  },
  rolls: {
    display: 'flex',
    width: 200,
    height: 30,
    justifyContent: 'space-between',
    margin: [22, 0]
  },
  placementList: {
    margin: [23, 0, 0, 0]
  },
  placeholder: {
    margin: [20, 0, 50, 0]
  },
  soundwaveBlock: {
    position: 'relative',
    height: 145,
    width: 1390,
    display: 'block',
    width: '100%',
    borderRadius: 8,
    marginBottom: 50,
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 1390,
      height: 145,
      borderRadius: 8,
    }
  },
  wavesCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  downloadWavesBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  downloadWavesTitle: {
    marginRight: 50
  },
  pendingWavesTitle: {
    width: '100%',
    backgroundColor: colors.light_grey,
    borderRadius: 8,
    padding: [5, 20]
  },
  mt30: {
    marginTop: 30
  }
})

export const EpisodeMonetizationPage = () => {
  const classes = useStyles()
  const { podcastMaskedId, episodeMaskedId } = useParams()

  const [midrolls, setMidrolls] = useState([])
  const [newMidrolls, setNewMidrolls] = useState([])
  const [preroll, setPreroll] = useState(false)
  const [postroll, setPostroll] = useState(false)
  const [removedMidrolls, setRemovedMidrolls] = useState([])
  const [autoOpt, setAutoOpt] = useState(true)
  const [crumbs, setCrumbs] = useState(true)
  const [audioHostLink, setAudioHostLink] = useState('')
  const [activeBar, setActiveBar] = useState([])

  const [offset, setOffset] = useState('')

  const { fetchEpisode, savePlacements, removePlacement, cleanCurrentEpisode, downloadEpisodeWaveform } = useActions()

  const episode = useSelector(state => state.episode?.currentEpisode)
  const { loading } = useSelector(state => state.episode)

  useEffect(() => {
    fetchEpisode(episodeMaskedId)
    return () => cleanCurrentEpisode()
  }, [])

  useEffect(() => {
    if (!isEmpty(episode)) {
      let arrPlacements = []

      setCrumbs([
        { name: 'Подкасты', path: '/podcasts' },
        { name: episode.podcastData?.title, path: '/podcasts/' + podcastMaskedId },
        { name: episode.title, path: '' },
      ])

      if (episode.masked_id && !audioHostLink) {
        setAudioHostLink(generateAudioLink(episode.masked_id))
      }

      if (episode.placements) {
        episode.placements.forEach((el) => {

          if (el.offset === '-1') {
            setPostroll(true)
          } else if (el.offset === '0') {
            setPreroll(true)
          } else {
            arrPlacements.push(el)
          }
        })

      }
      setMidrolls(arrPlacements)
    }
  }, [episode])

  const handleAddMidroll = (e = null, externalOffset = null) => {
    if (offset || externalOffset) {
      setNewMidrolls([...newMidrolls, { key: crypto.randomUUID(), offset: offset ? offset : externalOffset }])
      setOffset('')
    } else {
      alert('Empty Offset')
    }
  }

  const handleChangeMidroll = (currentBar, newMaskedTimePosition) => {
    if (currentBar) {
      let barsArr = newMidrolls.filter((el, idx) => el.offset !== currentBar.maskedTimePositionMs)
      setNewMidrolls([...barsArr, { key: crypto.randomUUID(), offset: newMaskedTimePosition }])
    }
  }

  const handleHighlightMidroll = (midrollKey) => {
    setActiveBar([...activeBar, midrollKey])
  }

  const handleDimMidroll = (midrollKey) => {
    setActiveBar([...activeBar.filter(el => el === midrollKey)])
  }

  const handleChangeMidrollViaInput = (e, key) => {
    let barsArr = newMidrolls.filter((el) => el.key !== key)
    setNewMidrolls([...barsArr, { key: crypto.randomUUID(), offset: e.target.value }])
  }

  const handleCancelMidrolls = () => {
    setNewMidrolls([])
  }

  const handleDownloadWaveform = () => {
    downloadEpisodeWaveform(episode.masked_id)
  }

  const handleSavePlacements = () => {
    const placementsForSave = []

    newMidrolls.forEach((el) => {
      if (el.offset) {
        const timeArr = el.offset.split(':')
        placementsForSave.push({ offset: parseInt(timeArr[0]) * 60 * 1000 + parseInt(timeArr[1]) * 1000 + parseInt(timeArr[2]) })
      }
    })

    savePlacements(episodeMaskedId, placementsForSave, removedMidrolls, preroll, postroll, fetchEpisode)
    setNewMidrolls([])
  }

  const handleRemoveNewMidroll = (targetKey) => {
    setNewMidrolls(newMidrolls.filter((el) => el.key !== targetKey))
  }

  const handleRemoveMidroll = (placeId) => {
    setRemovedMidrolls([...removedMidrolls, placeId])
    setMidrolls([...midrolls.filter(el => el.id !== placeId)])
  }

  const handleAutoOptToggle = () => {
    setAutoOpt(!autoOpt)
  }

  const handleOffsetChange = (e) => {
    setOffset(e.target.value)
  }

  const formatOffset = (offset) => {
    const minutes = parseInt(offset / 60000)
    const seconds = parseInt((parseInt(offset) - minutes * 60000) / 1000)
    const milliseconds = parseInt(offset) - (minutes * 60000 + seconds * 1000)

    const formattedMinutes = (minutes < 10) ? '0' + minutes : minutes
    const formattedSeconds = (seconds < 10) ? '0' + seconds : seconds
    const formattedMilliseconds = (milliseconds < 100)
      ? (milliseconds < 10)
        ? '00' + milliseconds
        : '0' + milliseconds
      : milliseconds

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`
  }

  return (
    <Template>
      {loading
        ? <Loader show={loading} />
        : <Page>
          <BreadCrumbs crumbs={crumbs} />
          <H1>{episode.podcastData?.title}</H1>
          <H4 className={classes.cta}>Настройте параметры добавления рекламы в эпизоде</H4>
          {!isEmpty(episode) && <EpisodeCard
            className={classes.card}
            episode={episode}
            description={episode.description}
            podcastMaskedId={podcastMaskedId}
            noActions
          />}
          {/* <div className={classes.autoOpt}>
            <SwitchButton
              handleChange={handleAutoOptToggle}
              checked={autoOpt}
              label={'Автоматическая оптимизация'}
            />
          </div> */}
          <div className={classes.rolls}>
            <Checkbox id='pre' label={"Pre-roll"} checked={preroll} onChange={() => { setPreroll(!preroll) }} />
            <Checkbox id='post' label={"Post-roll"} checked={postroll} onChange={() => { setPostroll(!postroll) }} />
          </div>
          {episode?.waves_img
            ? <>
              <InteractiveSoundwave
                waves_img={episode.waves_img}
                duration={episode.duration}
                addMidroll={handleAddMidroll}
                changeMidroll={handleChangeMidroll}
                currentMidrolls={midrolls}
                newMidrolls={newMidrolls}
                activeBar={activeBar}
              />
              <H3 className={classes.mt30}>Вы можете добавить больше меток для вставок mid-roll. Чтобы указать место для вставки кликните на шкалу или укажите время вставки вручную:</H3>
              <div className={classes.buttonBlock}>
                <div className={classes.offsetInput}>
                  <Input
                    mask="99\:99\:999"
                    formatChars={{ '9': '[0-9]' }}
                    maskChar="0"
                    alwaysShowMask={true}
                    value={offset}
                    onChange={(e) => { handleOffsetChange(e) }}
                  />
                </div>
                <div className={classes.addPlacementBlock}>
                  <Button className={'mr10'} onClick={handleAddMidroll} disabled={offset ? false : true}>
                    Добавить
                  </Button>
                  <Button className={'mr10'} onClick={handleCancelMidrolls} disabled={offset ? false : true}>
                    Отменить
                  </Button>
                </div>
              </div>
              {(!isEmpty(midrolls) || !isEmpty(newMidrolls)) && <>
                <H3 className={classes.mt30}>Метки для вставки mid-rolls:</H3>
                <ul className={classes.placementList}>
                  {!isEmpty(midrolls) && midrolls.map((place, idx) => {
                    const formattedOffset = formatOffset(place.offset)

                    return <li key={idx}>
                      <div className={classes.placementWrapper}>
                        <Input
                          mask="99\:99\:999"
                          formatChars={{ '9': '[0-9]' }}
                          maskChar="0"
                          alwaysShowMask={true}
                          value={formattedOffset}
                          onChange={() => { }}
                          disabled={true}
                        />
                        <Button className={classes.btn} onClick={() => handleRemoveMidroll(place.id)}>
                          Удалить
                        </Button>
                      </div>
                    </li>
                  })}
                  {!isEmpty(newMidrolls) && newMidrolls.map((newPlace) => {
                    const formattedOffset = newPlace.offset

                    return <li key={newPlace.key}>
                      <div className={classes.placementWrapperNew}>
                        <Input
                          mask="99\:99\:999"
                          formatChars={{ '9': '[0-9]' }}
                          maskChar="0"
                          alwaysShowMask={true}
                          value={formattedOffset}
                          onChange={(e) => { handleChangeMidrollViaInput(e, newPlace.key) }}
                        />
                        <span>new</span>
                        <div className={classes.btn}>
                          <Button className={classes.btn} onClick={() => handleRemoveNewMidroll(newPlace.key)}>
                            Удалить
                          </Button>
                        </div>
                        {/* <div className={classes.btn}>
                        <Button className='primary' onClick={() => console.log('play')}>
                          Прослушать
                        </Button>
                      </div> */}
                        <AudioPlayer
                          link={episode.orig_link}
                          timestamp={transformTimeMaskToMs(formattedOffset) / 1000}
                          midrollKey={newPlace.key}
                          highlightMidroll={handleHighlightMidroll}
                          dimMidroll={handleDimMidroll}
                        />
                      </div>
                    </li>
                  })}
                </ul>
              </>
                // : <div className={classes.placeholder}><H1>Добавьте mid-roll метки</H1></div>
              }
            </>
            : <div className={classes.downloadWavesBlock}>
              {episode.waves_img_status !== 'pending'
                ? <>
                  <H3 className={classes.downloadWavesTitle}>Чтобы добавить midroll вставки загрузите диаграмму аудиофайла:</H3>
                  <Button className='primary' style={{ width: '200px' }} onClick={handleDownloadWaveform}>
                    Загрузить диаграмму
                  </Button>
                </>
                : <H3 className={classes.pendingWavesTitle}>
                  <div>Диаграмма аудиофайла находится в обработке, это может занять несколько минут.</div>
                  <div>Пожалуйста обновите страницу вручную, чтобы увидеть диаграмму.</div>
                </H3>
              }
            </div>
          }
          <div className={classes.mt30}>
            <Button className='primary' style={{ width: '200px' }} onClick={handleSavePlacements}>
              Сохранить
            </Button>
          </div>
          <Notification toast />
        </Page>
      }
    </Template>
  )
}