import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { AddPretargetForm } from "./AddPretargetForm";
import { TableFilterBar } from "./TableFilterBar";
import { getSortIcon, getSortOrder } from "../../utils";

const useStyles = createUseStyles({
  pretargetTable: {
    marginTop: 15,
  },
})

export const PretargetPage = () => {
  const classes = useStyles()
  const { fetchPretargetList, cleanPretargetList, deletePretarget } = useActions()

  const [pretargetData, setPretargetData] = useState([])

  const { pretargetList, loading } = useSelector(state => state.admin)
  const { sortBy, sortOrder } = useSelector(state => state.admin.pretargetFilter);

  useEffect(() => {
    fetchPretargetList()
    return () => cleanPretargetList()
  }, [])

  useEffect(() => {
    if (pretargetList.length) {
      let pretargetArr = []
      pretargetList.map((pretarget) => {
        pretargetArr.push(
          {
            columns: [
              { key: 'user', type: 'text', value: `(${pretarget.user_id}) ${pretarget.user_email}` },
              { key: 'adsystem_name', type: 'text', value: `(${pretarget.ad_system_id}) ${pretarget.ad_system_name}` },
              { key: 'pretarget_type', type: 'text', value: pretarget.type, style: pretarget.type === 'include' ? 'textGreen' : 'textRed' },
              { key: 'actions', type: 'actions', onClickHandler: () => deletePretarget(pretarget.id) },
            ]
          }
        )
        setPretargetData(pretargetArr)
      })
    } else {
      setPretargetData([])
    }
  }, [pretargetList])

  const handleSearch = (filter) => {
    fetchPretargetList({filter: filter})
  }

  const HEADERS = [
    {
      name: 'Пользователь',
      colSpan: 1,
      sortIcon: getSortIcon('user', sortBy, sortOrder),
      onSort: () => fetchPretargetList({ sortBy: 'user', sortOrder: getSortOrder('user', sortBy, sortOrder) }),
    },
    {
      name: 'Рекламная система',
      colSpan: 1,
      sortIcon: getSortIcon('ad_system_name', sortBy, sortOrder),
      onSort: () => fetchPretargetList({ sortBy: 'ad_system_name', sortOrder: getSortOrder('ad_system_name', sortBy, sortOrder) }),
    },
    {
      name: 'Тип претаргетинга',
      colSpan: 1
    },
    {
      name: 'Действие',
      colSpan: 1
    },
  ]

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <AddPretargetForm />
            <H1>Претаргетинги:</H1>
            <TableFilterBar handleSearch={handleSearch} />
            {!pretargetData.length
              ? <div><H1>Претаргетинги отсутствуют</H1></div>
              : <div className={classes.pretargetTable}>
                <Table headers={HEADERS} items={pretargetData} />
              </div>
            }
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}