import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, centered } from '../../theme'

const useStyles = createUseStyles({
  button: {
    fontSize: 14,
    lineHeight: '130%',
    color: colors.background_white,
    textAlign: 'center',
    background: colors.primary_black,
    cursor: 'pointer',
    borderRadius: 50,
    padding: [10, 24],
    width: 'fit-content',
    height: 'fit-content',
    whiteSpace: 'nowrap',
    // '& svg': {
    //   stroke: colors.primary_black,
    //   fill: colors.primary_black
    // },
    '&:hover': {
      color: colors.primary_black,
      background: colors.primary_yellow,
      // '& svg': {
      //   stroke: colors.background_white,
      //   fill: colors.background_white
      // }
    },
    ...centered,
    '&.disabled': {
      color: colors.background_white,
      borderColor: colors.disabled,
      background: colors.disabled,
      '&:hover': {
        color: colors.background_white,
        borderColor: colors.disabled,
        background: colors.disabled,
        cursor: 'unset'
      }
    },
  },
  mr10: {
    marginRight: 10
  },
  primary: {
    color: colors.primary_black,
    background: colors.primary_yellow,
    '& svg': {
      stroke: colors.primary_black,
      fill: colors.primary_black
    },
    '&:hover': {
      color: colors.background_white,
      background: colors.primary_black,
      '& svg': {
        stroke: colors.background_white,
        fill: colors.background_white
      }
    }
  },
  filter: {
    borderRadius: 50,
    padding: [10, 24],
    backgroundColor: colors.light_grey,
    marginRight: 24,
    color: colors.primary_black,
    ...centered,
    '&.active': {
      background: colors.primary_black,
      color: colors.background_white,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
      '&:hover': {
        background: colors.light_grey,
        color: colors.primary_black,
        '& > span:last-child': {
          color: colors.primary_black
        },
      }
    },
    '&:hover': {
      background: colors.primary_black,
      color: colors.background_white,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
    }
  },
  dateFilter: {
    borderRadius: 50,
    padding: [10, 14],
    backgroundColor: colors.background_white,
    marginRight: 24,
    color: colors.primary_black,
    ...centered,
    '&.active': {
      background: colors.light_grey,
      color: colors.text_black,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
      '&:hover': {
        background: colors.light_grey,
        color: colors.primary_black,
        '& > span:last-child': {
          color: colors.primary_black
        },
      }
    },
    '&:hover': {
      background: colors.light_grey,
      color: colors.primary_black,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
    }
  },
  toggle: {
    borderRadius: 50,
    padding: [10, 14],
    backgroundColor: colors.background_white,
    marginRight: 10,
    color: colors.primary_black,
    ...centered,
    '&.active': {
      background: colors.light_grey,
      color: colors.text_black,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
      '&:hover': {
        background: colors.light_grey,
        color: colors.primary_black,
        '& > span:last-child': {
          color: colors.primary_black
        },
      }
    },
    '&:hover': {
      background: colors.light_grey,
      color: colors.primary_black,
      '& > span:last-child': {
        color: colors.primary_yellow
      },
    }
  },
  width200: {
    width: 200
  }
})

export const Button = ({
  className,
  type,
  style,
  status,
  onClick,
  disabled,
  children,
}) => {
  const classes = useStyles()

  return (
    <>
      {type === 'submit'
        ? <button
          type='submit'
          className={classnames(classes.button, classes[className], status, disabled ? 'disabled' : '')}
          style={style}
        >
          {children}
        </button>
        : <div
          className={classnames(classes.button, classes[className], status, disabled ? 'disabled' : '')}
          style={style}
          onClick={disabled ? (e) => e.preventDefault() : onClick}
        >
          {children}
        </div>
      }
    </>
  )
}