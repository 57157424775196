import React, { useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../components'
import { isPressEscape } from '../../utils'
import { centered, colors } from '../../theme'

const useStyles = createUseStyles({
  modal: {
    position: 'fixed',
    zIndex: '9',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    ...centered,
  },
  overlay: {
    background: 'rgba(43, 42, 50, 0.4)',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backdropFilter: 'blur(4px)',
  },
  wrapper: {
    background: colors.background_white,
    maxHeight: 'calc(100vh - 40px)',
    padding: 24,
    borderRadius: 16,
    position: 'relative',
  },
  scroll: {
    maxHeight: 'calc(100vh - 100px)',
  },
  content: {
    minWidth: 400,
    flexDirection: 'column',
  },
  close: {
    background: colors.white,
    width: 36,
    height: 36,
    position: 'absolute',
    top: 14,
    right: 14,
    cursor: 'pointer',
    zIndex: 2,
    ...centered,
    '& > svg': {
      width: 20,
      height: 20,
      display: 'block',
      stroke: colors.primary_default,
    },
  },
  bgLogo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    bottom: 0,
    '& svg': {
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '50%',
      height: '50%',
      minWidth: 220,
      minHeight: 220,
      stroke: colors.light_grey,
      fill: colors.light_grey,
      strokeOpacity: '60%',
      fillOpacity: '60%',
    }
  }
})

export const Modal = ({
  children,
  className,
  withoutScroll,
  withClose,
  onClose,
}) => {
  const classes = useStyles()

  const onEscape = (e) => {
    if (isPressEscape(e)) {
      onClose()
    }
  }

  const onHoldPosition = (scrollY) => {
    setTimeout(() => {
      window.scrollTo(0, scrollY)
    }, 0)
  }

  useEffect(() => {
    const { scrollY } = window
    document.body.style.overflow = 'hidden'
    onHoldPosition(scrollY)

    return () => {
      document.body.style.overflow = ''
      onHoldPosition(scrollY)
    }
  })

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  })

  return (
    <div className={classes.modal}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classnames(classes.wrapper, className)}>
      <div className={classes.bgLogo}>
        <Icon iconName={'bg_logo'} />
      </div>
        {withClose &&
          <div className={classes.close} onClick={onClose} title='Закрыть'>
            <Icon iconName='close' />
          </div>
        }
        {withoutScroll
          ? children
          : /*<Scroll className={classes.scroll} translateContentSizesToHolder noScrollX>*/
            <div className={classes.content}>{children}</div>
          /*</Scroll>*/
        }
      </div>
    </div>
  )
}
