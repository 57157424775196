import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { colors } from '../../../theme'
import { Table } from '../../../components'

const useStyles = createUseStyles({
  episodes: {
    marginBottom: 48,
  },
  sectionTitle: {
    marginBottom: 16,
  }
})

const HEADERS = [
  { name: 'Название', colSpan: 1 },
  { name: 'Параметры рекламы', colSpan: 1 },
]

export const EpisodesSection = ({
  className,
  episodesData
}) => {
  const classes = useStyles()

  return <div className={classes.episodes}>
    <H1 className={classes.sectionTitle}>Параметры эпизодов</H1>
    <Table headers={HEADERS} items={episodesData} />
  </div>
}
