import { isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Selector, Button } from "../../components";
import { useActions } from "../../hooks";
import { isAdmin } from "../../utils";

const useStyles = createUseStyles({
  addUserForm: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    marginRight: 30
  }
})

export const AddUserForm = () => {
  const classes = useStyles()
  const history = useHistory()

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userType, setUserType] = useState({ label: 'Пользователь', value: 'podcaster' })
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { userErrors, loading } = useSelector(state => state.admin)
  const { user_type } = useSelector(state => state.account.user)

  const { addUser, clearAdminState } = useActions()

  const INPUTS = [
    {
      size: 'medium',
      type: 'text',
      name: 'username',
      label: 'Имя пользователя',
      placeholder: '',
      value: username,
      onChange: (e) => {
        setUsername(e.target.value);
      },
      error: submitted > 0 && errors.email,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'text',
      name: 'email',
      label: 'Email',
      placeholder: '',
      value: email,
      onChange: (e) => {
        setEmail(e.target.value);
      },
      error: submitted > 0 && errors.email,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'password',
      name: 'password',
      label: 'Пароль',
      placeholder: '',
      value: password,
      onChange: (e) => {
        setPassword(e.target.value);
      },
      error: submitted > 0 && errors.password,
      errorClassName: classes.error,
    },
    isAdmin(user_type) && {
      size: 'medium',
      type: 'select',
      name: 'userType',
      label: 'Роль',
      options: [
        { label: 'Пользователь', value: 'podcaster' },
        { label: 'Менеджер подкастов', value: 'pc_manager' },
        { label: 'Партнер', value: 'partner' },
        { label: 'Рекламодатель', value: 'advertiser' },
      ],
      placeholder: '',
      value: userType,
      onChange: (type) => {
        setUserType(type);
      },
      error: submitted > 0 && errors.userType,
      errorClassName: classes.error,
    }
  ]

  const isValid = email !== '' && password !== ''

  const onAddUser = () => setSubmitted(submitted + 1)

  useEffect(() => {
    let errors_arr = {}
    if (!username.trim() || userErrors.username) errors_arr.username = userErrors.username ? userErrors.username : ''
    if (!email.trim() || userErrors.email) errors_arr.email = userErrors.email ? userErrors.email : ''
    if (!password.trim() || userErrors.password) errors_arr.password = userErrors.password ? userErrors.password : ''

    setErrors(errors_arr);
  }, [username, email, password])

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        username: username,
        email: email,
        password: password,
        userType: userType.value
      }

      clearAdminState()
      addUser(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addUserForm}>
          {INPUTS.map((item, index) => {
            return isObject(item) && <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} width={200} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          }
          )}
          <Button size='medium' className={'primary'} onClick={onAddUser}>Добавить</Button>
        </div>}
    </>
  )
}