import React, { useState } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { colors } from '../../theme'
import { H4 } from '../Typography'
import { SeriesChip } from '../SeriesChip'
import { PodcastMetaChip } from '../PodcastMetaChip'
import { More } from '../More'
import { Icon } from '../Icon'
import { useActions } from '../../hooks/useActions'
import { generatePrefixUrl, isAdmin, isPartner, isPodcastManager } from '../../utils'

const useStyles = createUseStyles({
  menu: {
    position: 'relative',
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'end',
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      width: 28,
      height: 28,
      stroke: colors.icon_grey,
      fill: colors.icon_grey,
    }
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 328,
    minWidth: 328,
    // maxHeight: 109,
    marginRight: 19,
    marginTop: 25,
    borderRadius: 8,
    background: colors.light_grey,
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
      background: colors.primary_yellow,
      '& svg': {
        fill: colors.text_black,
        stroke: colors.text_black,
      }
    },
    '&:hover $menu': {
      '& svg': {
        width: 28,
        height: 28,
        fill: colors.text_black,
        stroke: colors.text_black,
      }
    },
  },
  mainWrapper: {
    display: 'flex'
  },
  adminWrapper: {
    marginTop: 10
  },
  shortcut: {
    width: 77,
    height: 77,
    marginRight: 16,
    '& > img': {
      width: 77,
      height: 77,
      objectFit: 'cover',
    },
    '& > svg': {
      width: 77,
      height: 77,
      fill: colors.plug_grey,
      stroke: colors.plug_grey,
    }
  },
  mainInfo: {
    width: 160,
    // maxHeight: 77,
    marginRight: 15,
    '& > p': {
      marginTop: 3,
      color: colors.dark_grey,
      fontSize: 12,
    }
  },
  partnerInfo: {
    '& > p': {
      marginTop: 3,
      color: colors.dark_grey,
      fontSize: 12,
    },
    marginTop: 10
  },
  podcastTitle: {
    fontSize: 16,
    lineHeight: '130%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chips: {
    marginTop: 15,
    '& > div:last-child': {
      marginTop: 5
    },
  }
})

export const PodcastTile = ({
  className,
  prefix_url_domain,
  podcast,
  userType,
  onClick
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { hidePodcast, fetchPodcastList } = useActions()

  const MORE_OPTIONS = [
    {
      title: 'Монетизация',
      clickHandler: () => history.push(`/podcasts/${podcast.masked_id}/monetization`)
    },
    {
      title: 'Статистика',
      clickHandler: () => history.push(`/stats/${podcast.masked_id}`)
    },
    {
      title: 'Скрыть подкаст',
      adminOnly: true,
      clickHandler: () => {
        if (window.confirm(`Подкаст ${podcast.masked_id} и все его данные будут скрыты`)) {
          hidePodcast(podcast.masked_id, fetchPodcastList)
        }
      }
    },
    // {
    //   title: 'Удалить',
    //   clickHandler: () => { alert(podcast.id) }
    // },
  ]

  return (
    <div
      className={classnames(classes.tile, className)}
      onClick={onClick}
    >
      <div className={classes.mainWrapper}>
        <div className={classes.shortcut}>
          {podcast.image_orig
            ? <img src={podcast.image_orig} alt="Podcast Cover" loading='lazy'/>
            : <Icon iconName={'img_plug'} />
          }
        </div>
        <div className={classes.mainInfo}>
          <H4 className={classes.podcastTitle}>{podcast.title}</H4>
          <p>{podcast.created}</p>
          <div className={classes.chips}>
            <SeriesChip count={podcast.episodes.length} type={'counter'} />
          </div>
        </div>
        <div className={classes.menu}>
          <More options={MORE_OPTIONS} />
        </div>
      </div>
      {(isAdmin(userType) || isPodcastManager(userType)) && <div className={classes.adminWrapper}>
        <PodcastMetaChip
          podcastId={podcast.id}
          prefixUrl={generatePrefixUrl(prefix_url_domain, podcast.masked_id)}
          email={podcast?.user?.email}
          userId={podcast?.user_id}
          podcastStatus={podcast?.status}
        />
      </div>}
      {isPartner(userType) && <div className={classes.partnerInfo}>
            <p><strong style={{marginRight: '7px'}}>Пользователь:</strong>{podcast.user.username}</p>
          </div>}
    </div>
  )
}