import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isSuperAdmin, isAdvertiser } from '../../utils'


function PrivateRoute({ roleAccess, path, component: Component, ...rest }) {
  const isLoggedIn = useSelector(state => state.account?.user?.access_token)
  const user_type = useSelector(state => state.account?.user?.user_type)

  return (
    <Route {...rest} path={isSuperAdmin(user_type) ? '/distribution_settings' : path} render={props => {
      if (!isLoggedIn) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
      if (isSuperAdmin(user_type) && path === '/') {
        return <Redirect to={{ pathname: '/distribution_settings', state: { from: props.location } }} />
      }
      if ((!isEmpty(roleAccess) && !roleAccess.includes(user_type)) || path === '/') {
        return <Redirect to={{ pathname: isAdvertiser(user_type) ? '/statistics' : '/podcasts', state: { from: props.location } }} />
      }

      return <Component {...props} />
    }} />
  );
}

export default PrivateRoute