const initialState = {
  loading: false,
  errors: [],

  filter: {
    sortBy: 'CreatedOn',
    sortOrder: 'DESC',
    // startDate: getMonthAgoDate(),
    // endDate: getDate(),
    page: 1,
    pageLimit: 15,
  },
  podcastList: [],
  currentPodcast: {},

  podcastParams: {
    catsWhiteList: [],
    catsBlackList: [],
    whiteList: [],
    blackList: [],
  },

  importStatus: false,
  importMsg: '',
  pendingPodcastMaskedId: '',
  pendingRss: '',
}

export const podcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case PODCAST_REQUEST:
      return { ...state, loading: true, filter: { ...state.filter, ...action.payload } }
    case PODCAST_REQUEST_ERROR:
      return { ...state, loading: false, errors: action.payload }

    case PODCAST_IMPORT_SUCCESS:
      return { ...state, loading: false, pendingPodcastMaskedId: action.payload.podcastMaskedId, importStatus: action.payload.importStatus, pendingRss: action.payload.pendingRss }

    case PODCAST_TOGGLE_AUTOIMPORT_SUCCESS:
      return { ...state, loading: false, currentPodcast: action.payload }
    case PODCAST_TOGGLE_NEW_EPS_MONETIZATION_SUCCESS:
      return { ...state, loading: false, currentPodcast: action.payload }

    case PODCAST_CURRENT_CLEAN:
      return { ...state, currentPodcast: {}, podcastParams: initialState.podcastParams }

    case PODCAST_LIST_SUCCESS:
      return { ...state, podcastList: action.payload, loading: false }
    case PODCAST_CLEAN_LIST:
      return { ...state, podcastList: [] }
    case PODCAST_FETCH_SUCCESS:
      return { ...state, loading: false, currentPodcast: action.payload }
    case PODCAST_CLEAN_CARD_STATE:
      return { ...state, currentPodcast: {} }

    case PODCAST_CHANGE_IMPORT_STATUS:
      return { ...state, importStatus: action.payload.importStatus, importMsg: action.payload.message }
    case PODCAST_CHECK_IMPORT_SUCCESS:
      return { ...state, importStatus: action.payload.importStatus, importMsg: action.payload.message }
    case PODCAST_CLEAN_IMPORT_META:
      return { ...state, importStatus: false, importMsg: '', pendingPodcastMaskedId: '', pendingRss: '' }

    case PODCAST_FETCH_PARAMS_SUCCESS:
      return { ...state, loading: false, currentPodcast: action.payload.podcast, podcastParams: { ...action.payload.params } }
    case PODCAST_SET_ADS_SUCCESS:
      return { ...state, loading: false }
    case PODCAST_EDIT_CATEGORIES_SUCCESS:
      return { ...state, loading: false, currentPodcast: action.payload.podcast, podcastParams: { ...action.payload.params } }
    case PODCAST_SET_INTEGRATION_SUCCESS:
      return { ...state, loading: false }

    default:
      return state
  }
}

export const PODCAST_REQUEST = 'PODCAST_REQUEST'
export const PODCAST_REQUEST_ERROR = 'PODCAST_REQUEST_ERROR'
export const PODCAST_IMPORT_SUCCESS = 'PODCAST_IMPORT_SUCCESS'

export const PODCAST_LIST_SUCCESS = 'PODCAST_LIST_SUCCESS'
export const PODCAST_FETCH_SUCCESS = 'PODCAST_FETCH_SUCCESS'

export const PODCAST_TOGGLE_AUTOIMPORT_SUCCESS = 'PODCAST_TOGGLE_AUTOIMPORT_SUCCESS'
export const PODCAST_TOGGLE_NEW_EPS_MONETIZATION_SUCCESS = 'PODCAST_TOGGLE_NEW_EPS_MONETIZATION_SUCCESS'

export const PODCAST_CLEAN_CARD_STATE = 'PODCAST_CLEAN_CARD_STATE'

export const PODCAST_CHANGE_IMPORT_STATUS = 'PODCAST_CHANGE_IMPORT_STATUS'
export const PODCAST_CHECK_IMPORT_SUCCESS = 'PODCAST_CHECK_IMPORT_SUCCESS'
export const PODCAST_CLEAN_IMPORT_META = 'PODCAST_CLEAN_IMPORT_META'

export const PODCAST_FETCH_PARAMS_SUCCESS = 'PODCAST_FETCH_PARAMS_SUCCESS'
export const PODCAST_SET_ADS_SUCCESS = 'PODCAST_SET_ADS_SUCCESS'

export const PODCAST_SET_INTEGRATION_SUCCESS = 'PODCAST_SET_INTEGRATION_SUCCESS'

export const PODCAST_EDIT_CATEGORIES_SUCCESS = 'PODCAST_EDIT_CATEGORIES_SUCCESS'

export const PODCAST_CLEAN_LIST = 'PODCAST_CLEAN_LIST'
export const PODCAST_CURRENT_CLEAN = 'PODCAST_CURRENT_CLEAN'
