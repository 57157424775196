import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Checkbox } from "../Form/Checkbox";
import { colors } from '../../theme'
import { Button, Icon } from "../../components";

const useStyles = createUseStyles({
  tableWrapper: {
    color: colors.text_black,
    marginBottom: 48,
    borderRadius: [8, 8, 0, 0],
    '& table': {
      '& thead': {
        background: colors.light_grey,
        position: 'sticky',
        top: 0,
        zIndex: 1,

        '& tr': {
          height: 52,

        },
        '& th': {
          padding: 16,
          textAlign: 'start',
          verticalAlign: 'middle',
          wordBreak: 'initial',
          '&:first-child': {
            borderRadius: [8, 0, 0, 0],
          },
          '&:last-child': {
            borderRadius: [0, 8, 0, 0],
          },
          '& > div': {
            '&.sortable': {
              overflow: 'visible',
              '& > span': {
                cursor: 'pointer',
              },
            },
            '&.center': {
              textAlign: 'center',
            },
            '& svg': {
              width: 18,
              height: 18,
              marginLeft: 6,
              stroke: colors.primary_black,
              display: 'inline-block',
              verticalAlign: 'middle',
            },
          }
        }
      },
      '& tbody': {
        '& tr': {
          borderBottom: [1, 'solid', colors.icon_grey],
          '&:hover': {
            background: colors.x_light_grey,
          },
          '& td': {
            padding: [8, 16],
          }
        }
      },
      '& div': {
        whiteSpace: 'nowrap',
        wordBreak: 'inherit',
        '& span': {
          whiteSpace: 'nowrap',
          wordBreak: 'inherit',
        }
      }
    }
  },
  w240: {
    width: 240
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  prependIcon: {
    width: 18,
    height: 18,
    marginRight: 8,
    '& svg': {
      width: 18,
      height: 18,
    }
  },
  status: {
    fontSize: 14,
    fontWeight: 600
  },
  negative: {
    color: colors.coral
  },
  positive: {
    color: colors.primary_green
  },
  iconActions: {
    position: 'relative',
    display: 'flex',
    marginLeft: 45,
  },
  actions: {
    position: 'relative',
    display: 'flex',
  },
  pc_actions: {
    position: 'relative',
    display: 'flex',
    minWidth: 240,
    '& div:first-child': {
      marginRight: 10
    }
  },
  iconBtn: {
    width: 15,
    height: 28,
    '& svg': {
      width: 21,
      height: 28,
      fill: colors.icon_grey,
      '&:hover': {
        fill: colors.primary_yellow,
      }
    },
  },
  checkbox: {
    '& svg': {
      width: '20px!important',
      height: '20px!important',
    }
  },
  user_checkbox: {
    width: 200
  },
  scrollable_text: {
    width: 270
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textGreen: {
    color: colors.primary_green,
  },
  textRed: {
    color: colors.coral,
  },
  text200: {
    maxWidth: 200,
    whiteSpace: 'break-spaces!important',
    textOverflow: 'ellipsis',
  },
  text_centered: {
    textAlign: 'center'
  },
  clickable: {
    cursor: 'pointer'
  }
})

export const Table = ({
  className,
  headers,
  items,
  scrollX = false
}) => {
  const classes = useStyles()

  return <div className={classes.tableWrapper} style={scrollX ? { overflowX: 'scroll' } : {}}>
    <table className={classes.table}>
      <thead>
        <tr>
          {headers.map(({ name, colSpan, onSort, sortIcon, className }, idx) => {
            return <th key={idx} colSpan={colSpan} className={className ? classes[className] : ''}>
              <div className={classnames({ 'sortable': !!onSort, 'center': colSpan > 1 })}>
                <span onClick={onSort}>
                  {name}
                  {sortIcon && <Icon iconName={sortIcon} />}
                </span>
              </div>
            </th>
          })}
        </tr>
      </thead>
      <tbody>
        {items.map(({ columns }, idx) => {
          return <tr key={idx} className={classes.clickable}>
            {columns.map(({ key, type, value, link, el_id, action, onClickHandler, onCellClickHandler, onPrimaryAction, onSecondaryAction, style }) => {
              return <td key={key+type} onClick={() => onCellClickHandler ? onCellClickHandler() : () => {}} className={type === 'monet_status' ? classes.w240 : ''}>
                {type === 'text' && <div className={classes[style]}><span>{value}</span></div>}
                {type === 'text200' && <div className={classes[style]}><span className={classes.text200}>{value}</span></div>}
                {type === 'checkbox' && <div className={classnames(classes.checkbox, key === 'sendAdfoxPcId' ? classes.textAlignCenter : '')}>
                  <Checkbox label={""} id={el_id} checked={value} onChange={action} />
                </div>
                }
                {type === 'monet_status' && <div className={classes.flexEnd}>
                  <div className={classes.flex}>
                    <div className={classes.prependIcon}>
                      <Icon iconName={value ? 'status_checked' : 'status_cross'} />
                    </div>
                    <span className={classnames(classes.status, value ? classes.positive : classes.negative)}>
                      {value ? 'Есть монетизация' : 'Нет монетизации'}
                    </span>
                  </div>
                  <div className={classes.iconActions}>
                    <div className={classes.iconBtn}>
                      <Link to={link}>
                        <Icon iconName='dollar' />
                      </Link>
                    </div>

                  </div>
                </div>
                }
                {type === 'actions' &&
                  <div className={classes.actions}>
                    <div className={classes.actionBtn}>
                      <div className={classes.addBtn}>
                        <Button className={'secondary'} onClick={onClickHandler}>Удалить</Button>
                      </div>
                    </div>
                  </div>
                }
                {type === 'user_actions' &&
                  <div className={classes.actions}>
                    <div className={classes.actionBtn}>
                      <div className={classes.addBtn}>
                        <Button className={'secondary'} onClick={onClickHandler}>Сбросить пароль</Button>
                      </div>
                    </div>
                  </div>
                }
                {type === 'pc_actions' &&
                  <div className={classes.pc_actions}>
                    <Button className={'primary'} style={{maxWidth: '100px', fontSize: '12px'}} onClick={onPrimaryAction}>{key === 'pc_actions' ? 'Publish All' : 'Set Published'}</Button>
                    <Button className={'secondary'} style={{maxWidth: '100px', fontSize: '12px'}} onClick={onSecondaryAction}>{key === 'pc_actions' ? 'Pending All' : 'Set Pending'}</Button>
                  </div>
                }
              </td>
            })}
          </tr>
        })}
      </tbody>
    </table>
  </div>
}
