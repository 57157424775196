import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  error: {
    color: colors.coral,
    fontSize: 14,
    lineHeight: 22/14,
  },
})

export const Error = ({
  className,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.error, className)}>{children}</div>
  )
}