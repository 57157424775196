import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button, Selector } from "../../components";
import { useActions } from "../../hooks";

const useStyles = createUseStyles({
  addPretargetForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginBottom: 30,
  },
  pretargetTypeSelector: {
    height: 48
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddPretargetForm = () => {
  const classes = useStyles()
  const history = useHistory()

  const [userIds, setUserIds] = useState('')
  const [adsystemId, setAdsystemId] = useState('')
  const [pretargetType, setPretargetType] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { pretargetErrors, loading } = useSelector(state => state.admin)

  const { addPretargets, clearAdminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!userIds.trim() || pretargetErrors.userIds) errors_arr.userIds = pretargetErrors.userIds ? pretargetErrors.userIds : ERRORS.required
    if (!adsystemId.trim() || pretargetErrors.adsystemId) errors_arr.adsystemId = pretargetErrors.adsystemId ? pretargetErrors.adsystemId : ERRORS.required
    if (!pretargetType || pretargetErrors.pretargetType) errors_arr.pretargetType = pretargetErrors.pretargetType ? pretargetErrors.pretargetType : ERRORS.required

    setErrors(errors_arr);
  }, [userIds, adsystemId, pretargetType])

  const isValid = userIds !== '' && adsystemId !== '' && pretargetType !== ''

  const onAddPretarget = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        userIds: userIds,
        adsystemId: adsystemId,
        pretargetType: pretargetType.value,
      }

      clearAdminState()
      addPretargets(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'large',
      type: 'text',
      name: 'userIds',
      label: 'ID пользователя',
      placeholder: 'Введите один или несколько ID через запятую',
      value: userIds,
      onChange: (e) => {
        setUserIds(e.target.value);
      },
      error: submitted > 0 && errors.userIds,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 'adsystemId',
      label: 'ID рекламной системы',
      placeholder: '',
      value: adsystemId,
      onChange: (e) => {
        setAdsystemId(e.target.value);
      },
      error: submitted > 0 && errors.adsystemId,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'select',
      name: 'pretargetType',
      label: 'Тип претаргетинга',
      options: [
        { label: 'include', value: 'include' },
        { label: 'exclude', value: 'exclude' }
      ],
      placeholder: '',
      value: pretargetType,
      onChange: (type) => {
        setPretargetType(type);
      },
      error: submitted > 0 && errors.pretargetType,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addPretargetForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={onAddPretarget}>Сохранить</Button>
          </div>
        </div>}
    </>
  )
}