import React, { useEffect, useState } from 'react'
import { Auth } from '../../containers'
import { useActions } from '../../hooks/useActions'
import { LoginForm } from './LoginForm'


export const LoginPage = () => {
  const { clearAccState } = useActions()
  useEffect(() => { return clearAccState }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Auth>
      <LoginForm />
    </Auth>
  )
}