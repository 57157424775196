import API from '../../utils/Api'
import { specialCharsParser } from '../../utils'

import { REQUEST_NOTIFICATION_ERROR, REQUEST_NOTIFICATION_SUCCESS } from '../reducers/notificationReducer'

import {
  PODCAST_REQUEST,
  PODCAST_REQUEST_ERROR,
  PODCAST_IMPORT_SUCCESS,
  PODCAST_LIST_SUCCESS,
  PODCAST_FETCH_SUCCESS,
  PODCAST_CLEAN_CARD_STATE,
  PODCAST_CHANGE_IMPORT_STATUS,
  PODCAST_FETCH_PARAMS_SUCCESS,
  PODCAST_CLEAN_IMPORT_META,
  PODCAST_SET_ADS_SUCCESS,
  PODCAST_SET_INTEGRATION_SUCCESS,
  PODCAST_CLEAN_LIST,
  PODCAST_TOGGLE_AUTOIMPORT_SUCCESS,
  PODCAST_TOGGLE_NEW_EPS_MONETIZATION_SUCCESS,
  PODCAST_EDIT_CATEGORIES_SUCCESS,
  PODCAST_CURRENT_CLEAN
} from '../reducers/podcastReducer'

export const importRss = (rssUrl, params = {}, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/import', { rssUrl, params })

      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        if (callback) {
          callback()
        } else {
          dispatch({ type: PODCAST_IMPORT_SUCCESS, payload: response.data })
        }
      } else {
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }

      dispatch({ type: PODCAST_CHANGE_IMPORT_STATUS, payload: response.data })
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
      dispatch({ type: PODCAST_CHANGE_IMPORT_STATUS, payload: { importStatus: 'failed', message: error } })
    }
  }
}

export const fetchPodcastList = () => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user

    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.get('/podcast/list', {}, access_token)

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: PODCAST_LIST_SUCCESS, payload: response.data.podcastList })
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const fetchPodcast = (masked_id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/fetch', { masked_id })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        response.data.podcast.description = specialCharsParser(response.data.podcast.description)
        response.data.podcast.title = specialCharsParser(response.data.podcast.title)
        response.data.podcast.summary = specialCharsParser(response.data.podcast.summary)
        dispatch({ type: PODCAST_FETCH_SUCCESS, payload: response.data.podcast })
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
        if (response.data.redirect) history.push(response.data.redirect)
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const cleanPodcastState = () => {
  return async (dispatch) => {
    dispatch({ type: PODCAST_CLEAN_CARD_STATE })
  }
}

export const cleanPodcastList = () => {
  return async (dispatch) => {
    dispatch({ type: PODCAST_CLEAN_LIST })
  }
}

export const getPodcastParams = (podcastMaskedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/params', { podcastMaskedId })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        response.data.podcast.description = specialCharsParser(response.data.podcast.description)
        response.data.podcast.title = specialCharsParser(response.data.podcast.title)
        response.data.podcast.summary = specialCharsParser(response.data.podcast.summary)
        dispatch({ type: PODCAST_FETCH_PARAMS_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const setPcAds = (masked_id, type, value, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/setads', { masked_id, type, value })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: PODCAST_SET_ADS_SUCCESS, payload: response.data })
        callback(masked_id)
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const hidePodcast = (podcastMaskedId, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/hide', { podcastMaskedId })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        // dispatch({ type: PODCAST_HIDE_SUCCESS, payload: response.data })
        callback()
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const changeImportStatus = (status, msg = '') => {
  return async (dispatch) => {
    dispatch({ type: PODCAST_CHANGE_IMPORT_STATUS, payload: { importStatus: status, message: msg } })
  }
}

export const checkImportStatus = (podcastMaskedId) => {
  return async (dispatch, getState) => {
    const { importStatus } = getState().podcast
    try {
      const response = await API.post('/podcast/checkimportstatus', { podcastMaskedId })

      if (response.data.success) {
        if (importStatus !== response.data.importStatus) {
          dispatch({ type: PODCAST_CHANGE_IMPORT_STATUS, payload: response.data })
        }
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const cleanImportMeta = () => {
  return async (dispatch) => {
    dispatch({ type: PODCAST_CLEAN_IMPORT_META })
  }
}

export const podcastAutoimportToggle = (masked_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/toggleautoimport', { masked_id })

      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: PODCAST_TOGGLE_AUTOIMPORT_SUCCESS, payload: response.data.podcast })
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const podcastNewEpsMonetToggle = (masked_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/togglenewepsmonet', { masked_id })

      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: PODCAST_TOGGLE_NEW_EPS_MONETIZATION_SUCCESS, payload: response.data.podcast })
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const setIntegration = (type, masked_id, callback = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/setintegration', { type, masked_id })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: PODCAST_SET_INTEGRATION_SUCCESS, payload: response.data })
        callback(masked_id)
      } else {
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const savePodcastCategories = (podcastMaskedId, formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PODCAST_REQUEST })
      const response = await API.post('/podcast/savepodcastcategories', {podcastMaskedId, formData})
      if (response.data.success) {
        dispatch({ type: PODCAST_EDIT_CATEGORIES_SUCCESS, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
        dispatch({ type: PODCAST_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: PODCAST_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const cleanCurrentPodcast = () => {
    return { type: PODCAST_CURRENT_CLEAN }
}
