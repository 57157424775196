import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { centered, colors } from '../../../theme'
import CheckboxTree from 'react-checkbox-tree'
import { Icon } from '../../Icon'
import { Col6 } from '../../Grid/Col6'
import { isEmpty } from 'lodash'

const useStyles = createUseStyles({
  blockTitle: {
    fontSize: 14,
    lineHeight: '130%',
    margin: [16, 0],
  },
  chipsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 8,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: 50,
    padding: [6, 12],
    background: colors.primary_black,
    marginRight: 16,
    marginBottom: 5,
    '& span': {
      marginRight: 8,
      color: colors.background_white,
      fontSize: 12,
      lineHeight: '150%',
    }
  },
  removeSelectedBtn: {
    width: 18,
    height: 18,
    '& svg': {
      width: 18,
      height: 18,
    }
  },
  multiselectBlock: {
    width: '100%',
    margin: [0, -12],
    '& .react-checkbox-tree': {
      maxWidth: 649,
    },
    '& .rct-node': {
      '&:first-child': {
        borderRadius: [8, 8, 0, 0]
      },
      border: [1, 'solid', colors.input_border],
      '& .rct-text:first-child': {
        height: 38,
        fontSize: 14,
        lineHeight: '130%',
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .rct-collapse': {
      display: 'none',
      visibility: 'hidden'
    },
    '& .rct-node-parent.rct-node-expanded': {
      '& > .rct-text:first-child': {
        height: 38,
        color: colors.background_white,
        background: colors.primary_black,
      }
    }
  },
  iconWrapper: {
    width: 22,
    height: 22,
    marginRight: 10,
    '& svg': {
      width: 22,
      height: 22,
    }
  }
})

export const MultiselectBlock = ({
  className,
  nodesWhite,
  nodesBlack,
  label,
  handleChange,
  handleBlackChange,
  onRemove,
  selectedBl,
  selectedWl,
}) => {
  const classes = useStyles()
  const [whiteChecked, setWhiteChecked] = useState([])
  const [blackChecked, setBlackChecked] = useState(selectedBl)
  const [whiteExpanded, setWhiteExpanded] = useState([])
  const [blackExpanded, setBlackExpanded] = useState([])
  const [whiteChips, setWhiteChips] = useState([])
  const [blackChips, setBlackChips] = useState([])

  useEffect(() => {
    if (whiteChecked.length) {
      let chipsArr = []

      nodesWhite.forEach((el, idx) => {
        if (whiteChecked.includes(el.value)) {
          chipsArr.push(el)
        } else {
          if (el.children) {
            el.children.forEach((child) => {
              if (whiteChecked.includes(child.value)) {
                chipsArr.push(child)
              }
            })
          }
        }
      })
      setWhiteChips(chipsArr)
    } else {
      setWhiteChips([])
    }
  }, [whiteChecked, nodesWhite])

  useEffect(() => {
    if (blackChecked.length) {
      let chipsArr = []

      nodesBlack.forEach((el, idx) => {
        if (blackChecked.includes(el.value)) {
          chipsArr.push(el)
        } else {
          if (el.children) {
            el.children.forEach((child) => {
              if (blackChecked.includes(child.value)) {
                chipsArr.push(child)
              }
            })
          }
        }
      })
      setBlackChips(chipsArr)
    } else {
      setBlackChips([])
    }
  }, [blackChecked, nodesBlack])

  const removeWhiteChips = (obj) => {
    setWhiteChips(whiteChips.filter((el) => el.value !== obj.value))
    setWhiteChecked(whiteChecked.filter((el) => el !== obj.value))
  }

  const removeBlackChips = (obj) => {
    setBlackChips(blackChips.filter((el) => el.value !== obj.value))
    let result = blackChecked.filter((el) => el !== obj.value)
    setBlackChecked(result)
    handleBlackChange(result)
  }

  return <div className={classes.multiselectBlock}>
    <div className={classes.blockTitle}>
      {!isEmpty(nodesWhite) && <Col6>
        <strong>White list</strong>
      </Col6>}
      {!isEmpty(nodesBlack) && <Col6>
        <strong>Black list</strong>
      </Col6>}
    </div>
    <div className={classes.chipsWrapper}>
      {!isEmpty(whiteChips) && <Col6>
        <div className={classes.chipsBlock}>
          {whiteChips.map((el, idx) => {
            return <div key={idx} className={classes.chips}>
              <span>{el.label}</span>
              <div className={classes.removeSelectedBtn} onClick={() => {removeWhiteChips(el)}}><Icon iconName={'white_cross'} /></div>
            </div>
          })}
        </div>
      </Col6>}
      {!isEmpty(blackChips) && <Col6>
        <div className={classes.chipsBlock}>
          {blackChips.map((el, idx) => {
            return <div key={idx} className={classes.chips}>
              <span>{el.label}</span>
              <div className={classes.removeSelectedBtn} onClick={() => {removeBlackChips(el)}}><Icon iconName={'white_cross'} /></div>
            </div>
          })}
        </div>
      </Col6>}
    </div>
    {!isEmpty(nodesWhite) && <Col6>
      <CheckboxTree
        nodes={nodesWhite}
        checked={whiteChecked}
        expandOnClick={true}
        expanded={whiteExpanded}
        onCheck={el => { setWhiteChecked(el) }}
        onExpand={el => setWhiteExpanded(el)}
        onClick={() => { }}
        icons={{
          check: <span className={classes.iconWrapper}><Icon iconName={'checked'} /></span>,
          uncheck: <span className={classes.iconWrapper}><Icon iconName={'check'} /></span>,
          halfCheck: <span className={classes.iconWrapper}><Icon iconName={'half_check'} /></span>,
          expandClose: <span />,
          expandOpen: <span />,
          expandAll: <span />,
          collapseAll: <span />,
        }}
      />
    </Col6>}
    {!isEmpty(nodesBlack) && <Col6>
      <CheckboxTree
        nodes={nodesBlack}
        checked={blackChecked}
        expandOnClick={true}
        expanded={blackExpanded}
        onCheck={el => { setBlackChecked(el); handleBlackChange(el) }}
        onExpand={el => setBlackExpanded(el)}
        onClick={() => { }}
        icons={{
          check: <span className={classes.iconWrapper}><Icon iconName={'checked'} /></span>,
          uncheck: <span className={classes.iconWrapper}><Icon iconName={'check'} /></span>,
          halfCheck: <span className={classes.iconWrapper}><Icon iconName={'half_check'} /></span>,
          expandClose: <span />,
          expandOpen: <span />,
          expandAll: <span />,
          collapseAll: <span />,
        }}
      />
    </Col6>}

  </div>
}
