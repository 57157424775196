import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  podcastsTable: {
    marginTop: 30
  }
})

export const ItunesSettingsPage = () => {
  return (<p></p>)
}