import API from '../../utils/Api'

import {
    STATS_REQUEST,
    STATS_FETCH_SUCCESS,
    STATS_REQUEST_ERROR,
    STATS_FETCH_TOTALS_SUCCESS,
    STATS_RESET,
    STATS_TOTALS_RESET,
} from '../reducers/statsReducer'

export const fetchStats = (filter) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATS_REQUEST })
      const response = await API.get('/stats/fetch', {filter: filter})

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: STATS_FETCH_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: STATS_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: STATS_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const fetchStatsTotals = (filter) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATS_REQUEST })
      const response = await API.get('/stats/fetchtotals', {filter: filter})

      if (response.data.success) {
        dispatch({ type: STATS_FETCH_TOTALS_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: STATS_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: STATS_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const resetStats = () => {
  return (dispatch) => {
    dispatch({ type: STATS_RESET })
  }
}

export const resetStatTotals = () => {
  return (dispatch) => {
    dispatch({ type: STATS_TOTALS_RESET })
  }
}
