import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label } from '../../index'
import { colors, fonts } from '../../../theme'
import Select from 'react-select'

const useStyles = createUseStyles({
  selectWrapper: {
    position: 'relative',
    minWidth: 120
  },
  errorMessage: {
    position: 'relative',
    left: 0,
    marginTop: '2px',
  },
})

const customStyles = (error, height, width) => {
  return {
    menu: (provided) => ({...provided, zIndex: 2}),
    control: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        width: `${width ? width+'px' : '100%'}`,
        height: `${height ? height : 36}px`,
        padding: [0, 40, 0, 5],
        color: colors.primary_default,
        fontFamily: fonts.notoSans,
        fontSize: 14,
        lineHeight: 24 / 16,
        outline: 'none!important',
        boxShadow: 'none',
        border: `1px solid ${error
          ? colors.error
          : state.hasValue
            ? colors.form_grey
            : colors.form_grey}`,
        borderRadius: 20,
        display: 'flex',
        transition: 'border 300ms ease',
        position: 'relative',
        background: error
          ? colors.error10
          : state.hasValue
            ? colors.white
            : colors.back_1,
        opacity: state.hasValue ? '1' : '.7',
        '&:hover': {
          borderColor: colors.primary_black + ' !important'
        },
      }
    },
    dropdownIndicator: () => ({
      color: colors.primary_default,
      width: 15,
      display: 'flex',
      justifyContent: 'center',
      marginRight: 15,
      marginLeft: 0,
      '& svg': {
        fill: colors.custom_grey,
        stroke: colors.custom_grey,
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.primary_default,
      fontSize: 14,
      lineHeight: 24 / 16,
    }),
    input: () => ({
      display: 'none',
      visibility: 'hidden',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: 10,
      marginTop: 2
    }),
    ValueContainer: () => ({
      padding: '2px 2px 2px 12px !important',
    }),
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: 19,
        paddingRight: 15,
        paddingBottom: 5,
        margin: 0,
        height: 'fit-content',
        fontSize: 14,
        color: colors.primary_black,
        textAlign: 'start',
        backgroundColor: state.data === state.selectProps.value ? colors.light_grey : colors.background_white,
        '&:hover': {
          backgroundColor: colors.light_grey
        }
      }
    }
  }
}

export const Selector = ({
  id,
  label,
  options = [],
  className,
  height,
  width,
  value,
  defaultValue,
  error,
  isMulti,
  isClearable = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <>
      {label &&
        <Label htmlFor={id}>{label}</Label>
      }
      <div className={classes.selectWrapper}>
        <Select
          defaultValue={defaultValue}
          isMulti={isMulti}
          isSearchable={false}
          isClearable={isClearable}
          name="colors"
          options={options}
          id={id}
          value={value}
          styles={customStyles(error, height, width)}
          className={classnames(className, error ? 'error' : '')}
          {...rest}
        />
      </div>
      {/* {error &&
        <Error className={classnames(classes.errorMessage)}>{error}</Error>
      } */}
    </>
  )
};