import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  bar: {
   
  },
})

export const ActionBar = ({ className, children }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.bar, className)}>
        {children}
    </div>
  )
}