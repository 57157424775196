const initialState = {
    loading: false,
    errors: [],

    stats: [],
    totals: {
      downloads: 0,
      shows: 0,
      revenue: 0,
      completes: 0
    },

    showsByErid: [],
    showsByPlacement: [],
    showsByPlacementPie: [],
    showsByGeoCities: [],
    showsByDevice: [],
    showsByApps: [],
    showsByItunesCat: [],
    chartLegends: [],
    showsByPlacementLegend: [],
  }
  
  export const advertiserReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADV_STATS_REQUEST:
        return { ...state, loading: true }
      case ADV_STATS_FETCH_SUCCESS:
        return { 
          ...state, 
          loading: false, 
          stats: action.payload.stats, 
          totals: action.payload.totals, 
          chartLegends: action.payload.chartLegends,
          showsByErid: action.payload.showsByErid,
          showsByPlacement: action.payload.showsByPlacement,
          showsByPlacementPie: action.payload.showsByPlacementPie,
          showsByGeoCities: action.payload.showsByGeoCities,
          showsByDevice: action.payload.showsByDevice,
          showsByApps: action.payload.showsByApps,
          showsByItunesCat: action.payload.showsByItunesCat,
          showsByPlacementLegend: action.payload.showsByPlacementLegend,
        }
  
      case ADV_STATS_RESET:
        return { ...state, loading: false, stats: []}
        case ADV_STATS_TOTALS_RESET:
        return { ...state, loading: false, totals: initialState.totals}
  
      case ADV_STATS_FETCH_TOTALS_SUCCESS:
        return { ...state, loading: false, totals: action.payload.totals }
      case ADV_STATS_REQUEST_ERROR:
        return { ...state, loading: false, errors: action.payload }
  
      default:
        return state
    }
  }
  
  export const ADV_STATS_REQUEST = 'STATS_REQUEST'
  export const ADV_STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS'
  export const ADV_STATS_REQUEST_ERROR = 'STATS_REQUEST_ERROR'
  export const ADV_STATS_FETCH_TOTALS_SUCCESS = 'STATS_FETCH_TOTALS_SUCCESS'
  export const ADV_STATS_RESET = 'STATS_RESET'
  export const ADV_STATS_TOTALS_RESET = 'STATS_TOTALS_RESET'
  
  
  
  