import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, fonts } from '../../theme'
import { Icon } from '../Icon'
import { useOutsideClickHandler, isAdmin } from '../../utils'

const useStyles = createUseStyles({
  actionMenu: {
    position: 'absolute',
    left: 30,
    top: -15,
    minWidth: 168,
    padding: [10, 0],
    background: colors.background_white,
    boxShadow: [0, 3, 12, 3, 'rgba(194, 199, 204, 0.3)'],
    borderRadius: 8,
  },
  option: {
    color: colors.dark_grey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%',
    padding: [7, 16],
    '&:hover': {
      color: colors.primary_black,
      background: colors.primary_yellow
    }
  }
})

export const More = ({
  options
}) => {
  const classes = useStyles()

  const [showOptions, setShowOptions] = useState(false)

  const user = useSelector(state => state.account.user)

  const handleClose = () => {
    setShowOptions(false)
  }

  const handleMenuClick = (e) => {
    setShowOptions(!showOptions)
    e.stopPropagation()
  }

  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, handleClose)

  return (
    <div ref={wrapperRef} onClick={handleMenuClick}>
      <Icon iconName='dots_action' />
      {(showOptions && options.length) && <div className={classes.actionMenu}>
        <ul>
          {options.map((option, idx) => {
            if (!option.adminOnly || (option.adminOnly && isAdmin(user.user_type))) {
              return <li
                key={idx}
                className={classes.option}
                onClick={option.clickHandler}
              >
                {option.title}
              </li>
            }
          }
          )}
        </ul>
      </div>}
    </div>
  )
}