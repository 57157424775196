import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, NavLink } from 'react-router-dom'
import { Icon, Notification } from '../../components'
import { centered, colors, justified } from '../../theme'

const useStyles = createUseStyles({
  auth: {
    background: colors.light_grey,
    padding: 24,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  container: {
    height: '100%',
    ...justified,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    background: colors.light_grey,
    width: '100%',
    height: '100%',
    paddingLeft: 12,
  },
  wrapper: {
    maxWidth: 732,
    background: colors.white,
    borderRadius: 12,
    ...centered,
    flexDirection: 'column'
  },
  pageHeader: {
    width: '100%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    margin: [17, 0, 28, 0]
  },
  pageLogo: {
    width: 226,
    height: 69,
    '& svg': {
      width: 226,
      height: 69,
    }
  },
  pageSwitch: {
    display: 'flex',
    alignItems: 'end',
    paddingBottom: 10,
    fontSize: 14,
    lineHeight: '130%',
    '& svg': {
      width: 23,
      height: 23,
      marginRight: 10
    },
    '& span': {
      marginBottom: 2
    }
  },
  bgLogo: {
    position: 'absolute',
    bottom: -52,
    right: 125,
    zIndex: 1,
    '& svg': {
      width: 613,
      height: 641
    }
  }
})

export const Auth = ({
  children
}) => {
  const classes = useStyles()

  const history = useHistory()
  const currentPage = history.location.pathname

  return (
    <>
      <div className={classes.auth}>
        <div className={classes.bgLogo}>
          <Icon iconName={'bg_logo'} />
        </div>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              <div className={classes.pageHeader}>
                <div className={classes.pageLogo}>
                  <Icon iconName={'auth_logo'} />
                </div>
                <NavLink
                  activeClassName='active'
                  className={classes.pageSwitch}
                  to={currentPage === '/login' ? '/sign-up' : '/login'}
                >
                  <Icon iconName={'arrow_link'} />
                  <span>{currentPage === '/login' ? 'Зарегистрироваться' : 'Войти в аккаунт'}</span>
                </NavLink>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
      <Notification toast />
    </>
  )
}