import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { AddEridForm } from "./AddEridForm";
// import { EditAdsSystemModal } from "./EditAdsSystemModal";
import { isEmpty } from "lodash";

const useStyles = createUseStyles({
  eridTable: {
    marginTop: 30,
  },
})

export const EridSettingsPage = () => {
  const classes = useStyles()
  const { fetchEridList, cleanEridList } = useActions()

  const [eridsData, setEridsData] = useState([])
  // const [editingSystem, setEditingSystem] = useState({})
  // const [editingField, setEditingField] = useState('')
  // const [editingValue, setEditingValue] = useState(null)

  const { eridList, advertiserList, loading } = useSelector(state => state.admin)
  // const user = useSelector(state => state.account.user)

  useEffect(() => {
    fetchEridList()
    return () => cleanEridList()
  }, [])

  useEffect(() => {
    if (eridList.length) {
      let eridsArr = []
      eridList.map((erid) => {
        eridsArr.push(
          {
            columns: [
              { key: 'erid', type: 'text', value: erid.erid },
              { key: 'title', type: 'text', value: erid.title },
              { key: 'advertiser', type: 'text', value: erid.advertiser.email },
              { key: 'campaign', type: 'text', value: erid.campaign ? erid.campaign : '-' }
            ]
          }
        )
        setEridsData(eridsArr)
      })
    }
  }, [eridList])

  const HEADERS = [
    { name: 'ЕРИД', colSpan: 1 },
    { name: 'Название ЕРИД', colSpan: 1 },
    { name: 'Рекламодатель', colSpan: 1 },
    { name: 'Название РК', colSpan: 1 },
  ]

  // const handleCellClick = (system, field, value) => {
  //   setEditingSystem(system)
  //   setEditingField(field)
  //   setEditingValue(value)
  // }

  // const handleEditModalSave = (podcast_id, data = {}) => {
  //   editAdsSystem(podcast_id, data)
  // }

  // const handleCloseModal = () => {
  //   setEditingSystem({})
  //   setEditingField('')
  //   setEditingValue(null)
  // }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <AddEridForm advertiserList={advertiserList}/>
            {!eridsData.length
              ? <div>
                <H1>Список ЕРИДов пуст.</H1>
              </div>
              : <div>
                <H1>Список ЕРИДов:</H1>
                <div className={classes.eridTable}>
                  <Table headers={HEADERS} items={eridsData} />
                </div>
              </div>
            }
            <Notification toast />
            {/* {(!isEmpty(editingSystem) && editingField) && <EditAdsSystemModal
              onClose={() => handleCloseModal()}
              onSave={handleEditModalSave}
              adsSystem={editingSystem}
              field={editingField}
              value={editingValue}
            />} */}
          </Page>
        }
      </Template>
    </>
  )
}