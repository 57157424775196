import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { H1 } from "../../components/Typography";
import { Loader, Button, Notification } from "../../components";
import { Checkbox } from '../../components/Form/Checkbox'
import { Page, Template } from "../../containers";
import { AudioFormatsSection } from "./AudioFormatsSection"
import { useActions } from "../../hooks";
import { colors } from "../../theme";

const useStyles = createUseStyles({
  distroSettingsBLock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 15
  },
  distroSetting: {
    width: 500,
    margin: [10, 0, 10, 0],
  },
  distroTelecomsBLock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 15
  },
  distroTelecom: {
    width: 500,
    margin: [10, 0, 10, 0],
  },
  section: {
    marginBottom: 50
  },
  saveBtn: {
    marginBottom: 75
  }
})

export const DistributionSettingsPage = () => {
  const classes = useStyles()
  const { fetchDistroSettings, saveDistroSettings } = useActions()

  const [distributionSettings, setDistributionSettings] = useState([])
  const [distributionTelecoms, setDistributionTelecoms] = useState([])
  const [changedSettings, setChangedSettings] = useState({})
  const [changedTelecoms, setChangedTelecoms] = useState({})

  const { distribution_settings, distribution_telecoms, defaultFormats, loading } = useSelector(state => state.superadmin)

  useEffect(() => {
    fetchDistroSettings()
  }, [])

  useEffect(() => {
    setDistributionSettings(distribution_settings)
  }, [distribution_settings])

  useEffect(() => {
    setDistributionTelecoms(distribution_telecoms)
  }, [distribution_telecoms])

  const handleChangeSetting = (name, value) => {
    const newSettings = [...distributionSettings]

    setDistributionSettings(newSettings.map(item => {
      return (item.setting_name === name)
        ? { ...item, setting_value: value ? '0' : '1' }
        : item
    }))

    setChangedSettings({ ...changedSettings, [name]: value ? '0' : '1' })
  }

  const handleChangeTelecom = (name, value) => {
    const newTelecomSettings = [...distributionTelecoms]

    setDistributionTelecoms(newTelecomSettings.map(item => {
      return (item.telecom_name === name)
        ? { ...item, redirect_status: value ? '0' : '1' }
        : item
    }))
    setChangedTelecoms({ ...changedTelecoms, [name]: value ? '0' : '1' })
  }

  const handleSave = () => {
    saveDistroSettings(changedSettings, changedTelecoms)
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            {!distributionSettings.length
              ? <div>
                <H1>Настройки дистрибуции отсутствуют.</H1>
              </div>
              : <div className={classes.section}>
                <H1>Настройки дистрибуции:</H1>
                <div className={classes.distroSettingsBLock}>
                  {distributionSettings.map((setting, idx) => <div className={classes.distroSetting} key={idx}>
                    <Checkbox id={setting.setting_name} label={setting.setting_label} checked={parseInt(setting.setting_value)} onChange={() => handleChangeSetting(setting.setting_name, parseInt(setting.setting_value))} />
                  </div>
                  )}
                </div>
              </div>
            }
            {!distributionTelecoms.length
              ? <div>
                <H1>Настройки телекомов отсутствуют.</H1>
              </div>
              : <div className={classes.section}>
                <H1>Настройки телекомов:</H1>
                <div className={classes.distroTelecomsBLock}>
                  {distributionTelecoms.map((telecom, idx) => <div className={classes.distroTelecom} key={idx}>
                    <Checkbox id={telecom.telecom_name} label={"Редиректить трафик в <b>" + telecom.telecom_label + "</b>"} checked={parseInt(telecom.redirect_status)} onChange={() => handleChangeTelecom(telecom.telecom_name, parseInt(telecom.redirect_status))} />
                  </div>
                  )}
                </div>
              </div>
            }
            <div className={classes.saveBtn}>
              <Button className={'primary'} onClick={handleSave}>Сохранить</Button>
            </div>
            <AudioFormatsSection defaultFormats={defaultFormats} />
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}