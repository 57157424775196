const initialState = {
  loading: false,
  errors: [],
  totals: {
    listens: 0,
    downloads: 0,
    shows: 0,
    revenue: 0,
    completes: 0
  },
  stats: [],
  listenerStats: [],
  chartLegends: [],
  showsByPlacement: [],
  showsByPlacementLegend: [],
  listenersByPodcast: [],
  showsByPodcast: [],
  listenersByItunesCategory: [],
  listenersByCities: [],
  listenersByApps: [],
  listenersByDevice: [],
}

export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS_REQUEST:
      return { ...state, loading: true }
    case STATS_FETCH_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        stats: action.payload.stats, 
        listenerStats: action.payload.listenerStats, 
        chartLegends: action.payload.chartLegends,
        showsByPlacement: action.payload.showsByPlacement,
        showsByPlacementLegend: action.payload.showsByPlacementLegend,
        listenersByPodcast: action.payload.listenersByPodcast,
        showsByPodcast: action.payload.showsByPodcast,
        listenersByItunesCategory: action.payload.listenersByItunesCategory,
        listenersByCities: action.payload.listenersByCities,
        listenersByApps: action.payload.listenersByApps,
        listenersByDevice: action.payload.listenersByDevice,
      }

    case STATS_RESET:
      return { ...state, loading: false, stats: []}
      case STATS_TOTALS_RESET:
      return { ...state, loading: false, totals: initialState.totals}

    case STATS_FETCH_TOTALS_SUCCESS:
      return { ...state, loading: false, totals: action.payload.totals }
    case STATS_REQUEST_ERROR:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export const STATS_REQUEST = 'STATS_REQUEST'
export const STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS'
export const STATS_REQUEST_ERROR = 'STATS_REQUEST_ERROR'
export const STATS_FETCH_TOTALS_SUCCESS = 'STATS_FETCH_TOTALS_SUCCESS'
export const STATS_RESET = 'STATS_RESET'
export const STATS_TOTALS_RESET = 'STATS_TOTALS_RESET'



