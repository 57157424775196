import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Action } from '../../components'
import { colors, centered, boxShadow } from '../../theme'

const useStyles = createUseStyles({
  paginationBlock: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    '& div': {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
    }
  },
  pagination: {
    color: colors.primary_black,
    fontSize: 14,
    lineHeight: 22 / 14,
    ...centered,
  },
  currentPage: {
    width: 36,
    height: 36,
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 8,
    color: colors.primary_black,
    backgroundColor: colors.primary_yellow,
    ...centered,
  },
  anotherPage: {
    marginLeft: 12,
    '& + &': {
      marginLeft: 6,
    },
  },
  size: {
    color: colors.primary_light_1,
    fontSize: 14,
    lineHeight: 22 / 14,
    ...centered,
  },
  sizeValue: {
    width: 36,
    height: 36,
    marginLeft: 12,
    border: [1, 'solid', colors.primary_light_1],
    borderRadius: 8,
    ...centered,
    cursor: 'pointer',
    '&:hover': {
      color: colors.background_white,
      backgroundColor: colors.primary_black,
    },
    '&.active': {
      color: colors.primary_black,
      backgroundColor: colors.primary_yellow,

    },
    '& + &': {
      marginLeft: 6,
    },
    '& > svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },
})

const SIZES = [25, 50, 100]

export const Pagination = ({
  changePagination,
}) => {
  const classes = useStyles()

  const [size, setSize] = useState()

  const { pagination } = useSelector(state => state.admin.podcastFilter);

  useEffect(() => {
    setSize(pagination.pageSize)
  }, [pagination])

  const onSize = (value) => {
    setSize(value)
    changePagination({...pagination, pageSize: value})
  }

  const handlePageChange = (page) => {
    if (page > pagination.totalPages || page < 1) return false
    changePagination({...pagination, currentPage: page})
  }

  return (
    <div className={classes.paginationBlock}>
      {true &&
        <div className={classes.size}>
          <div>Выводить по:</div>
          {SIZES.map((value, index) =>
            <div
              className={classnames(classes.sizeValue, { 'active': size === value })}
              onClick={() => onSize(value)}
              key={index}
            >
              {value}
            </div>
          )}
        </div>
      }
      <div className={classes.pagination}>
        <div>Страница:</div>
        <div className={classes.currentPage} title='Текущая страница'>
          {pagination.currentPage}
        </div>
        <div>из {pagination.totalPages}</div>
        <Action
          className={classes.anotherPage}
          icon='arrow-left'
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          title='Предыдущая страница'
        />
        <Action
          className={classes.anotherPage}
          icon='arrow-right'
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          title='Следующая страница'
        />
      </div>
    </div>
  )
}