import * as podcastActionCreators from './podcast'
import * as episodeActionCreators from './episode'
import * as statsActionCreators from './stats'
import * as accountActionCreators from './account'
import * as adminActionCreators from './admin'
import * as notificationActionCreators from './notification'
import * as superadminActionCreators from './superadmin'
import * as advertiserActionCreators from './advertiser'

export const actionCreators = {
    ...podcastActionCreators,
    ...episodeActionCreators,
    ...statsActionCreators,
    ...accountActionCreators,
    ...notificationActionCreators,
    ...adminActionCreators,
    ...superadminActionCreators,
    ...advertiserActionCreators
}

