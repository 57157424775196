import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button, Selector } from "../../components";
import { useActions } from "../../hooks";

const useStyles = createUseStyles({
  addMappingForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginTop: 30,
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddMappingForm = () => {
  const classes = useStyles()

  const [oldUrl, setOldUrl] = useState('')
  const [newUrl, setNewUrl] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { mappingErrors, loading } = useSelector(state => state.superadmin)

  const { addMapping, clearSuperadminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!oldUrl.trim() || mappingErrors.oldUrl) errors_arr.oldUrl = mappingErrors.oldUrl ? mappingErrors.oldUrl : ERRORS.required
    if (!newUrl.trim() || mappingErrors.newUrl) errors_arr.newUrl = mappingErrors.newUrl ? mappingErrors.newUrl : ERRORS.required

    setErrors(errors_arr);
  }, [newUrl, oldUrl])

  const isValid = oldUrl !== '' && newUrl !== ''

  const onAddmapping = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        oldUrl: oldUrl,
        newUrl: newUrl,
      }

      clearSuperadminState()
      addMapping(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'large',
      type: 'text',
      name: 'old_ep_url',
      label: 'Старый URL',
      placeholder: 'Введите старый URL',
      value: oldUrl,
      onChange: (e) => {
        setOldUrl(e.target.value);
      },
      error: submitted > 0 && errors.oldUrl,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 'new_ep_url',
      label: 'Новый URL',
      placeholder: 'Введите новый URL',
      value: newUrl,
      onChange: (e) => {
        setNewUrl(e.target.value);
      },
      error: submitted > 0 && errors.newUrl,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addMappingForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={onAddmapping}>Сохранить</Button>
          </div>
        </div>}
    </>
  )
}