import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { Loader, Fieldset, Input, Button, Selector } from "../../components";

const useStyles = createUseStyles({
  tableFilterBar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginTop: 30,
  },
  pretargetTypeSelector: {
    height: 48
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  resetBtn: {
    marginBottom: 35,
    marginLeft: 15
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

export const TableFilterBar = ({handleSearch}) => {
  const classes = useStyles()

  const [userEmail, setUserEmail] = useState('')
  const [adSystemName, setAdSystemName] = useState('')
  const [pretargetType, setPretargetType] = useState('')

  const { loading, pretargetFilter } = useSelector(state => state.admin)

  useEffect(() => {
    if (pretargetFilter?.filter?.userEmail) setUserEmail(pretargetFilter.filter.userEmail)
    if (pretargetFilter?.filter?.adSystemName) setAdSystemName(pretargetFilter.filter.adSystemName)
    if (pretargetFilter?.filter?.pretargetType) setPretargetType(pretargetFilter.filter.pretargetType)
  }, [pretargetFilter])

  const handleReset = () => {
    setUserEmail('')
    setAdSystemName('')
    setPretargetType('')
    handleSearch({})
  }

  const INPUTS = [
    {
      size: 'medium',
      type: 'text',
      name: 'userEmail',
      label: 'Email пользователя',
      placeholder: '',
      value: userEmail,
      onChange: (e) => {
        setUserEmail(e.target.value);
      },
    //   error: submitted > 0 && errors.userIds,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'text',
      name: 'adSystemName',
      label: 'Рекламная система',
      placeholder: '',
      value: adSystemName,
      onChange: (e) => {
        setAdSystemName(e.target.value);
      },
    //   error: submitted > 0 && errors.adsystemId,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'select',
      name: 'pretargetType',
      label: 'Тип претаргетинга',
      options: [
        { label: 'include', value: 'include' },
        { label: 'exclude', value: 'exclude' }
      ],
      placeholder: '',
      value: pretargetType,
      onChange: (type) => {
        setPretargetType(type);
      },
    //   error: submitted > 0 && errors.pretargetType,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.tableFilterBar}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={() => handleSearch({userEmail, adSystemName, pretargetType})}>Поиск</Button>
          </div>
          <div className={classes.resetBtn}>
            <Button className={'secondary'} onClick={handleReset}>Сбросить фильтр</Button>
          </div>
        </div>}
    </>
  )
}