import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Modal, Fieldset, Input, Button, Selector } from "../../components"

const useStyles = createUseStyles({
  title: {
    margin: [12, 0, 12, 0],
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 700,
  },
  relative: {
    position: 'relative'
  },
  button: {
    width: 100,
    margin: [0, 6],
  },
  wr: {
    minWidth: 400,
    padding: [0, 6],
    position: 'relative'
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'start',
    marginTop: 30,
  },
  fieldset: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'start',
    width: '100%',
    margin: [0, 0, 30, 0],
    '& > label': {
      marginRight: 30
    }
  },
  saveBtn: {
    alignSelf: 'end'
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

export const EditAdsSystemModal = ({
  onClose,
  onSave,
  adsSystem,
  field,
  value
}) => {
  const classes = useStyles()

  const [changedFieldValue, setChangedFieldValue] = useState(value)

  const INPUTS = {
    preroll_link: {
      size: 'large',
      type: 'text',
      name: 'preroll_link',
      label: 'preroll_link',
      placeholder: '',
      value: changedFieldValue,
      onChange: (e) => {
        setChangedFieldValue(e.target.value);
      },
      error: false,
      errorClassName: classes.error,
    },
    midroll_link: {
      size: 'large',
      type: 'text',
      name: 'midroll_link',
      label: 'midroll_link',
      placeholder: '',
      value: changedFieldValue,
      onChange: (e) => {
        setChangedFieldValue(e.target.value);
      },
      error: false,
      errorClassName: classes.error,
    },
    postroll_link: {
      size: 'large',
      type: 'text',
      name: 'postroll_link',
      label: 'postroll_link',
      placeholder: '',
      value: changedFieldValue,
      onChange: (e) => {
        setChangedFieldValue(e.target.value);
      },
      error: false,
      errorClassName: classes.error,
    },
  }

  const handleSave = () => {
    onSave(adsSystem.id, { field: field, value: changedFieldValue })
    onClose()
  }

  return (
    <Modal withClose withoutScroll onClose={onClose}>
      <div className={classes.title}>{`Редактирование Рекламной системы`}</div>
      <div className={classes.relative}>{`"${adsSystem.system_name}"`}</div>
      <div className={classes.wr}>
        {<div className={classes.editForm}>
          <Fieldset className={classes.fieldset}>
            {INPUTS[field].type === 'select'
              ? <Selector height={48} {...INPUTS[field]} />
              : <Input {...INPUTS[field]} />
            }
          </Fieldset>
          <div className={classes.saveBtn}>
            <Button className={'primary'} onClick={handleSave}>Сохранить</Button>
          </div>
        </div>}
      </div>
    </Modal>
  )
}
