import React from 'react'
import { createUseStyles } from 'react-jss'
import Switch from 'react-switch'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  switchWrapper: {
    '& label': {
      display: 'flex',
      alignItems: 'end',
      '& span': {
        fonrSize: 14,
        lineHeight: '130%',
        marginLeft: 10
      }
    }
  },
})

export const SwitchButton = ({
  handleChange,
  checked,
  label,
}) => {
  const classes = useStyles()

  return <div className={classes.switchWrapper}>
    <label>
      <Switch
        onChange={handleChange}
        checked={checked}
        className={'react-switch'}
        onColor={colors.primary_yellow}
        offColor={colors.primary_black}
        uncheckedIcon={false}
        checkedIcon={false}
        width={48}
        height={24}
        handleDiameter={20}
      />
      <span>{label}</span>
    </label>
  </div>
}
