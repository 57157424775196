import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, fonts } from '../../../theme'

const useStyles = createUseStyles({
  label: {
    paddingBottom: 8,
    fontFamily: fonts.notoSans,
    color: colors.primary_light_1,
    fontSize: 14,
    lineHeight: 22/14,
    display: 'block',
  },
  bold: {
    fontWeight: 600
  }
})

export const Label = ({
  className,
  htmlFor,
  children,
}) => {
  const classes = useStyles()
  return (
    <label className={classnames(classes.label, classes[className])} htmlFor={htmlFor}>
      {children}
    </label>
  )
}