import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { Loader, Fieldset, Input, Button, Selector } from "../../components";

const useStyles = createUseStyles({
  tableFilterBar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginTop: 30,
  },
  pretargetTypeSelector: {
    height: 48
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  resetBtn: {
    marginBottom: 35,
    marginLeft: 15
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

export const TableFilterBar = ({ handleSearch }) => {
  const classes = useStyles()

  const [userEmail, setUserEmail] = useState('')
  const [status, setStatus] = useState('')

  const { loading, podcastFilter } = useSelector(state => state.admin)

  useEffect(() => {
    if (podcastFilter?.filter?.userEmail) setUserEmail(podcastFilter.filter.userEmail)
    if (podcastFilter?.filter?.status) setStatus(podcastFilter.filter.status)
  }, [podcastFilter])

  const handleReset = () => {
    setUserEmail('')
    setStatus('')
    handleSearch({ userEmail: "", status: "" })
  }

  const INPUTS = [
    {
      size: 'medium',
      type: 'text',
      name: 'userEmail',
      label: 'Email пользователя',
      placeholder: '',
      value: userEmail,
      onChange: (e) => {
        setUserEmail(e.target.value);
      },
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'select',
      name: 'status',
      label: 'Статус',
      options: [
        { label: 'pending', value: 'pending' },
        { label: 'published', value: 'published' }
      ],
      placeholder: '',
      value: status,
      onChange: (status) => {
        setStatus(status);
      },
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.tableFilterBar}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={() => handleSearch({ userEmail, status })}>Поиск</Button>
          </div>
          <div className={classes.resetBtn}>
            <Button className={'secondary'} onClick={handleReset}>Сбросить фильтр</Button>
          </div>
        </div>}
    </>
  )
}