import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { colors, fonts, centered } from '../../theme'
import { Icon } from '../Icon'
import { useOutsideClickHandler } from '../../utils'
import { useActions } from '../../hooks/useActions'
import { H4 } from '../Typography'

const useStyles = createUseStyles({
  accountBtn: {
    marginBottom: 15,
    cursor: 'pointer',
    ...centered,

    '& > svg': {
      width: 24,
      height: 24,
      marginLeft: 2,
      stroke: colors.primary_default,
      display: 'inline-block',
      verticalAlign: 'top',
      transition: 'transform 300ms ease',
    },
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& > svg': {
      width: 40,
      height: 40,
      borderRadius: '50%',
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 10,
      height: 10,
      borderRadius: '50%',
      background: colors.success,
      border: [2, 'solid', colors.white],
    },
  },
  userInfo: {
    padding: [7, 16],
    cursor: 'auto',
    backgroundColor: colors.light_grey,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    '& p': {
      marginBottom: 7
    },
  },
  actionMenu: {
    position: 'absolute',
    left: 45,
    bottom: 0,
    minWidth: 147,
    background: colors.background_white,
    boxShadow: [0, 3, 12, 3, 'rgba(194, 199, 204, 0.3)'],
    borderRadius: 8,
    cursor: 'auto',
    zIndex: 1,
  },
  option: {
    color: colors.dark_grey,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%',
    cursor: 'pointer',
    padding: [7, 16],
    '&:hover': {
      color: colors.primary_black,
      background: colors.primary_yellow
    },
    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    }
  }
})

export const AccountMenu = ({

}) => {
  const classes = useStyles()
  const { logout } = useActions()

  const [showMenu, setShowMenu] = useState(false)

  const user = useSelector(state => state.account.user)
  const OPTIONS = [
    // {
    //   title: 'Профиль',
    //   clickHandler: () => {alert('Профиль')}
    // },
    {
      title: 'Выйти',
      clickHandler: () => {logout()}
    },
  ]

  const USERS_ARRAY = {
    'admin': 'Администратор',
    'podcaster': 'Пользователь',
    'super_admin': 'Супер_aдмин',
    'pc_manager': 'Подкаст менеджер',
    'partner': 'Партнер',
    'advertiser': 'Рекламодатель',
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const accountRef = useRef(null);
  useOutsideClickHandler(accountRef, closeMenu)

  return (
    <div ref={accountRef} className={classes.accountBtn} onClick={toggleMenu}>
      <div className={classes.avatar}>
        <Icon iconName='user_avatar' />
        {(showMenu && OPTIONS.length) && <div className={classes.actionMenu}>
          <div className={classes.userInfo}>
            <H4>{user.username}</H4>
            <p>{USERS_ARRAY[user.user_type]}</p>
            <b>{user.email}</b>
          </div>
          <ul>
            {OPTIONS.map((option, idx) => <li
              key={idx}
              className={classes.option}
              onClick={option.clickHandler}
            >
              {option.title}
            </li>
            )}
          </ul>
        </div>}
      </div>
    </div>
  )
}