import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { AddPartnerUserForm } from "./AddPartnerUserForm";

const useStyles = createUseStyles({
  usersTable: {
    marginTop: 30
  },
})

export const PartnerUsersPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchUserList, cleanUserList, toggleSendAdfoxPcId, switchUserPodcasts } = useActions()

  const [usersData, setUsersData] = useState([])

  const { userList, loading } = useSelector(state => state.admin)
  const user = useSelector(state => state.account.user)

  useEffect(() => {
    fetchUserList()
    return () => cleanUserList()
  }, [])

  useEffect(() => {
    if (userList.length) {
      let usersArr = []
      userList.map((user_data) => {
        let whiteList = []
        let blackList = []

        user_data.whiteList.map((item) => {
          whiteList.push(`(${item.adsystem_id}) ${item.adsystem_name}`)
        })

        user_data.blackList.map((item) => {
          blackList.push(`(${item.adsystem_id}) ${item.adsystem_name}`)
        })

        usersArr.push(
          {
            columns: [
              { key: 'user_name', type: 'text', value: user_data.username },
              { key: 'email', type: 'text', value: user_data.email },
            ]
          }
        )
        setUsersData(usersArr)
      })
    }
  }, [userList])

  const HEADERS = [
    { name: 'Пользователь', colSpan: 1 },
    { name: 'Email', colSpan: 1 },
  ]

  const handleSwitchUserPodcasts = (user_id, user_name, status) => {
    if (window.confirm(`Подкасты пользователя ${user_name} будут переведены в статус "${status}"`)) {
      switchUserPodcasts(user_id, status)
    }
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <AddPartnerUserForm />
            {!userList.length
              ? <div>
                <H1>Пользователи отсутствуют</H1>
              </div>
              : <div>
                <H1>Ваши пользователи:</H1>
                <div className={classes.usersTable}>
                  <Table headers={HEADERS} items={usersData} />
                </div>
              </div>
            }
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}