import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  h3: {
    color: colors.text_black,
    fontSize: 18,
    lineHeight: 28/18,
    fontWeight: 600,
  },
})

export const H3 = ({ children, className }) => {
  const classes = useStyles()
  return <div className={classnames(classes.h3, className)}>{children}</div>
}
