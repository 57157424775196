import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button, Selector } from "../../../components";
import { useActions } from "../../../hooks";
import { inputNumericFilter } from "../../../utils";

const useStyles = createUseStyles({
  addPodcastGroupForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginTop: 30,
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddAudioFormatForm = () => {
  const classes = useStyles()

  const [bitrate, setBitrate] = useState('')
  const [sampling, setSampling] = useState('')
  const [channels, setChannels] = useState({label: 'stereo', value: 'stereo'})
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { audioFormatsErrors, loading } = useSelector(state => state.superadmin)

  const { addAudioFormat, clearSuperadminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!bitrate.trim() || audioFormatsErrors.bitrate) errors_arr.bitrate = audioFormatsErrors.bitrate ? audioFormatsErrors.bitrate : ERRORS.required
    if (!sampling.trim() || audioFormatsErrors.sampling) errors_arr.sampling = audioFormatsErrors.sampling ? audioFormatsErrors.sampling : ERRORS.required
    if (isEmpty(channels) || audioFormatsErrors.channels) errors_arr.channels = audioFormatsErrors.channels ? audioFormatsErrors.channels : ERRORS.required

    setErrors(errors_arr);
  }, [bitrate, sampling, channels])

  const isValid = bitrate !== '' && sampling !== '' && channels !== ''

  const onAddAudioFormat = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        bitrate: bitrate,
        sampling: sampling,
        channels: channels.value,
      }

      clearSuperadminState()
      addAudioFormat(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'medium',
      type: 'text',
      name: 'podcast_group',
      label: 'Битрейт',
      placeholder: '',
      value: bitrate,
      handleKeyPress: (e) => inputNumericFilter(e),
      onChange: (e) => {
        setBitrate(e.target.value);
      },
      error: submitted > 0 && errors.bitrate,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'text',
      name: 'podcast_group',
      label: 'Сэмплинг',
      placeholder: '',
      value: sampling,
      handleKeyPress: (e) => inputNumericFilter(e),
      onChange: (e) => {
        setSampling(e.target.value);
      },
      error: submitted > 0 && errors.sampling,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'select',
      name: 'channels',
      label: 'Каналы',
      options: [{label: 'stereo', value: 'stereo'}, {label: 'mono', value: 'mono'}],
      placeholder: '',
      value: channels,
      onChange: (el) => {
        setChannels(el);
      },
      error: submitted > 0 && errors.channels,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addPodcastGroupForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={onAddAudioFormat}>Добавить</Button>
          </div>
        </div>}
    </>
  )
}