const initialState = {
  loading: false,
  distribution_settings: [],
  distribution_telecoms: [],

  defaultFormats: [],
  audioFormatsErrors: [],

  mappingList: [],
  mappingErrors: [],
  mappingFilter: {
    filter: {
      oldUrl: '',
      newUrl: '',
    },
    sortBy: 'id',
    sortOrder: 'ASC',
    page: 1,
    pageLimit: 30,
  },

  proxyHostingList: [],
  proxyHostingErrors: [],
  proxyHostingFilter: {
    filter: {
      proxyHosting: '',
    },
    sortBy: 'id',
    sortOrder: 'ASC',
    page: 1,
    pageLimit: 30,
  },

  podcastGroupList: [],
  podcastGroupErrors: [],
  podcastGroupFilter: {
    filter: {
      groupName: '',
    },
    sortBy: 'id',
    sortOrder: 'ASC',
    page: 1,
    pageLimit: 30,
  },

  errors: []
}

export const superadminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPERADMIN_REQUEST:
      return { ...state, loading: true, ...action.payload }
    case SUPERADMIN_REQUEST_ERROR:
      return { ...state, loading: false }
    case SUPERADMIN_REQUEST_STATE_CLEAR:
      return { ...state, loading: false, errors: [] }

    //Distro settings
    case SUPERADMIN_FETCH_DISTRO_SETTINGS_SUCCESS:
    case SUPERADMIN_SAVE_DISTRO_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        distribution_settings: action.payload.distribution_settings,
        distribution_telecoms: action.payload.distribution_telecoms,
        defaultFormats: action.payload.defaultFormats
      }
    case SUPERADMIN_AUDIO_FORMAT_ADD_SUCCESS:
    case SUPERADMIN_REMOVE_AUDIO_FORMAT_SUCCESS:
      return { ...state, loading: false, defaultFormats: action.payload }

    //Episodes mapping
    case SUPERADMIN_FETCH_MAPPING_LIST_SUCCESS:
    case SUPERADMIN_REMOVE_MAPPING_SUCCESS:
    case SUPERADMIN_MAPPING_ADD_SUCCESS:
      return { ...state, loading: false, mappingList: action.payload }
    case SUPERADMIN_CLEAN_MAPPING_LIST:
      return { ...state, loading: false, mappingList: [] }

    //Proxy hostings
    case SUPERADMIN_FETCH_PROXY_HOSTINGS_LIST_SUCCESS:
    case SUPERADMIN_REMOVE_PROXY_HOSTINGS_SUCCESS:
    case SUPERADMIN_PROXY_HOSTINGS_ADD_SUCCESS:
      return { ...state, loading: false, proxyHostingList: action.payload }
    case SUPERADMIN_CLEAN_PROXY_HOSTINGS_LIST:
      return { ...state, loading: false, proxyHostingList: [] }

    //Podcast groups
    case SUPERADMIN_FETCH_PODCAST_GROUP_LIST_SUCCESS:
    case SUPERADMIN_REMOVE_PODCAST_GROUP_SUCCESS:
    case SUPERADMIN_PODCAST_GROUP_ADD_SUCCESS:
      return { ...state, loading: false, podcastGroupList: action.payload }
    case SUPERADMIN_CLEAN_PODCAST_GROUP_LIST:
      return { ...state, loading: false, podcastGroupList: [] }

    default:
      return state
  }
}

export const SUPERADMIN_REQUEST = 'SUPERADMIN_REQUEST'
export const SUPERADMIN_FETCH_DISTRO_SETTINGS_SUCCESS = 'SUPERADMIN_FETCH_DISTRO_SETTINGS_SUCCESS'
export const SUPERADMIN_SAVE_DISTRO_SETTINGS_SUCCESS = 'SUPERADMIN_SAVE_DISTRO_SETTINGS_SUCCESS'
export const SUPERADMIN_REQUEST_ERROR = 'SUPERADMIN_REQUEST_ERROR'
export const SUPERADMIN_REQUEST_STATE_CLEAR = 'SUPERADMIN_REQUEST_STATE_CLEAR'

export const SUPERADMIN_FETCH_MAPPING_LIST_SUCCESS = 'SUPERADMIN_FETCH_MAPPING_LIST_SUCCESS'
export const SUPERADMIN_REMOVE_MAPPING_SUCCESS = 'SUPERADMIN_REMOVE_MAPPING_SUCCESS'
export const SUPERADMIN_MAPPING_ADD_SUCCESS = 'SUPERADMIN_MAPPING_ADD_SUCCESS'
export const SUPERADMIN_CLEAN_MAPPING_LIST = 'SUPERADMIN_CLEAN_MAPPING_LIST'

export const SUPERADMIN_FETCH_PROXY_HOSTINGS_LIST_SUCCESS = 'SUPERADMIN_FETCH_PROXY_HOSTINGS_LIST_SUCCESS'
export const SUPERADMIN_REMOVE_PROXY_HOSTINGS_SUCCESS = 'SUPERADMIN_REMOVE_PROXY_HOSTINGS_SUCCESS'
export const SUPERADMIN_PROXY_HOSTINGS_ADD_SUCCESS = 'SUPERADMIN_PROXY_HOSTINGS_ADD_SUCCESS'
export const SUPERADMIN_CLEAN_PROXY_HOSTINGS_LIST = 'SUPERADMIN_CLEAN_PROXY_HOSTINGS_LIST'

export const SUPERADMIN_FETCH_PODCAST_GROUP_LIST_SUCCESS = 'SUPERADMIN_FETCH_PODCAST_GROUP_LIST_SUCCESS'
export const SUPERADMIN_REMOVE_PODCAST_GROUP_SUCCESS = 'SUPERADMIN_REMOVE_PODCAST_GROUP_SUCCESS'
export const SUPERADMIN_PODCAST_GROUP_ADD_SUCCESS = 'SUPERADMIN_PODCAST_GROUP_ADD_SUCCESS'
export const SUPERADMIN_CLEAN_PODCAST_GROUP_LIST = 'SUPERADMIN_CLEAN_PODCAST_GROUP_LIST'

export const SUPERADMIN_AUDIO_FORMAT_ADD_SUCCESS = 'SUPERADMIN_AUDIO_FORMAT_ADD_SUCCESS'
export const SUPERADMIN_REMOVE_AUDIO_FORMAT_SUCCESS = 'SUPERADMIN_REMOVE_AUDIO_FORMAT_SUCCESS'
