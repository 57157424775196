import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { AddExternalSystemForm } from "./AddExternalSystemForm";
import { EditAdsSystemModal } from "./EditAdsSystemModal";
import { isEmpty } from "lodash";

const useStyles = createUseStyles({
  externalSystemsTable: {
    marginTop: 30,
  },
})

export const ExternalSystemsPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchExternalSystems, cleanExternalSystemsList, editAdsSystem } = useActions()

  const [externalSystemsData, setExternalSystemsData] = useState([])
  const [editingSystem, setEditingSystem] = useState({})
  const [editingField, setEditingField] = useState('')
  const [editingValue, setEditingValue] = useState(null)

  const { externalSystemsList, loading } = useSelector(state => state.admin)
  const user = useSelector(state => state.account.user)

  useEffect(() => {
    fetchExternalSystems()
    return () => cleanExternalSystemsList()
  }, [])

  useEffect(() => {
    if (externalSystemsList.length) {
      let externalSystemsArr = []
      externalSystemsList.map((adsSys) => {
        externalSystemsArr.push(
          {
            columns: [
              { key: 'system_id', type: 'text', value: adsSys.id },
              { key: 'system_name', type: 'text', value: adsSys.system_name },
              { key: 'preroll_link', type: 'text', value: adsSys.preroll_link, onCellClickHandler: () => handleCellClick(adsSys, 'preroll_link', adsSys.preroll_link) },
              { key: 'midroll_link', type: 'text', value: adsSys.midroll_link, onCellClickHandler: () => handleCellClick(adsSys, 'midroll_link', adsSys.midroll_link)},
              { key: 'postroll_link', type: 'text', value: adsSys.postroll_link, onCellClickHandler: () => handleCellClick(adsSys, 'postroll_link', adsSys.postroll_link)},
              { key: 's2s', type: 'text', value: adsSys.s2s_key },
              { key: 'priority', type: 'text', value: adsSys.base_prio },
              { key: 'status', type: 'text', value: adsSys.status },
            ]
          }
        )
        setExternalSystemsData(externalSystemsArr)
      })
    }
  }, [externalSystemsList])

  const HEADERS = [
    { name: 'ID', colSpan: 1 },
    { name: 'Название', colSpan: 1 },
    { name: 'Preroll тег', colSpan: 1 },
    { name: 'Midroll тег', colSpan: 1 },
    { name: 'Postroll тег', colSpan: 1 },
    { name: 's2s ключ', colSpan: 1 },
    { name: 'Приоритет', colSpan: 1 },
    { name: 'Статус', colSpan: 1 },
  ]

  const handleCellClick = (system, field, value) => {
    setEditingSystem(system)
    setEditingField(field)
    setEditingValue(value)
  }

  const handleEditModalSave = (podcast_id, data = {}) => {
    editAdsSystem(podcast_id, data)
  }

  const handleCloseModal = () => {
    setEditingSystem({})
    setEditingField('')
    setEditingValue(null)
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <AddExternalSystemForm />
            {!externalSystemsData.length
              ? <div>
                <H1>Внешние рекламные системы отсутствуют</H1>
              </div>
              : <div>
                <H1>Внешние рекламные системы:</H1>
                <div className={classes.externalSystemsTable}>
                  <Table headers={HEADERS} items={externalSystemsData} scrollX={true}/>
                </div>
              </div>
            }
            <Notification toast />
            {(!isEmpty(editingSystem) && editingField) && <EditAdsSystemModal
              onClose={() => handleCloseModal()}
              onSave={handleEditModalSave}
              adsSystem={editingSystem}
              field={editingField}
              value={editingValue}
            />}
          </Page>
        }
      </Template>
    </>
  )
}