import axios from 'axios'
import { DOMAIN } from '../configs/mainconf'

class ApiClient {
  post = async (url, jsonData, params={}) => {
    const userData = JSON.parse(JSON.parse(localStorage['persist:root']).account).user
    const access_token = (userData && userData.access_token) ? userData.access_token : ''

    return await axios.post(
      DOMAIN + url,
      jsonData,
      { headers: { 'Content-type': 'application/json' }, params: {...params, 'access_token': access_token}, withCredentials: true }
    )
  }

  uploadFiles = async (url, files = [], instance = {}, dispatchCallback = null) => {
    const userData = JSON.parse(JSON.parse(localStorage['persist:root']).account).user
    const access_token = (userData && userData.access_token) ? userData.access_token : ''
    const params = {
      'access_token': access_token,
    }

    if (instance?.campaignId) {
      params.campaignId = instance.campaignId

      if (instance?.docType) {
        params.docType = instance.docType
      }

      if (instance?.flightId) {
        params.flight_id = instance.flightId
      }

      if (instance?.rimType) {
        params.rimType = instance.rimType
      }
    }

    let requests = []
    let uploadedFiles = 0 // eslint-disable-line no-unused-vars
    let fileProgress = {}
    let totalProgress = 0

    files.forEach((file, idx, files) => {
      const data = new FormData()
      data.append('file', file)

      requests.push(axios.post(
        DOMAIN + url,
        data,
        {
          params,
          withCredentials: true,
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent
            if (loaded === total) uploadedFiles++

            fileProgress[idx] = Math.floor((loaded * 100) / total)
            const totalPercent = fileProgress ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0) : 0
            totalProgress = parseInt(Math.round(totalPercent / files.length))

            dispatchCallback(totalProgress || 1)
          }
        }
      ))
    })
    return axios.all(requests)
  }

  get = async (url, jsonParams, access_token = '') => {
    if(!access_token) {
      const userData = JSON.parse(JSON.parse(localStorage['persist:root']).account).user
      access_token = (userData && userData.access_token) ? userData.access_token : ''
    }
    
    return await axios.get(
      DOMAIN + url,
      { params: { ...jsonParams, 'access_token': access_token }, withCredentials: true }
    )
  }

  openUrlInBlankWindow = (url) => {
    const userData = JSON.parse(JSON.parse(localStorage['persist:root']).account).user
    const access_token = (userData && userData.access_token) ? userData.access_token : ''

    window.open(DOMAIN + url + `&access_token=${access_token}`, '_blank');
  }

}

export default new ApiClient()
