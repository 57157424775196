import API from '../../utils/Api'

import { REQUEST_NOTIFICATION_ERROR, REQUEST_NOTIFICATION_SUCCESS } from '../reducers/notificationReducer'

import {
  EPISODE_REQUEST,
  EPISODE_REQUEST_ERROR,
  EPISODE_SAVE_SUCCESS,
  EPISODE_LIST_SUCCESS,
  EPISODE_DOWNLOAD_WAVEFORM_SUCCESS,
  EPISODE_FETCH_SUCCESS,
  EPISODE_CURRENT_CLEAN,
} from '../reducers/episodeReducer'

// export const fetchEpisodeList = (podcastId) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: EPISODE_REQUEST })
//       const response = await API.post('/episode/list', {podcastId})

//       if (response.data.success) {
//         // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
//         dispatch({ type: EPISODE_LIST_SUCCESS, payload: response.data.episodeList })
//       } else {
//         dispatch({ type: EPISODE_REQUEST_ERROR, payload: response.data.errors })
//       }
//     } catch (error) {
//       dispatch({ type: EPISODE_REQUEST_ERROR, payload: { api: error } })
//     }
//   }
// }

export const fetchEpisode = (episodeMaskedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EPISODE_REQUEST })
      const response = await API.post('/episode/fetch', { episodeMaskedId })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: EPISODE_FETCH_SUCCESS, payload: response.data.episode })
      } else {
        dispatch({ type: EPISODE_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: EPISODE_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const downloadEpisodeWaveform = (episodeMaskedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EPISODE_REQUEST })
      const response = await API.post('/episode/downloadwaveform', { episodeMaskedId })

      if (response.data.success) {
        dispatch({ type: EPISODE_DOWNLOAD_WAVEFORM_SUCCESS, payload: response.data.episode })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: EPISODE_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: EPISODE_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const savePlacements = (episodeMaskedId, placements, removedMidrolls, preroll, postroll, callbackFetch) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EPISODE_REQUEST })
      const response = await API.post('/placement/update', { placements, removedMidrolls, episodeMaskedId, preroll, postroll })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: EPISODE_SAVE_SUCCESS, payload: response.data.episode })
        callbackFetch(episodeMaskedId)
      } else {
        dispatch({ type: EPISODE_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: EPISODE_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const removePlacement = (placeId, episodeMaskedId, callbackFetch) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EPISODE_REQUEST })
      const response = await API.post('/placement/remove', { placeId })

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: EPISODE_SAVE_SUCCESS, payload: response.data.episode })
        callbackFetch(episodeMaskedId)
      } else {
        dispatch({ type: EPISODE_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: EPISODE_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const cleanCurrentEpisode = () => {
  return { type: EPISODE_CURRENT_CLEAN }
}