import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, centered } from '../../theme'

const useStyles = createUseStyles({
  chip: {
    padding: [0, 8],
    width: 'fit-content',
    height: 20,
    borderRadius: 8,
    background: colors.primary_black,
    '& > span': {
      fontSize: 12,
      color: colors.background_white,
      verticalAlign: '-1px',
    }
  },
})

export const SeriesChip = ({
  className,
  count,
  type = 'identifier',
}) => {
  const classes = useStyles()
  const [chipsText, setChipsText] = useState('серий')

  useEffect(() => {
    if (count && type) {
      const stringCount = count.toString()
      const lastChar = stringCount.charAt(stringCount.length - 1)

      if (type === 'counter') {
        if (['2', '3', '4'].includes(lastChar)) {
          setChipsText('серии')
        } else if (['0', '5', '6', '7', '8', '9'].includes(lastChar)) {
          setChipsText('серий')
        } else {
          setChipsText('серия')
        }
      } else {
        setChipsText('серия')
      }
    }
  }, [])

  return (
    <div
      className={classnames(classes.chip, className)}
    >
      <span>{count} {chipsText}</span>
    </div>
  )
}