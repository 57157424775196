import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Action, Collapsed } from '../../components'
import { colors, justified } from '../../theme'

const useStyles = createUseStyles({
  block: {
    paddingTop: 11,
    paddingBottom: 5,
    borderBottom: [1, 'dashed', colors.primary_light_2],

    '& .extra-bottom-padding': {
      paddingBottom: 30,
    }
  },
  head: {
    ...justified,
  },
  headPrepended: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  title: {
    color: colors.primary_default,
    fontSize: 16,
    lineHeight: 24 / 16,
  },
  titlePrepended: {
    marginLeft: 12
  },
  mr17: {
    marginRight: 17
  },
  bordered: {
    borderBottom: [1, 'dashed', colors.primary_light_2],
  },
  opened: {
    borderColor: colors.secondary_default,
    '& svg': {
      stroke: colors.secondary_default,
    }
  },
})

export const Block = ({
  title,
  styleClasses = '',
  collapsedStyle = '',
  prependCollapseBtn = false,
  isCollapsed = false,
  highlightActionBtn = false,
  headerSection = null,
  children,
}) => {
  const classes = useStyles()
  const [collapse, setCollapse] = useState(isCollapsed)
  const onCollapse = () => setCollapse(!collapse)

  return (
    <div className={`${classes.block} ${classes[styleClasses]}`}>
      <div className={`block-header ${!prependCollapseBtn ? classes.head : classes.headPrepended}`}>
        {/* {title && */}
          <>
            {!prependCollapseBtn && <div className={classes.title}>{title}</div>}
            <Action
              className={(highlightActionBtn && !collapse) ?  classes.opened : ''}
              icon={'chevron_up'}
              onClick={onCollapse}
              rotated={collapse}
              title={collapse ? 'Свернуть' : 'Развернуть'}
            /> 
            {prependCollapseBtn && <div className={`block-title ${classes.title, classes.titlePrepended}`}>{title}</div>/* eslint-disable-line no-sequences*/}
            {headerSection && headerSection()}
          </>
        {/* } */}
      </div>
      <Collapsed className={collapsedStyle} isOpened={!collapse}>
        {children}
      </Collapsed>
    </div>
  )
}