const initialState = {
    title: null,
    message: '',
    status: '',
    show: false,
}

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_NOTIFICATION_SUCCESS:
            return { ...state, show: true, title: action.payload.title, message: action.payload.text, status: 'success' }
        case REQUEST_NOTIFICATION_ERROR:
            return { ...state, show: true, title: action.payload.title, message: action.payload.text, status: 'error' }
        case HIDE_REQUEST_NOTIFICATION:
            return { title: '', message: '', status: '', show: false }

        default:
            return state
    }
}

export const REQUEST_NOTIFICATION_SUCCESS = 'REQUEST_NOTIFICATION_SUCCESS'
export const REQUEST_NOTIFICATION_ERROR = 'REQUEST_NOTIFICATION_ERROR'
export const HIDE_REQUEST_NOTIFICATION = 'HIDE_REQUEST_NOTIFICATION'
