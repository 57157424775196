import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Template, Page } from "../../containers"
import { BreadCrumbs, Loader, Notification } from '../../components'
import { useParams } from 'react-router'
import { useActions } from "../../hooks/useActions"
import { InfoSection } from './InfoSection'
import { TagsSection } from './TagsSection'
import { CategoriesSection } from './CategoriesSection'
import { EpisodesSection } from './EpisodesSection'
import { formattedDate, generatePrefixUrl, generateProxyRss, isPodcastManager } from '../../utils'
import { isEmpty } from 'lodash'

export const PodcastMonetizationPage = () => {
  const { podcastMaskedId } = useParams()
  const { getPodcastParams, setPcAds, cleanCurrentPodcast, setIntegration} = useActions()

  const [episodeList, setEpisodeList] = useState([])

  const podcast = useSelector(state => state.podcast.currentPodcast)
  const user = useSelector(state => state.account.user)
  const params = useSelector(state => state.podcast.podcastParams)
  const { loading } = useSelector(state => state.podcast)

  useEffect(() => {
    getPodcastParams(podcastMaskedId)
    return () => cleanCurrentPodcast()
  }, [])

  useEffect(() => {
    if (podcast.episodes) {
      let epArr = []
      podcast.episodes.map((ep) => {
        epArr.push(
          {
            columns: [
              { key: 'name', type: 'text', value: ep.title },
              { key: 'monetization', type: 'monet_status', value: !isEmpty(ep.placements), link: `/podcasts/${podcast.masked_id}/episodes/${ep.masked_id}` },
            ]
          }
        )
        setEpisodeList(epArr)
      })
    }
  }, [podcast])

  const CRUMBS = [
    { name: 'Подкасты', path: '/podcasts' },
    { name: podcast.title, path: '/podcasts/' + podcastMaskedId },
    { name: 'Параметры рекламы подкаста', path: '' },
  ];

  const PODCAST = {
    masked_id: podcast.masked_id,
    title: podcast.title,
    summary: podcast.summary,
    description: podcast.description,
    cover: podcast.image_orig,
    rss: podcast.rss_url,
    created: formattedDate(podcast.created),
    episodes: podcast.episodes,

    // rssCategory: ['Случайная категория'],
    itunesCategories: podcast?.categories?.split(','),
  }

  // const EPISODES = [
  //   {
  //     columns: [
  //       { key: 'name', type: 'text', value: 'episode1' },
  //       { key: 'monetization', type: 'monet_status', value: true },
  //     ]
  //   },
  //   {
  //     columns: [
  //       { key: 'name', type: 'text', value: 'episode2' },
  //       { key: 'monetization', type: 'monet_status', value: false },
  //     ]
  //   },
  //   {
  //     columns: [
  //       { key: 'name', type: 'text', value: 'episode3' },
  //       { key: 'monetization', type: 'monet_status', value: false },
  //     ]
  //   },
  //   {
  //     columns: [
  //       { key: 'name', type: 'text', value: 'episode4' },
  //       { key: 'monetization', type: 'monet_status', value: true },
  //     ]
  //   }
  // ]

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <BreadCrumbs crumbs={CRUMBS} />
            <InfoSection podcast={PODCAST} />
            <TagsSection
              prefixUrl={generatePrefixUrl(user.prefix_url_domain, podcast.masked_id)}
              proxyRss={generateProxyRss(user.prefix_url_domain, podcast.masked_id)}
              disableControls={isPodcastManager(user.user_type)}
              podcast={podcast}
              pcParams={params}
              setPcAds={setPcAds}
              getPodcastParams={getPodcastParams}
              setIntegration={setIntegration}
            />
            <CategoriesSection podcastMaskedId={podcast.masked_id} pcParams={params} />
            <EpisodesSection episodesData={episodeList} />
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}