import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  h1: {
    height: 36,
    color: colors.text_black,
    fontSize: 28,
    lineHeight: 48/32,
    fontWeight: 400,
  },
})

export const H1 = ({ className, children }) => {
  const classes = useStyles()
  return <div className={classnames(classes.h1, className)}>{children}</div>
}
