import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../components'
import { colors, ellipsis } from '../../../theme'

const useStyles = createUseStyles({
  checkbox: {
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&.error': {
      '& input[type=checkbox]': {
        '& + label': {
          color: '#f00',
        },
      },
    },
    '& input[type=checkbox]': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '& $checked': {
            fill: colors.secondary_default,
            stroke: colors.secondary_default,
            opacity: 1,
          },
          '& $check': {
            opacity: 0,
          },
        },
      },
      '& + label': {
        width: '100%',
        minHeight: 18,
        paddingLeft: 26,
        fontSize: 14,
        lineHeight: 18/16,
        color: colors.primary_default,
        display: 'block',
        cursor: 'pointer',
        ...ellipsis,
        '& > svg': {
          width: 26,
          height: 26,
          position: 'absolute',
          left: 0,
          top: 0,
          stroke: colors.primary_light_2,
        },
        '& > span': {
            position: 'absolute',
            marginLeft: 8,
            marginTop: 6,
        }
      },
    },
    '& input[type=checkbox][disabled]': {
      '& + label': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
  },
  check: {
    opacity: 1,
  },
  checked: {
    opacity: 0,
  },
})

export const Checkbox = ({
  id = -1,
  className,
  label,
  error,
  checked,
  styles,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.checkbox, className, { error })}>
      <input id={id} type='checkbox' checked={checked} {...rest} />
      <label htmlFor={id} style={styles}>
        <Icon className={classes.check} iconName='check' styles={styles} />
        <Icon className={classes.checked} iconName='checked' styles={styles} />
        <span dangerouslySetInnerHTML={{ __html: label}}></span>
      </label>
    </div>
  )
}