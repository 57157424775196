import { createUseStyles } from 'react-jss'
import classnames from "classnames";
import { colors } from '../../theme';

const useStyles = createUseStyles({
  spinnerWr: {
    position: 'relative',
    width: 25,
    height: 25,
    margin: [0, 10, 0, 0],
  },
  "@global": {
    ".spinner": {
      "display": "block",
      "position": "absolute",
      "top": "50%",
      "left": "50%",
      "height": "20px",
      "width": "20px",
      "margin": "-10px 0 0 -10px",
      "border": "2px " + colors.background_white + " solid",
      "borderTop": "2px " + colors.dark_grey + " solid",
      "borderRight": "2px " + colors.dark_grey + " solid",
      "borderBottom": "2px " + colors.dark_grey + " solid",
      "borderRadius": "50%",
      "webkitAnimation": "spin3 1s infinite linear",
      "animation": "spin3 1s infinite linear"
    },
    "@keyframes spin3": {
      "from": {
        "webkitTransform": "rotate(0deg)",
        "transform": "rotate(0deg)"
      },
      "to": {
        "webkitTransform": "rotate(359deg)",
        "transform": "rotate(359deg)"
      }
    }
  },
})

export const Spinner = ({ show }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classnames(classes.spinnerWr)}>
        <div className='spinner'></div>
      </div>
    </div>
  )
}