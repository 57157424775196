import API from '../../utils/Api'

import { REQUEST_NOTIFICATION_ERROR, REQUEST_NOTIFICATION_SUCCESS } from '../reducers/notificationReducer'

import {
  ACCOUNT_REQUEST,
  ACCOUNT_REQUEST_ERROR,
  ACCOUNT_RESET_PASSWORD_SUCCESS,
  SIGN_UP_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  ACCOUNT_REQUEST_STATE_CLEAR,
} from '../reducers/accountReducer'

export const signUp = (formData, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACCOUNT_REQUEST })
      const response = await API.post('/site/signup', formData)

      if (!response.data.error) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SIGN_UP_SUCCESS, payload: response.data })
        history.push('/login');
      } else {
        dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: response.data.errors })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const loginAction = (formData, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACCOUNT_REQUEST })
      const response = await API.post('/site/login', formData)
      if (!response.data.error && response.data.access_token) {
        dispatch({ type: LOGIN_SUCCESS, payload: response.data })
        response.data.redirect ? history.push(response.data.redirect) : history.push('/');
      } else {
        dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const resetPasswordAction = (formData, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACCOUNT_REQUEST })
      const response = await API.post('/site/resetpassword', formData)
      if (!response.data.error) {
        response.data.redirect ? history.push(response.data.redirect) : history.push('/');
        dispatch({ type: ACCOUNT_RESET_PASSWORD_SUCCESS, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: response.data.errors })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      const response = await API.post('/site/logout')

      if (response.data.error) {
        dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: { ...response.data } })
      } else {
        dispatch({ type: LOGOUT })
      }
    } catch (error) {
      dispatch({ type: ACCOUNT_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const clearAccState = () => {
  return { type: ACCOUNT_REQUEST_STATE_CLEAR }
}
