const initialState = {
  loading: false,
  errors: [],

  episodeList: [],
  currentEpisode: {},
}

export const episodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EPISODE_REQUEST:
      return { ...state, loading: true, filter: { ...state.filter, ...action.payload } }
    case EPISODE_REQUEST_ERROR:
      return { ...state, loading: false, errors: action.payload }

    case EPISODE_SAVE_SUCCESS:
      return { ...state, loading: false }

    case EPISODE_DOWNLOAD_WAVEFORM_SUCCESS:
      return { ...state, loading: false, currentEpisode: action.payload }

    case EPISODE_LIST_SUCCESS:
      return { ...state, loading: false, episodeList: action.payload }
    case EPISODE_FETCH_SUCCESS:
      return { ...state, loading: false, currentEpisode: action.payload }

    case EPISODE_CURRENT_CLEAN:
      return { ...state, loading: false, currentEpisode: {} }

    default:
      return state
  }
}

export const EPISODE_REQUEST = 'EPISODE_REQUEST'
export const EPISODE_REQUEST_ERROR = 'EPISODE_REQUEST_ERROR'
export const EPISODE_SAVE_SUCCESS = 'EPISODE_SAVE_SUCCESS'

export const EPISODE_DOWNLOAD_WAVEFORM_SUCCESS = 'EPISODE_DOWNLOAD_WAVEFORM_SUCCESS'

export const EPISODE_LIST_SUCCESS = 'EPISODE_LIST_SUCCESS'
export const EPISODE_FETCH_SUCCESS = 'EPISODE_FETCH_SUCCESS'

export const EPISODE_CURRENT_CLEAN = 'EPISODE_CURRENT_CLEAN'