import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Collapse } from 'react-collapse'
import { Icon } from '../Icon'

const useStyles = createUseStyles({
  collapsed: {
    '& .ReactCollapse--collapse': {
      transition: 'height 300ms ease',
    },
  },
})

export const Collapsed = ({
  className,
  isOpened,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.collapsed, className)}>
      <Collapse isOpened={isOpened}>
        {children}
      </Collapse>
    </div>
  )
}