import React from 'react'
import { createUseStyles } from 'react-jss'
import Switch from 'react-switch'
import { colors } from '../../../theme'
import NumericInput from 'react-numeric-input';

const useStyles = createUseStyles({
  numericWrapper: {
    '& .react-numeric-input input': {
      width: 70,
      height: 35,
      color: colors.dark_grey,
      border: '1px solid '+colors.input_border+'!Important',
      borderRadius: '8px !important',
      background: colors.light_grey,
      padding: '10px !important',
      paddingLeft: '16px !important',
    },
    '& .react-numeric-input b': {
      cursor: 'pointer !important',
      border: 'none !Important',
      background: colors.light_grey+'!important',
      boxShadow: 'none !important',
      right: '5px !important',
      '& i': {
        // borderWidth: '0px 0.5px 0.5px !important'
      }
    }
  },
})

export const Numeric = ({
  min,
  max,
  value,
}) => {
  const classes = useStyles()

  return <div className={classes.numericWrapper}>
    <NumericInput min={min} max={max} value={value}/>
  </div>
}
