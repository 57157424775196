const initialState = {
  userList: [],
  podcastList: [],
  podcastGroups: [],
  podcastFilter: {
    filter: {
      userEmail: '',
      status: ''
    },
    pagination: {
      currentPage: 1,
      totalPages: 1,
      pageSize: 50,
    },
    sortBy: 'podcast',
    sortOrder: 'DESC',
  },
  userErrors: [],

  externalSystemsList: [],
  externalSystemsErrors: [],

  pretargetList: [],
  pretargetErrors: [],
  pretargetFilter: {
    filter: {
      userEmail: '',
      adSystemName: '',
      pretargetType: ''
    },
    sortBy: 'id',
    sortOrder: 'ASC',
    page: 1,
    pageLimit: 30,
  },

  eridList: [],
  advertiserList: [],
  eridErrors: [],
  eridFilter: {
    filter: {
      erid: '',
      title: '',
      advertiser: '',
      campaign: ''
    },
    sortBy: 'id',
    sortOrder: 'ASC',
    page: 1,
    pageLimit: 30,
  },

  loading: false,
  errors: [],
}

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {

    case ADMIN_REQUEST:
      return { ...state, loading: true }
    case ADMIN_REQUEST_ERROR:
      return { ...state, loading: false, errors: { [action.payload.field]: action.payload.message } }
    case ADMIN_REQUEST_STATE_CLEAR:
      return { ...state, loading: false, errors: [] }


    // User page
    case ADMIN_FETCH_USER_LIST_SUCCESS:
      return { ...state, loading: false, userList: action.payload }
    case ADMIN_USER_ADD_SUCCESS:
      return { ...state, loading: false, userList: action.payload }
    case ADMIN_USER_TOGGLE_SEND_ADFOX_PC_ID_SUCCESS:
      return { ...state, loading: false, userList: action.payload }
    case ADMIN_SWITCH_USER_PODCASTS_SUCCESS:
    case ADMIN_RESET_USER_PASSWORD_SUCCESS:
      return { ...state, loading: false }
    case ADMIN_CLEAN_USER_LIST:
      return { ...state, userList: [], }

    //External ad systems page
    case ADMIN_FETCH_EXTERNAL_SYSTEMS_LIST_SUCCESS:
    case ADMIN_EXTERNAL_SYSTEM_ADD_SUCCESS:
    case ADMIN_EXTERNAL_SYSTEMS_EDIT_SUCCESS:
      return { ...state, loading: false, externalSystemsList: action.payload }
    case ADMIN_CLEAN_EXTERNAL_SYSTEMS_LIST:
      return { ...state, externalSystemsList: [], }

    //Pretarget page
    case ADMIN_REQUEST_PRETARGET:
      return { ...state, loading: true, pretargetFilter: { ...state.pretargetFilter, ...action.payload } }
    case ADMIN_FETCH_PRETARGET_LIST_SUCCESS:
      return { ...state, loading: false, pretargetList: action.payload }
    case ADMIN_PRETARGET_ADD_SUCCESS:
      return { ...state, loading: false, pretargetList: action.payload }
    case ADMIN_REMOVE_PRETARGET_SUCCESS:
      return { ...state, loading: false, pretargetList: action.payload }
    case ADMIN_CLEAN_PRETARGET_LIST:
      return { ...state, pretargetList: [], }

      //Erid settings page
    case ADMIN_REQUEST_ERID:
      return { ...state, loading: true, eridFilter: { ...state.eridFilter, ...action.payload } }
    case ADMIN_FETCH_ERID_LIST_SUCCESS:
    case ADMIN_ADD_ERID_SUCCESS:
      return { ...state, loading: false, eridList: action.payload.eridList, advertiserList: action.payload.advertiserList }
    case ADMIN_REMOVE_ERID_SUCCESS:
      return { ...state, loading: false, eridList: action.payload }
    case ADMIN_CLEAN_ERID_LIST:
      return { ...state, eridList: [], }

    // Podcast settings page
    case ADMIN_REQUEST_PODCAST_LIST:
      return { ...state, loading: true, podcastFilter: { ...state.podcastFilter, ...action.payload } }
    case ADMIN_FETCH_PODCAST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        podcastList: action.payload.podcastList,
        podcastGroups: action.payload.podcastGroups,
        podcastFilter: {
          ...state.podcastFilter,
          pagination: { ...action.payload.pagination }
        }
      }
    case ADMIN_PODCAST_STATUS_SWITCH_SUCCESS:
      return { ...state, loading: false, podcastList: action.payload }
    case ADMIN_PODCAST_EDIT_SUCCESS:
      return { ...state, loading: false, podcastList: action.payload.podcastList, podcastGroups: action.payload.podcastGroups, }


    default:
      return state
  }
}

export const ADMIN_REQUEST = 'ADMIN_REQUEST'
export const ADMIN_REQUEST_ERROR = 'ADMIN_REQUEST_ERROR'

export const ADMIN_USER_ADD_SUCCESS = 'ADMIN_USER_ADD_SUCCESS'
export const ADMIN_USER_TOGGLE_SEND_ADFOX_PC_ID_SUCCESS = 'ADMIN_USER_TOGGLE_SEND_ADFOX_PC_ID_SUCCESS'
export const ADMIN_FETCH_USER_LIST_SUCCESS = 'ADMIN_FETCH_USER_LIST_SUCCESS'
export const ADMIN_CLEAN_USER_LIST = 'ADMIN_CLEAN_USER_LIST'
export const ADMIN_SWITCH_USER_PODCASTS_SUCCESS = 'ADMIN_SWITCH_USER_PODCASTS_SUCCESS'
export const ADMIN_RESET_USER_PASSWORD_SUCCESS = 'ADMIN_RESET_USER_PASSWORD_SUCCESS'

export const ADMIN_REQUEST_PODCAST_LIST = 'ADMIN_REQUEST_PODCAST_LIST'
export const ADMIN_FETCH_PODCAST_LIST_SUCCESS = 'ADMIN_FETCH_PODCAST_LIST_SUCCESS'
export const ADMIN_PODCAST_STATUS_SWITCH_SUCCESS = 'ADMIN_PODCAST_STATUS_SWITCH_SUCCESS'
export const ADMIN_PODCAST_EDIT_SUCCESS = 'ADMIN_PODCAST_EDIT_SUCCESS'

export const ADMIN_EXTERNAL_SYSTEM_ADD_SUCCESS = 'ADMIN_EXTERNAL_SYSTEM_ADD_SUCCESS'
export const ADMIN_EXTERNAL_SYSTEMS_EDIT_SUCCESS = 'ADMIN_EXTERNAL_SYSTEMS_EDIT_SUCCESS'
export const ADMIN_FETCH_EXTERNAL_SYSTEMS_LIST_SUCCESS = 'ADMIN_FETCH_EXTERNAL_SYSTEMS_LIST_SUCCESS'
export const ADMIN_CLEAN_EXTERNAL_SYSTEMS_LIST = 'ADMIN_CLEAN_EXTERNAL_SYSTEMS_LIST'

export const ADMIN_REQUEST_PRETARGET = 'ADMIN_REQUEST_PRETARGET'
export const ADMIN_FETCH_PRETARGET_LIST_SUCCESS = 'ADMIN_FETCH_PRETARGET_LIST_SUCCESS'
export const ADMIN_PRETARGET_ADD_SUCCESS = 'ADMIN_PRETARGET_ADD_SUCCESS'
export const ADMIN_REMOVE_PRETARGET_SUCCESS = 'ADMIN_REMOVE_PRETARGET_SUCCESS'
export const ADMIN_CLEAN_PRETARGET_LIST = 'ADMIN_CLEAN_PRETARGET_LIST'

export const ADMIN_REQUEST_ERID = 'ADMIN_REQUEST_ERID'
export const ADMIN_FETCH_ERID_LIST_SUCCESS = 'ADMIN_FETCH_ERID_LIST_SUCCESS'
export const ADMIN_ADD_ERID_SUCCESS = 'ADMIN_ADD_ERID_SUCCESS'
export const ADMIN_REMOVE_ERID_SUCCESS = 'ADMIN_REMOVE_ERID_SUCCESS'
export const ADMIN_CLEAN_ERID_LIST = 'ADMIN_CLEAN_ERID_LIST'

export const ADMIN_REQUEST_STATE_CLEAR = 'ADMIN_REQUEST_STATE_CLEAR'


