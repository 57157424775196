import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { AddProxyHostingForm } from "./AddProxyHostingForm";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { getSortIcon, getSortOrder } from "../../utils";

const useStyles = createUseStyles({
  proxyHostingTable: {
    marginTop: 15,
  }
})

export const ProxyIpPage = () => {
  const classes = useStyles()
  const { fetchProxyHostingList, cleanProxyHostingList, deleteProxyHosting } = useActions()

  const [proxyHostingData, setProxyHostingData] = useState([])

  const { proxyHostingList, loading } = useSelector(state => state.superadmin)
  const { sortBy, sortOrder } = useSelector(state => state.superadmin.proxyHostingFilter);

  useEffect(() => {
    fetchProxyHostingList()
    return () => cleanProxyHostingList()
  }, [])

  useEffect(() => {
    if (proxyHostingList && proxyHostingList.length > 0) {
      let proxyHostingArr = []
      proxyHostingList.map((proxyHosting) => {
        proxyHostingArr.push(
          {
            columns: [
              { key: 'id', type: 'text', value: proxyHosting.id },
              { key: 'hosting', type: 'text', value: proxyHosting.domain },
              { key: 'status', type: 'text', value: proxyHosting.status, style: proxyHosting.status === 'active' ? 'textGreen' : 'textRed' },
              { key: 'actions', type: 'actions', onClickHandler: () => handleDelete(proxyHosting.id) },
            ]
          }
        )
        setProxyHostingData(proxyHostingArr)
      })
    } else {
      setProxyHostingData([])
    }
  }, [proxyHostingList])

  const handleSearch = (filter) => {
    fetchProxyHostingList({ filter: filter })
  }

  const handleDelete = (id) => {
    if (window.confirm(`Хостинг с ID: ${id} будет удален.`)) {
      deleteProxyHosting(id)
    }
  }

  const HEADERS = [
    {
      name: 'ID',
      colSpan: 1,
      sortIcon: getSortIcon('id', sortBy, sortOrder),
      onSort: () => fetchProxyHostingList({ sortBy: 'id', sortOrder: getSortOrder('id', sortBy, sortOrder) }),
    },
    {
      name: 'Хостинг',
      colSpan: 1,
      sortIcon: getSortIcon('hosting', sortBy, sortOrder),
      onSort: () => fetchProxyHostingList({ sortBy: 'hosting', sortOrder: getSortOrder('hosting', sortBy, sortOrder) }),
    },
    {
      name: 'Статус',
      colSpan: 1
    },
    {
      name: 'Действие',
      colSpan: 1
    },
  ]

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <H1>Хостинги для проксирования:</H1>
            <AddProxyHostingForm />
            {!proxyHostingData.length
              ? <div><H1>Хостинги отсутствуют</H1></div>
              : <div className={classes.proxyHostingTable}>
                <Table headers={HEADERS} items={proxyHostingData} />
              </div>
            }
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}