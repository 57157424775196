import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, fonts } from '../../theme'
import { Template, Page } from "../../containers";
import { BreadCrumbs, SeriesChip, Icon, Block, Status, Button, Search, Loader, ActionBar, Notification } from '../../components'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useActions } from "../../hooks/useActions"
import { H4, H1 } from '../../components/Typography'
import { isEmpty } from 'lodash'
import { Checkbox } from '../../components/Form/Checkbox'
import { EpisodeCard } from './EpisodeCard'
import { formattedDate, specialCharsParser } from '../../utils'
import { useHistory } from 'react-router'

const useStyles = createUseStyles({
  podcastCard: {
    color: colors.text_black,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    minWidth: 328,
    borderRadius: 8,
    background: colors.light_grey,
    padding: 15,
  },
  mainWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: [1, 'solid', colors.icon_grey],
    paddingBottom: 15,
  },
  flexRow: {
    width: '100%',
    display: 'flex',
  },
  podcastTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '130%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chips: {
    marginTop: 15
  },
  cover: {
    width: 77,
    height: 77,
    marginRight: 16,
    '& img': {
      objectFit: 'cover',
      width: 77,
      height: 77,
      borderRadius: 8
    },
    '& > svg': {
      width: 77,
      height: 77,
      fill: colors.plug_grey,
      stroke: colors.plug_grey,
    }
  },
  mainInfo: {
    // width: 160,
    // maxHeight: 77,
    marginRight: 15,
    '& > p': {
      marginTop: 3,
      color: colors.dark_grey,
      fontSize: 14,
      lineHeight: '130%',
    }
  },
  description: {
    marginTop: '15px!important',
  },
  podcastActions: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'end',
  },
  actionBtn: {
    marginLeft: 20,
    '& svg': {
      width: 21,
      height: 21,
      fill: colors.icon_grey,
      '&:hover': {
        fill: colors.primary_yellow,
      }
    },
  },
  rssLabel: {
    margin: [15, 0],
  },
  rssLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    '& a': {
      textDecoration: 'underline',
      marginRight: 50,
    }
  },
  rssActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: [33, 0],
  },
  actionBlock: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: 8,
    },
  },
  checkboxWrapper: {
    paddingTop: 10
  }
})

export const PodcastPage = () => {
  const classes = useStyles()
  const { podcastMaskedId } = useParams()

  const { fetchPodcast, cleanPodcastState, importRss, podcastAutoimportToggle, podcastNewEpsMonetToggle } = useActions()
  const history = useHistory()

  const podcast = useSelector(state => state.podcast?.currentPodcast)
  const { loading } = useSelector(state => state.podcast)

  useEffect(() => {
    fetchPodcast(podcastMaskedId, history)
    return () => {
      cleanPodcastState()
    }
  }, [])

  const CRUMBS = [
    { name: 'Подкасты', path: '/podcasts' },
    { name: podcast.title, path: '' },
  ];

  const handleUpdateEpisodes = () => {
    importRss(podcast.rss_url, {podcastMaskedId: podcast.masked_id}, () => fetchPodcast(podcastMaskedId, history))
  }

  const handleToggleAutoimport = (masked_id) => {
    podcastAutoimportToggle(masked_id)
  }

  const handleToggleNewEpsMonet = (masked_id) => {
    podcastNewEpsMonetToggle(masked_id)
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <BreadCrumbs crumbs={CRUMBS} />
            {!isEmpty(podcast) && <div className={classes.podcastCard}>
              <div className={classes.flexRow}>
                <div className={classes.cover}>
                  {podcast.image_orig
                    ? <img src={podcast.image_orig} alt="Podcast Cover" />
                    : <Icon iconName={'img_plug'} />
                  }
                </div>
                <div className={classes.mainWrapper}>
                  <div className={classes.infoWrapper}>
                    <div className={classes.mainInfo}>
                      <H4 className={classes.podcastTitle}>{podcast.title}</H4>
                      <p>{formattedDate(podcast.created)}</p>
                      <p className={classes.description}>{podcast.description}</p>
                      <div className={classes.chips}>
                        <SeriesChip count={podcast.episodes?.length} type={'counter'} />
                      </div>
                    </div>
                    <div className={classes.podcastActions}>
                      <div className={classes.actionBtn}>
                        <Link to={`/stats/${podcast.masked_id}`}>
                          <Icon iconName='chart_btn' />
                        </Link>
                      </div>
                      {/* <div className={classes.actionBtn}>
                      <Link to='/settings'>
                        <Icon iconName='tag_btn' />
                      </Link>
                    </div> */}
                      <div className={classes.actionBtn}>
                        <Link to={`/podcasts/${podcastMaskedId}/monetization`}>
                          <Icon iconName='dollar' />
                        </Link>
                      </div>
                      {/* <div className={classes.actionBtn}>
                      <Link to='/settings'>
                        <Icon iconName='edit' />
                      </Link>
                    </div> */}
                    </div>
                  </div>
                  <Block title="" isCollapsed={true}>
                    <div className={classes.blockWrapper}>
                      <div className={classes.rssLabel}>
                        <p>Подкаст импортирован из этого RSS-канала</p>
                      </div>
                      <div className={classes.rssLink}>
                        <a href={podcast.rss_url} onClick={(e) => e.preventDefault()}>{podcast.rss_url}</a>
                        {/* <Status text={'RSS успешно синхронизирована'} icon={'status_ok'} /> */}
                      </div>
                      <div className={classes.rssActions}>
                        <div className={classes.checkboxWrapper}>
                          <Checkbox id={'auto_import'} label={"Импортировать эпизоды автоматически"} checked={podcast.auto_import} onChange={() => handleToggleAutoimport(podcast.masked_id)} />
                        </div>
                        <div className={classes.checkboxWrapper}>
                          <Checkbox id={'new_eps_monet'} label={"По умолчанию не включается реклама у новых эпизодов"} checked={podcast.new_eps_monet === '0'} onChange={() => handleToggleNewEpsMonet(podcast.masked_id)} />
                        </div>
                        <Button onClick={handleUpdateEpisodes}>Обновить сейчас</Button>
                      </div>
                    </div>
                  </Block>
                </div>
              </div>
            </div>
            }
            <ActionBar className={classes.actionBar}>
              <div className={classes.search}>
                {/* <Search
                placeholder={'Поиск эпизодов'}
              /> */}
              </div>
              <div className={classes.actionBlock}>
                {/* <Button className={classes.actionBarBtn} onClick={() => alert('Import')}>
                Импорт
              </Button> */}
                {/* <Button className={'primary'} onClick={() => alert('Загрузить еще')}>
                <span>Загрузить ещё эпизод</span>
              </Button> */}
              </div>
            </ActionBar>
            <div className={classes.episodesWrapper}>
              {!isEmpty(podcast.episodes)
                ? <>
                  {podcast.episodes.map((ep, idx) => <EpisodeCard
                    key={idx}
                    episode={ep}
                    description={ep.description}
                    podcastMaskedId={podcastMaskedId}
                  />
                  )}
                </>
                : <H1>Список эпизодов пуст</H1>
              }
            </div>
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}