import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon, Label, Error } from '../../index'
import { colors, fonts } from '../../../theme'

const useStyles = createUseStyles({
  selectWrapper: {
    position: 'relative',
  },
  select: {
    cursor: 'pointer',
    background: colors.white,
    width: '100%',
    height: 36,
    padding: [0, 40, 0, 14],
    color: colors.primary_default,
    fontFamily: fonts.notoSans,
    fontSize: 16,
    lineHeight: 24/16,
    border: [1, 'solid', colors.primary_default],
    borderRadius: 6,
    transition: 'border 300ms ease',
    position: 'relative',
    '&:disabled, &.empty': {
      background: colors.back_1,
      borderColor: colors.primary_light_2,
      color: colors.primary_default,
      opacity: '.7'
    },
    '&.error': {
      background: colors.error10,
      borderColor: colors.error,
    },
  },
  icon: {
    width: 20,
    height: 20,
    stroke: colors.primary_default,
    display: 'block',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 9,
    pointerEvents: 'none',
  },
  errorMessage: {
    position: 'relative',
    left: 0,
    marginTop: '2px',
  },
})

export const Select = ({
  id,
  label,
  options = [],
  className,
  value,
  error,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <>
      {label &&
        <Label htmlFor={id}>{label}</Label>
      }
      <div className={classes.selectWrapper}>
        <select
          id={id}
          value={value}
          className={classnames(classes.select, className, error && 'error')}
          {...rest}
        >
          {options.map(({ label, value, disabled, hidden }, index) =>
            <option value={value} disabled={disabled} hidden={hidden} key={index}>
              {label}
            </option>
          )}
        </select>
        <Icon className={classes.icon} iconName='arrow-down' />
      </div>
      {error &&
        <Error className={classnames(classes.errorMessage)}>{error}</Error>
      }
    </>
  )
}