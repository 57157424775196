import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { AddUserForm } from "./AddUserForm";
import { isAdmin } from "../../utils";

const useStyles = createUseStyles({
  usersTable: {
    marginTop: 30
  },
  tableBlock: {
    overflowX: 'auto'
  }
})

export const UsersPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchUserList, cleanUserList, toggleSendAdfoxPcId, switchUserPodcasts, resetUserPassword } = useActions()

  const [usersData, setUsersData] = useState([])

  const { userList, loading } = useSelector(state => state.admin)
  const user = useSelector(state => state.account.user)

  useEffect(() => {
    fetchUserList()
    return () => cleanUserList()
  }, [])

  useEffect(() => {
    if (userList.length) {
      let usersArr = []
      userList.map((user_data) => {
        let whiteList = []
        let blackList = []
        
        user_data.whiteList.map((item) => {
          whiteList.push(`(${item.adsystem_id}) ${item.adsystem_name}`)
        })

        user_data.blackList.map((item) => {
          blackList.push(`(${item.adsystem_id}) ${item.adsystem_name}`)
        })

        usersArr.push(
          {
            columns: [
              { key: 'user_id', type: 'text', value: user_data.id },
              { key: 'user_name', type: 'text', value: user_data.username },
              { key: 'user_type', type: 'text', value: user_data.type },
              { key: 'email', type: 'text', value: user_data.email },
              { key: 'partner', type: 'text', style: 'text_centered', value: user_data.partner_username ? user_data.partner_username : '-' },
              { key: 'user_actions', type: 'user_actions', onClickHandler: () => handleResetPassword(user_data.id, user_data.username) },
              { key: 'pc_actions', type: 'pc_actions', onPrimaryAction: () => handleSwitchUserPodcasts(user_data.id, user_data.username, 'published'), onSecondaryAction: () => handleSwitchUserPodcasts(user_data.id, user_data.username, 'pending') },
              isAdmin(user.user_type) && { key: 'sendAdfoxPcId', type: 'checkbox', value: !!user_data.send_adfox_pc_id, el_id: user_data.id, action: () => {toggleSendAdfoxPcId(user_data.id)} },
              { key: 'white_list', type: 'text', value: whiteList.join(', ') },
              { key: 'black_list', type: 'text', value: blackList.join(', ') },
            ]
          }
        )
        setUsersData(usersArr)
      })
    }
  }, [userList])

  const HEADERS = [
    { name: 'ID', colSpan: 1 },
    { name: 'Пользователь', colSpan: 1 },
    { name: 'Тип', colSpan: 1 },
    { name: 'Email', colSpan: 1 },
    { name: 'Партнер', colSpan: 1 },
    { name: 'Действия с пользователем', colSpan: 1 },
    { name: 'Действия с подкастами', colSpan: 1 },
    isAdmin(user.user_type) && { name: "Передавать id подкаста в puid45", colSpan: 1, className: 'user_checkbox' },
    { name: "White list", colSpan: 1, className: 'scrollable_text' },
    { name: "Black list", colSpan: 1, className: 'scrollable_text' },
  ]

  const handleSwitchUserPodcasts = (user_id, user_name, status) => {
    if (window.confirm(`Подкасты пользователя ${user_name} будут переведены в статус "${status}"`)) {
      switchUserPodcasts(user_id, status)
    }
  }

  const handleResetPassword = (user_id, user_name) => {
    if (window.confirm(`Новый пароль для пользователя ${user_name} будет отправлен на его email`)) {
      resetUserPassword(user_id)
    }
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <AddUserForm />
            {!userList.length
              ? <div>
                <H1>Пользователи отсутствуют</H1>
              </div>
              : <div className={classes.tableBlock}>
                <H1>Список Пользователей:</H1>
                <div className={classes.usersTable}>
                  <Table headers={HEADERS} items={usersData} />
                </div>
              </div>
            }
            <Notification toast/>
          </Page>
        }
      </Template>
    </>
  )
}