import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import Axios from 'axios'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import { rootReducer } from '../store/reducers'
import { LOGOUT } from './reducers/accountReducer'

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  stateReconciler: autoMergeLevel2,
  whitelist: ['account'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

const interceptor = (store) => {
  Axios.interceptors.response.use(
    response => {
      const { status, statusText } = response;

      // switch (status) {
      //   case 207:
      //     store.dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: { title: 'Уведомление:', message: JSON.parse(response.statusText), status: 'warning' } });
      //     break;
      //   default:
      //     break;
      // }
      return response
    },
    error => {
      const { status } = error.response;

      switch (status) {
        case 401:
          store.dispatch({ type: LOGOUT });
          break;

        // case 502:
        //   const messages = JSON.parse(error.response.data.messages)
        //   const errors = messages['errors']

        //   Object.keys(errors).forEach((title) => {
        //     store.dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: { title: `Ошибка: ${title}`, message: `MESSAGE: ${errors[title][0]}` } });
        //   })
        //   break;
        default:
          break;
      }

      return Promise.reject(error);
    }
  )
}

interceptor(store);

const persistor = persistStore(store);
const mainStore = { store, persistor }

export default mainStore;
