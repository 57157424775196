import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { AddPodcastGroupForm } from "./AddPodcastGroupForm";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { getSortIcon, getSortOrder } from "../../utils";

const useStyles = createUseStyles({
  podcastGroupTable: {
    marginTop: 15,
  }
})

export const PodcastGroupsPage = () => {
  const classes = useStyles()
  const { fetchPodcastGroupList, cleanPodcastGroupList, deletePodcastGroup } = useActions()

  const [podcastGroupData, setPodcastGroupData] = useState([])

  const { podcastGroupList, loading } = useSelector(state => state.superadmin)
  const { sortBy, sortOrder } = useSelector(state => state.superadmin.podcastGroupFilter);

  useEffect(() => {
    fetchPodcastGroupList()
    return () => cleanPodcastGroupList()
  }, [])

  useEffect(() => {
    if (podcastGroupList && podcastGroupList.length > 0) {
      let podcastGroupArr = []
      podcastGroupList.map((podcastGroup) => {
        podcastGroupArr.push(
          {
            columns: [
              { key: 'id', type: 'text', value: podcastGroup.id },
              { key: 'groupName', type: 'text', value: podcastGroup.groupName },
              { key: 'actions', type: 'actions', onClickHandler: () => handleDelete(podcastGroup) },
            ]
          }
        )
        setPodcastGroupData(podcastGroupArr)
      })
    } else {
      setPodcastGroupData([])
    }
  }, [podcastGroupList])

  const handleDelete = (podcastGroup) => {
    if (window.confirm(`Группа "${podcastGroup.groupName}' будет удалена.`)) {
      deletePodcastGroup(podcastGroup.id)
    }
  }

  const HEADERS = [
    {
      name: 'ID',
      colSpan: 1,
      sortIcon: getSortIcon('id', sortBy, sortOrder),
      onSort: () => fetchPodcastGroupList({ sortBy: 'id', sortOrder: getSortOrder('id', sortBy, sortOrder) }),
    },
    {
      name: 'Название группы',
      colSpan: 1,
      sortIcon: getSortIcon('groupName', sortBy, sortOrder),
      onSort: () => fetchPodcastGroupList({ sortBy: 'groupName', sortOrder: getSortOrder('groupName', sortBy, sortOrder) }),
    },
    {
      name: 'Действие',
      colSpan: 1
    },
  ]

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <H1>Группы подкастов:</H1>
            <AddPodcastGroupForm />
            {!podcastGroupData.length
              ? <div><H1>Хостинги отсутствуют</H1></div>
              : <div className={classes.podcastGroupTable}>
                <Table headers={HEADERS} items={podcastGroupData} />
              </div>
            }
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}