import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  h2: {
    color: colors.text_black,
    fontSize: 24,
    lineHeight: 36/24,
    fontWeight: 600,
  },
})

export const H2 = ({ className, children }) => {
  const classes = useStyles()
  return <div className={classnames(classes.h2, className)}>{children}</div>
}