import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import Draggable from 'react-draggable'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'
import { isEmpty } from 'lodash'
import { transformMsTimeMask } from '../../../utils'
import { Icon } from '../../../components'

const CONTAINER_WIDTH = 1390
const CONTAINER_HEIGHT = 145

const useStyles = createUseStyles({
  soundwaveBlock: {
    position: 'relative',
    height: CONTAINER_HEIGHT,
    width: CONTAINER_WIDTH,
    display: 'block',
    width: '100%',
    borderRadius: 8,
    marginBottom: 50,
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: CONTAINER_WIDTH,
      height: CONTAINER_HEIGHT,
      borderRadius: 8,
    }
  },
  container: {
    cursor: 'pointer',
    marginTop: 40,
    position: 'relative',
    width: CONTAINER_WIDTH,
    height: CONTAINER_HEIGHT,
    top: 0,
    left: 0,
  },
  midrollBar: {
    position: 'absolute',
    width: 1,
    color: 'white',
    backgroundColor: colors.dark_grey,
    height: '100%',
    zIndex: 1,
    opacity: .7,
    '&:hover': {
      opacity: 1,
      cursor: 'ew-resize',
      // boxShadow: [0, 0, 5, 1, colors.primary_yellow],
      backgroundColor: colors.primary_yellow,
      '& > svg': {
        stroke: colors.primary_yellow,
        fill: colors.primary_yellow,
      }
    },
    '& > svg': {
      cursor: 'pointer',
      transform: 'rotate(180deg)',
      position: 'absolute',
      bottom: -27,
      left: -8,
      width: 18,
      height: 27,
      stroke: colors.dark_grey,
      fill: colors.dark_grey,
    }
  },
  barClicked: {
    '&:hover': {
      cursor: 'ew-resize',
      backgroundColor: colors.primary_yellow,
    }
  },
  barHighlighted: {
      backgroundColor: colors.primary_yellow,
      '& > svg': {
        stroke: colors.primary_yellow,
        fill: colors.primary_yellow,
      }
  },
  midrollBarUndragable: {
    position: 'absolute',
    width: 1,
    color: 'white',
    backgroundColor: colors.primary_yellow,
    height: '100%',
    '& > svg': {
      cursor: 'auto',
      position: 'absolute',
      top: -27,
      left: -9,
      width: 18,
      height: 27,
      stroke: colors.primary_yellow,
      fill: colors.primary_yellow,
    }
  },
  timingsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5
  },
  timingsStart: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    color: 'black',
  },
  timingsEnd: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    color: 'black',
  }
})

export const InteractiveSoundwave = ({
  className,
  waves_img,
  duration,
  addMidroll,
  changeMidroll,
  currentMidrolls,
  newMidrolls,
  activeBar
}) => {
  const classes = useStyles()

  const [currentMidrollBars, setCurrentMidrollBars] = useState([])
  const [newMidrollBars, setNewMidrollBars] = useState([])
  const [mouseClicked, setMouseClicked] = useState(false)
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 })
  const [currentBar, setCurrentBar] = useState(null)

  const containerRef = useRef(null)

  useEffect(() => {
    setCurrentBar(null)
  }, [])

  useEffect(() => {
    const getMidrollsPos = (midrolls, currentMidrolls = false) => {
      let totalBars = []

      midrolls.forEach(el => {
        if (el.offset) {
          const offset = currentMidrolls ? parseInt(el.offset) : el.offset
          let offsetArr = []
          let timePositionMs = 0

          if (!currentMidrolls) {
            offsetArr = offset.split(':')
            timePositionMs = parseInt(offsetArr[0]) * 60 * 1000 + parseInt(offsetArr[1]) * 1000 + parseInt(offsetArr[2])
          } else {
            timePositionMs = offset
          }

          if (duration) {
            const relPos = timePositionMs / (duration * 1000)
            const axleXPosition = relPos * (CONTAINER_WIDTH - 1) // axleXPosition starts from 0 so CONTAINER_WIDTH -1
            totalBars.push({ axleXPosition, maskedTimePositionMs: transformMsTimeMask(timePositionMs), key: !currentMidrolls ? el.key : null })
          }
        }
      })

      if (currentMidrolls) {
        setCurrentMidrollBars(totalBars)
      } else {
        setNewMidrollBars(totalBars)
      }
    }

    if (!isEmpty(currentMidrolls)) {
      getMidrollsPos(currentMidrolls, true)
    }
    if (!isEmpty(newMidrolls)) {
      getMidrollsPos(newMidrolls)
    } else {
      setNewMidrollBars([])
    }
  }, [currentMidrolls, newMidrolls, duration])

  const getRelAxleXpos = (x) => {
    const container = containerRef.current
    const rect = container.getBoundingClientRect()
    const scaleX = container.clientWidth / rect.width

    const axleXPosition = (x - rect.left) * scaleX
    const relPos = axleXPosition / (rect.width - 1)       // axleXPosition starts from 0 so rect.width -1
    return relPos
  }

  const getTimePositionMs = (clientX) => {
    const relPos = getRelAxleXpos(clientX)
    const durationMs = duration * 1000
    return Math.round(durationMs * relPos)
  }

  const onContainerClick = (e) => {
    if (mouseClicked) {
      setMouseClicked(false)
      return
    }

    const timePositionMs = getTimePositionMs(e.clientX)
    addMidroll(null, transformMsTimeMask(timePositionMs))
  };

  const handleDrag = (e, pos) => {
    const { x, y } = deltaPosition;

    setDeltaPosition({
      x: x + pos.deltaX,
      y: y + pos.deltaY,
    })
  }

  const handleMouseDown = (e) => {
    const barsPosArr = newMidrollBars.map((el) => {
      return {axleXPosition: el.axleXPosition, maskedTimePositionMs: el.maskedTimePositionMs}
    })

    const relPos = getRelAxleXpos(e.clientX)
    const currentAxleXposition = relPos * (CONTAINER_WIDTH - 1)
    const closestBar = barsPosArr.reduce(function(prev, curr) {
      return (Math.abs(curr.axleXPosition - currentAxleXposition) < Math.abs(prev.axleXPosition - currentAxleXposition) ? curr : prev);
    })
    setCurrentBar(newMidrollBars.filter((el, idx) => el.maskedTimePositionMs === closestBar.maskedTimePositionMs)[0])
  }

  const onStart = (type, e) => {
    setMouseClicked(true)
    if (type === 'current') return false
  }

  const onStop = (e) => {
    e.preventDefault(); e.stopPropagation();

    const timePositionMs = getTimePositionMs(e.clientX)
    const durationMs = duration * 1000

    const validRangeValue = (durationMs < timePositionMs)
      ? transformMsTimeMask(durationMs)
      : (timePositionMs < 0)
        ? transformMsTimeMask(0)
        : transformMsTimeMask(timePositionMs)

    changeMidroll(currentBar, validRangeValue)
  }

  const renderMidrollBars = (el, type, idx) => {
    return <Draggable
      key={idx}
      axis={"x"}
      bounds="parent"
      onMouseDown={e => handleMouseDown(e)}
      onDrag={handleDrag}
      position={{ x: Math.round(el.axleXPosition), y: 0 }}
      onStart={e => onStart(type, e)}
      onStop={onStop}
    >
      <div className={type === 'new' ? classnames(classes.midrollBar, mouseClicked ? classes.barClicked : '', activeBar.includes(el.key) ? classes.barHighlighted : '') : classes.midrollBarUndragable}>
        {<Icon iconName='bar-handle'/>}
      </div>
    </Draggable>
  }

  return (
    <div className={classes.soundwaveBlock}>
      <div
        className={classes.container}
        style={{ backgroundImage: `url(${waves_img})` }}
        width={CONTAINER_WIDTH}
        ref={containerRef}
        onClick={onContainerClick}
      >
        {!isEmpty(currentMidrollBars) && currentMidrollBars.map((el, idx) => renderMidrollBars(el, 'current', 'current-midroll-' + idx))}
        {!isEmpty(newMidrollBars) && newMidrollBars.map((el, idx) => renderMidrollBars(el, 'new', 'new-midroll-' + idx))}
      </div>
      <div className={classes.timingsBar}>
        <div className={classes.timingsStart}>{transformMsTimeMask(0)}</div>
        <div className={classes.timingsEnd}>{transformMsTimeMask(duration * 1000)}</div>
      </div>
    </div>
  )
}