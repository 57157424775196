import { createUseStyles } from 'react-jss'
import classnames from "classnames";

const useStyles = createUseStyles({
  '@keyframes loader': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' }
  },
  loaderWr: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.4)',
    top: 0,
    left: 0,
    zIndex: 9,

    '& .loader': {
      display: 'block',
      position: 'absolute',
      width: 80,
      height: 80,
      top: 'calc(50% - 40px)',
      left: 'calc(50% - 40px)',

      '& div': {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: 64,
        height: 64,
        margin: 8,
        border: '8px solid #A0A0A0',
        borderRadius: '50%',
        animation: '$loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
        borderColor: '#A0A0A0 transparent transparent transparent',

        '&:nth-child(1)': {
          animationDelay: '-0.45s'
        },
        '&:nth-child(2)': {
          animationDelay: '-0.3s'
        },
        '&:nth-child(3)': {
          animationDelay: '-0.15s'
        }
      },
    },
  },
  translucent: {
    opacity: '.8'
  }
})

export const Loader = ({ show, translucent = false }) => {
  const classes = useStyles()

  return (
    <div>
      {show &&
        <div className={classnames(classes.loaderWr, translucent ? classes.translucent : '')}>
          <div className='loader'>
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      }
    </div>
  )
}