const initialState = {
    user: null,
    loading: false,
    errors: [],
}

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {

        // Loading status and error handling
        case ACCOUNT_REQUEST:
            return { ...state, loading: true }
        case ACCOUNT_REQUEST_ERROR:
            return { ...state, loading: false, errors: action.payload }
        case ACCOUNT_REQUEST_STATE_CLEAR:
            return { ...state, loading: false, errors: [] }
        // REGISTRATION
        case SIGN_UP_SUCCESS:
            return { ...state, loading: false, errors: [] }

        // PASSWORD RESET
        case ACCOUNT_RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, errors: [] }


        // AUTHORIZATION
        case LOGIN_SUCCESS:
            return { user: action.payload, loading: false, errors: [] }

        // LOGGING OUT
        case LOGOUT:
            return { user: null, loading: false, errors: [] }

        default:
            return state
    }
}

export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST'
export const ACCOUNT_REQUEST_ERROR = 'ACCOUNT_REQUEST_ERROR'
export const ACCOUNT_REQUEST_STATE_CLEAR = 'ACCOUNT_REQUEST_STATE_CLEAR'
export const ACCOUNT_RESET_PASSWORD_SUCCESS = 'ACCOUNT_RESET_PASSWORD_SUCCESS'

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
