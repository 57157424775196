import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { H1 } from "../../../components/Typography";
import { Loader, Button, Notification, Table } from "../../../components";
import { Checkbox } from '../../../components/Form/Checkbox'
import { Page, Template } from "../../../containers";
import { AddAudioFormatForm } from "./AddAudioFormatForm";
import { useActions } from "../../../hooks";
import { colors } from "../../../theme";

const useStyles = createUseStyles({
  audioFormatsTable: {
    marginTop: 15,
  }
})

export const AudioFormatsSection = ({ defaultFormats }) => {
  const classes = useStyles()

  const { deleteAudioFormat } = useActions()

  const [audioFormatsData, setAudioFormatsData] = useState([])

  const HEADERS = [
    {
      name: 'ID',
      colSpan: 1,
    },
    {
      name: 'Битрейт',
      colSpan: 1,
    },
    {
      name: 'Сэмплинг',
      colSpan: 1
    },
    {
      name: 'Каналы',
      colSpan: 1
    },
    {
      name: 'Действие',
      colSpan: 1
    },
  ]

  useEffect(() => {
    if (defaultFormats && defaultFormats.length > 0) {
      let audioFormatsArr = []
      defaultFormats.map((format) => {
        audioFormatsArr.push(
          {
            columns: [
              { key: 'id', type: 'text', value: format.id },
              { key: 'bitrate', type: 'text', value: format.bitrate },
              { key: 'sampling_rate', type: 'text', value: format.sampling_rate },
              { key: 'channel_mode', type: 'text', value: format.channel_mode },
              { key: 'actions', type: 'actions', onClickHandler: () => handleDelete(format) },
            ]
          }
        )
        setAudioFormatsData(audioFormatsArr)
      })
    } else {
      setAudioFormatsData([])
    }
  }, [defaultFormats])

  const handleDelete = (format) => {
    if (window.confirm(`Формат "${format.bitrate} ${format.sampling_rate} ${format.channel_mode}" будет удален.`)) {
      deleteAudioFormat(format.id)
    }
  }

  return (
    <>
      <H1>Аудиоформаты по умолчанию:</H1>
      <AddAudioFormatForm />
      {!audioFormatsData.length
        ? <div><H1>Аудиоформаты отсутствуют.</H1></div>
        : <div className={classes.audioFormatsTable}>
          <Table headers={HEADERS} items={audioFormatsData} />
        </div>
      }
    </>
  )
}