import API from '../../utils/Api'

import {
    ADV_STATS_REQUEST,
    ADV_STATS_FETCH_SUCCESS,
    ADV_STATS_REQUEST_ERROR,
    ADV_STATS_FETCH_TOTALS_SUCCESS,
    ADV_STATS_RESET,
    ADV_STATS_TOTALS_RESET,
} from '../reducers/advertiserReducer'

export const fetchAdvStats = (filter) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user

    try {
      dispatch({ type: ADV_STATS_REQUEST })
      const response = await API.get('/advertiser/stats', {filter: filter}, access_token)

      if (response.data.success) {
        dispatch({ type: ADV_STATS_FETCH_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: ADV_STATS_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADV_STATS_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

// export const fetchAdvStatsTotals = (filter) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: ADV_STATS_REQUEST })
//       const response = await API.get('/advertiser/fetchtotals', {filter: filter})

//       if (response.data.success) {
//         dispatch({ type: ADV_STATS_FETCH_TOTALS_SUCCESS, payload: response.data })
//       } else {
//         dispatch({ type: ADV_STATS_REQUEST_ERROR, payload: response.data.errors })
//       }
//     } catch (error) {
//       dispatch({ type: ADV_STATS_REQUEST_ERROR, payload: { api: error } })
//     }
//   }
// }

export const resetAdvStats = () => {
  return (dispatch) => {
    dispatch({ type: ADV_STATS_RESET })
  }
}

export const resetAdvStatTotals = () => {
  return (dispatch) => {
    dispatch({ type: ADV_STATS_TOTALS_RESET })
  }
}
