import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { Input, Button, Fieldset, Hint, Loader } from '../../components'
import { colors } from '../../theme'
import { useActions } from '../../hooks/useActions'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'

import classnames from 'classnames'

const useStyles = createUseStyles({
  form: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 16,
    width: '100%',
    padding: [60, 176],
    zIndex: 2,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& p:last-child': {
      marginTop: 5,
      lineHeight: '130%'
    }
  },
  title: {
    color: colors.primary_light_2,
    fontSize: 32,
    lineHeight: 48 / 32,
  },
  fieldset: {
    '& + &': {
      marginTop: 10,
    },
  },
  fields: {
    marginTop: 20,
  },
  footer: {
    minWidth: 380,
    marginTop: 30,
    '& p': {
      marginTop: 10,
      fontSize: 12,
      lineHeight: '150%'
    }
  },
  hint: {
    '& svg': {
      width: 18,
      height: 18,
    },
    marginTop: 32
  }
})

const INPUTS = [
  {
    name: 'email',
    size: 'medium',
    type: 'text',
    label: 'Email',
    placeholder: '',
    react_hook_form: true
  }
]

const schema = z.object({
  email: string().email({ message: 'Введите корректный адрес электронной почты' }),
})

export const ResetPasswordForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const { resetPasswordAction } = useActions()

  const { loading } = useSelector(state => state.account)

  const methods = useForm({ resolver: zodResolver(schema) })

  const onResetPassword = (formValues) => {
    if (window.confirm(`Пароль от вашего аккаунта Zayads будет обновлен. Новый пароль будет выслан на указанный вами email: ${formValues.email}`)) {
      resetPasswordAction(formValues, history)
    }
  }

  return (
    <>
      {loading && <Loader show={loading} translucent={true} />}
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onResetPassword)} className={classes.form}>
          <div className={classes.header}>
            <p className={classes.title}>Сброс пароля</p>
            <p>
              Введите email от вашей учетной записи
            </p>
          </div>
          <div className={classes.fields}>
            {INPUTS.map((item, index) =>
              <Fieldset className={classes.fieldset} key={index}>
                <Input {...item} />
              </Fieldset>
            )}
          </div>
          <div className={classnames(classes.footer)}>
            <Button type='submit' size='medium' className={'primary'}>Сбросить пароль</Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}