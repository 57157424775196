import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useHistory, Link } from 'react-router-dom'
import { Input, Checkbox, Button, Fieldset, Loader } from '../../components'
import { colors } from '../../theme'
import { useActions } from '../../hooks/useActions'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'

import classnames from 'classnames'

const useStyles = createUseStyles({
  form: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 16,
    width: '100%',
    padding: [60, 176],
    zIndex: 2,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& p:last-child': {
      marginTop: 5,
      lineHeight: '130%'
    }
  },
  title: {
    color: colors.primary_light_2,
    fontSize: 32,
    lineHeight: 48 / 32,
  },
  fieldset: {
    '& + &': {
      marginTop: 10,
    },
  },
  fields: {
    marginTop: 20,
  },
  footer: {
    minWidth: 380,
    marginTop: 30,
    '& p': {
      marginTop: 10,
      fontSize: 12,
      lineHeight: '150%',
      '& a': {
        textDecoration: 'underline'
      }
    }
  },
})

const INPUTS = [
  {
    name: 'username',
    size: 'medium',
    type: 'text',
    label: 'Имя пользователя',
    placeholder: '',
    react_hook_form: true,
  },
  {
    name: 'email',
    size: 'medium',
    type: 'email',
    label: 'Email',
    placeholder: '',
    react_hook_form: true,
  }
]

const schema = z.object({
  username: string().min(1, { message: 'Имя пользователя обязательно к заполнению' }),
  email: string().email({ message: 'Неверный адрес электронной почты' }),
})

export const SignUpForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const { signUp } = useActions()

  const { loading } = useSelector(state => state.account)

  const methods = useForm({ resolver: zodResolver(schema) })

  const onSignUp = (formValues) => {
    signUp(formValues, history)
  }

  return (
    <>
      {loading && <Loader show={loading} translucent={true} />}
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSignUp)} className={classes.form}>
          <div className={classes.header}>
            <p className={classes.title}>Присоединиться</p>
            <p>
              Для того чтобы начать публиковать подкасты,
              необходимо создать аккаунт
            </p>
          </div>
          <div className={classes.fields}>
            {INPUTS.map((item, index) =>
              <Fieldset className={classes.fieldset} key={index}>
                {item.type === 'checkbox'
                  ? <Checkbox {...item} />
                  : <Input {...item} />
                }
              </Fieldset>
            )}
          </div>
          <div className={classnames(classes.footer)}>
            <Button type='submit' size='medium' className='primary'>Присоединиться</Button>
            <p>Регистрируясь, вы соглашаетесь с этими условиями: Пользовательское соглашение и <a target="_blank" rel="noopener noreferrer" href='https://zayads.ru/PrivacyPolicy'>Политика обработки персональных данных</a></p>
          </div>
        </form>
      </FormProvider>
    </>
  )
}