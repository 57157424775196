import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, centered } from '../../theme'
import { Icon } from '../Icon'
import { H4, H1 } from '../Typography'
import { Button } from '../Button'

const useStyles = createUseStyles({
  tile: {
    display: 'flex',
    width: 730,
    height: 242,
    marginRight: 24,
    marginBottom: 24,
    borderRadius: 8,
    background: colors.light_grey,
    '& > svg': {
      width: 231,
      height: 219,
    },
  },
  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: 460,
    marginRight: 15,
    paddingBottom: 33,
    paddingTop: 33,
    '& > p': {
      marginTop: 3,
      color: colors.dark_grey,
      fontSize: 12,
    }
  },
  podcastTitle: {
    fontSize: 16,
    lineHeight: '130%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnBlock: {
    marginTop: 15
  },
  title: {
    marginBottom: 8
  },
  btn: {
    width: 150,
    height: 46,
    marginRight: 22,
    padding: [12, 24],
    background: colors.primary_yellow,
    borderRadius: 50
  }
})

export const ActionTile = ({
  className,
  title,
  icon,
  description,
  btn,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classnames(classes.tile, className)}
    >
      <Icon iconName={icon} />
      <div className={classes.mainInfo}>
        <H1 className={classes.title}>{title}</H1>
        <H4 >{description}</H4>
        <div className={classes.btnBlock}>
          <Button className={classes.btn} onClick={btn.clickHandler}>{btn.text}</Button>
        </div>
      </div>
    </div>
  )
}