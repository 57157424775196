import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Modal, Fieldset, Input, Button, Selector } from "../../components"
import { inputNumericFilter } from '../../utils'

const useStyles = createUseStyles({
  title: {
    margin: [12, 0, 12, 0],
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 700,
  },
  relative: {
    position: 'relative'
  },
  button: {
    width: 100,
    margin: [0, 6],
  },
  wr: {
    minWidth: 400,
    padding: [0, 6],
    position: 'relative'
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'start',
    marginTop: 30,
  },
  fieldset: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'start',
    width: '100%',
    margin: [0, 0, 30, 0],
    '& > label': {
      marginRight: 30
    }
  },
  saveBtn: {
    alignSelf: 'end'
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

export const EditPodcastModal = ({
  onClose,
  onSave,
  podcast,
  field,
  value,
  pc_groups
}) => {
  const classes = useStyles()

  const [changedFieldValue, setChangedFieldValue] = useState(value)

  const INPUTS = {
    puid45: {
      size: 'large',
      type: 'text',
      name: 'new_puid',
      label: 'puid45',
      placeholder: '',
      value: changedFieldValue,
      onChange: (e) => {
        setChangedFieldValue(e.target.value);
      },
      error: false,
      errorClassName: classes.error,
    },
    pc_group: {
      size: 'medium',
      type: 'select',
      name: 'pc_group',
      label: 'Группа',
      options: pc_groups,
      placeholder: '',
      value: changedFieldValue,
      onChange: (group) => {
        setChangedFieldValue(group);
      },
      errorClassName: classes.error,
    },
  }

  const handleSave = () => {
    onSave(podcast.id, { [field]: changedFieldValue })
    onClose()
  }

  return (
    <Modal withClose withoutScroll onClose={onClose}>
      <div className={classes.title}>{`Редактирование подкаста`}</div>
      <div className={classes.relative}>{`"${podcast.title}"`}</div>
      <div className={classes.wr}>
        {<div className={classes.editForm}>
          <Fieldset className={classes.fieldset}>
            {INPUTS[field].type === 'select'
              ? <Selector height={48} {...INPUTS[field]} />
              : <Input {...INPUTS[field]} handleKeyPress={(e) => inputNumericFilter(e)} />
            }
          </Fieldset>
          <div className={classes.saveBtn}>
            <Button className={'primary'} onClick={handleSave}>Сохранить</Button>
          </div>
        </div>}
      </div>
    </Modal>
  )
}
