import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label, Icon, Error } from '../../../components'
import { boxShadow, colors, fonts } from '../../../theme'
import { Spinner } from '../../Spinner'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const useStyles = createUseStyles({
  inputWrapper: {
    position: 'relative',
    "& input[type='password']": {
      fontFamily: 'Verdana',
      letterSpacing: ' 0.125em'
    },
  },
  input: {
    width: '100%',
    height: 46,
    padding: [6, 14],
    fontSize: 14,
    lineHeight: 24 / 16,
    color: colors.text_black,
    fontFamily: fonts.notoSans,
    border: [1, 'solid', colors.input_border],
    borderRadius: 8,
    caretColor: colors.text_black,
    background: colors.light_grey,
    transition: 'border 300ms ease',
    '&:-moz-placeholder': {
      fontSize: 14,
      color: colors.text_grey,
    },
    '&::-moz-placeholder': {
      fontSize: 14,
      color: colors.text_grey,
    },
    '&:-ms-input-placeholder': {
      fontSize: 14,
      color: colors.text_grey,
    },
    '&::-webkit-input-placeholder': {
      fontSize: 14,
      color: colors.text_grey,
    },
    '&::-webkit-text-fill-color': {
      color: colors.text_grey,
    },
    '&:enabled': {
      '&:hover': {
        ...boxShadow,
      },
    },
    '&:disabled': {
      background: colors.back_1,
      borderColor: colors.primary_light_2,
    },
    '&.medium': {
      height: 48,
    },
    '&.large': {
      height: 48,
      width: 400
    },
    '&.measure': {
      paddingRight: 48,
    },
    '&.error': {
      background: colors.error10,
      borderColor: colors.error,
    },
  },
  eye: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 12,
    cursor: 'pointer',
    '&:hover': {
      '& > svg': {
        stroke: colors.shade,
      },
    },
    '& > svg': {
      width: 20,
      height: 20,
      stroke: colors.primary_default,
      transition: 'stroke 300ms ease',
    },
  },
  search: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 12,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  measure: {
    fontSize: 16,
    lineHeight: 24 / 16,
    color: colors.primary_light_1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 13,
    pointerEvents: 'none',
  },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  iconWrapper: {
    width: 18,
    height: 18,
    marginRight: 16,
    '& > svg': {
      width: 22,
      height: 22,
    }
  }
})

export const PROCESS_STATUSES = {
  pending: 'pending',
  success: 'success',
  failed: 'failed',
}

export const Input = ({
  name,
  size,
  id,
  label,
  type,
  react_hook_form = false,
  className,
  value,
  measure,
  error,
  errorClassName,
  mask,
  maskChar,
  alwaysShowMask,
  formatChars,
  handleKeyPress,
  processStatus = false,
  labelStyle = '',
  ...rest
}) => {
  const classes = useStyles()

  const formContext = useFormContext()
  
  const [showPassword, setShowPassword] = useState(false)
  const onShowPassword = () => setShowPassword(!showPassword)
  const isPassword = type === 'password'
  const errorMessage = (formContext && react_hook_form ) ? formContext.formState.errors[name]?.message : error?.text
  const inputProps = {
    id,
    className: classnames(classes.input, className, { [size]: size, measure, error }),
    value: value || '',
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'off',
    type,
    ...rest,
  }

  return (
    <>
      {label &&
        <Label className={labelStyle} htmlFor={id}>{label}</Label>
      }
      <div className={classes.inputWrapper}>
        {mask
          ? <InputMask
            {...inputProps}
            mask={mask}
            maskChar={maskChar}
            alwaysShowMask={alwaysShowMask}
            formatChars={formatChars}
          />
          : processStatus
            ? <div className={classes.spinnerWrapper}>
              {processStatus === PROCESS_STATUSES.pending
                ? <Spinner />
                : <div className={classes.iconWrapper}>
                  <Icon iconName={'process_' + processStatus} />
                </div>}
              <input {...inputProps} type={isPassword && showPassword ? 'text' : type} onKeyPress={handleKeyPress} />
            </div>
            : (formContext && react_hook_form)
              ? <input
                {...formContext.register(name)}
                className={classnames(classes.input, className, { [size]: size, measure, error })}
                type={isPassword && showPassword ? 'text' : type} />
              : <input {...inputProps} type={isPassword && showPassword ? 'text' : type} onKeyPress={handleKeyPress} />
        }
        {isPassword &&
          <div
            className={classes.eye}
            title={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
            onClick={onShowPassword}
          >
            {showPassword
              ? <Icon iconName='eye_on' />
              : <Icon iconName='eye_off' />
            }
          </div>
        }
        {(type === 'search' && !value) &&
          <div className={classes.search}>
            <Icon iconName='search' />
          </div>
        }
        {measure &&
          <div className={classes.measure}>
            {measure}
          </div>
        }
      </div>
      {errorMessage &&
        <Error className={errorClassName}>{errorMessage}</Error>
      }
    </>
  )
}