import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, fonts } from '../../theme'
import { Icon } from '../Icon'

const useStyles = createUseStyles({
  hintBlock: {
    display: 'flex',
    width: 'fit-content',
    cursor: 'pointer',
    '& > svg': {
      width: 24,
      height: 24
    }
  },
  hintText: {
    fontSize: 12,
    padding: 6
  }
})

export const Hint = ({
  className,
  text,
  icon = '',
  link = '',
  children,
}) => {
  const classes = useStyles()

  const hintContent = <>
    <Icon iconName={icon ? icon : 'hint'} />
    <span className={classes.hintText}>{text}</span>
  </>

  return (
    <>
      {link
        ? <div className={classnames(classes.hintBlock, className)}>
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {hintContent}
          </a>
        </div>
        : <div className={classnames(classes.hintBlock, className)} >
          {hintContent}
        </div>
      }
    </>
  )
}