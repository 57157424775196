import React, { useEffect, useState } from "react";
import { createUseStyles } from 'react-jss';
import { H1 } from "../../components/Typography";
import { Template, Page } from "../../containers";
import { BreadCrumbs, Button, Input, Loader, Notification } from "../../components";
import { colors } from "../../theme";
import { useActions } from "../../hooks/useActions";
import { inputNumericFilter, isAdmin, isPodcastManager } from "../../utils"
import { useSelector } from "react-redux";
import { PROCESS_STATUSES } from "../../components/Form/Input";
import { useHistory } from "react-router";

const CRUMBS = [
  { name: 'Подкасты', path: '/podcasts' },
  { name: 'Копирование данных подкаста' },
];

const useStyles = createUseStyles({
  pageTitle: {
    marginTop: 17
  },
  instruction: {
    fontSize: 16,
    color: colors.text_black,
    fontWeight: 600,
    marginTop: 32
  },
  importBlock: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
  },
  countBlock: {
    marginTop: 25,
  },
  btnWrapper: {
    margin: [4, 27, 4, 0]
  },
  rssField: {
    minWidth: 330,
    maxWidth: 448,
    width: '100%',
    marginTop: 20,
    marginRight: 24,
  },
  rssRowBlock: {
    display: 'flex',
    alignItems: 'end',
  },
  importFailed: {
    margin: [10, 0, 0, 35],
    color: colors.coral
  },
  importSuccess: {
    margin: [10, 0, 0, 35],
    color: colors.primary_green
  },
  backToImportBtn: {
    marginBottom: 4
  }
})

export const PodcastsImportPage = () => {
  const classes = useStyles()
  const history = useHistory()

  const { importRss, changeImportStatus, checkImportStatus, cleanImportMeta, fetchPodcastList } = useActions()

  const [rssUrl, setRssUrl] = useState('')
  const [episodesCount, setEpisodesCount] = useState('')

  const { loading, importStatus, importMsg, pendingPodcastMaskedId, pendingRss, podcastList } = useSelector(state => state.podcast)
  const { user } = useSelector(state => state.account)

  useEffect(() => {
    fetchPodcastList()
  }, [])

  useEffect(() => {
    return () => {
      if (importStatus && PROCESS_STATUSES.pending && (importStatus !== PROCESS_STATUSES.pending)) {
        cleanImportMeta()
      }
    }
  }, [importStatus, PROCESS_STATUSES])

  useEffect(() => {
    let checkImportInterval = setInterval(() => {
      if (pendingPodcastMaskedId && importStatus == PROCESS_STATUSES.pending) {
        checkImportStatus(pendingPodcastMaskedId)
      }
    }, 5000);

    return () => {
      clearInterval(checkImportInterval);
    };
  })

  const handleImportRss = () => {
    if (rssUrl) {
      const feedExists = podcastList.filter((pc) => {return pc.rss_url === rssUrl.trim()})
      let params = {}

      if (feedExists.length) {
        if (window.confirm('Данный подкаст был загружен ранее. Обновить эпизоды?')) {
          // changeImportStatus(PROCESS_STATUSES.pending)
          importRss(rssUrl.trim())
        }
      } else {
        params = { episodesCount: episodesCount }
        // changeImportStatus(PROCESS_STATUSES.pending)
        importRss(rssUrl.trim(), params)
      }
    }

  }

  const handleBackToImport = () => {
    changeImportStatus(false)
  }

  const handleGoToSettings = () => {
    if (pendingPodcastMaskedId) {
      history.push(`/podcasts/${pendingPodcastMaskedId}/monetization`)
    }
  }

  return (
    <Template>
      {loading
        ? <Loader show={loading} />
        : <Page>
          <BreadCrumbs crumbs={CRUMBS} />
          <H1 className={classes.pageTitle}>Копирование данных подкаста</H1>
          {(!importStatus && !pendingRss)
            ? <div>
              <p className={classes.instruction}>
                Вставь ссылку на RSS поток подкаста, чтобы скопировать необходимые данные.
              </p>
              <div className={classes.countBlock}>
                <div className={classes.rssField}>
                  {(isAdmin(user.user_type) || isPodcastManager(user.user_type)) && <Input
                    type='text'
                    label='Кол-во последних эпизодов к загрузке'
                    value={episodesCount}
                    placeholder={'Не включать рекламу у новых эпизодов'}
                    onChange={(e) => { setEpisodesCount(e.target.value) }}
                    handleKeyPress={(e) => inputNumericFilter(e)}
                  />}
                </div>
              </div>
              <div className={classes.importBlock}>
                <div className={classes.rssField}>
                  <Input
                    type='text'
                    label=''
                    value={rssUrl}
                    placeholder={'Ссылка на RSS'}
                    onChange={(e) => { setRssUrl(e.target.value) }}
                  />
                </div>
                <div className={classes.btnWrapper}>
                  <Button className={'primary'} onClick={handleImportRss} disabled={!!importStatus} >
                    Скопировать данные подкаста
                  </Button>
                </div>
              </div>
            </div>
            : <>
              <div className={classes.processBlock}>
                <p className={classes.instruction}>
                  Ожидайте процесс загрузки
                </p>
              </div>
              <div className={classes.rssRowBlock}>
                <div className={classes.rssField}>
                  <Input
                    type='text'
                    label=''
                    value={pendingRss}
                    error={importStatus === PROCESS_STATUSES.failed ? importMsg : null}
                    errorClassName={(importStatus === PROCESS_STATUSES.failed) ? classes.importFailed : classes.importSuccess}
                    processStatus={importStatus}
                    disabled={true}
                  />
                </div>
                <div className={classes.backToImportBtn}>
                  {importStatus === PROCESS_STATUSES.failed && <Button className={'primary'} onClick={handleBackToImport} >
                    Импортировать другой
                  </Button>
                  }
                  {importStatus === PROCESS_STATUSES.success && <Button className={'primary'} onClick={handleGoToSettings} >
                    К настройкам рекламы подкаста
                  </Button>
                  }
                </div>
              </div>
            </>
          }
          <Notification toast />
        </Page>
      }
    </Template>
  )
}