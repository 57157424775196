import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { colors } from '../../../theme'
import { MultiselectBlock, Button } from '../../../components'
import { Col6 } from '../../../components/Grid/Col6'
import { useActions } from "../../../hooks";

const useStyles = createUseStyles({
  categoties: {
    marginBottom: 48,
  },
  selectWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
})

export const CategoriesSection = ({
  className,
  podcastMaskedId,
  pcParams,
}) => {
  const classes = useStyles()
  // const [whiteCategories, setWhiteCategories] = useState([]);
  const [blackCategories, setBlackCategories] = useState(pcParams.blackList);
  const [submitted, setSubmitted] = useState(0)

  const { savePodcastCategories } = useActions()

  // const OPTIONS_WHITE = [
  //   {
  //     label: "[IAB1] Arts & Entertainment", value: "1", children: [
  //       { label: "[IAB1.1] Automotive1", value: "11" },
  //       { label: "[IAB1.2] Automotive2", value: "12" },
  //       { label: "[IAB1.3] Automotive3", value: "13" },
  //     ]
  //   },
  //   { label: "[IAB2] Automotive", value: "2" },
  //   { label: "[IAB3] Business", value: "3" },
  // ];

  // const OPTIONS_BLACK = [
  //   { label: "[IAB6-7] Pregnancy", value: "4" },
  //   { label: "[IAB7-39] Sexuality", value: "5" },
  //   { label: "[IAB11-4] Politics", value: "6" },
  //   { label: "[IAB26] - Illegal Content", value: "7" },
  // ];

  const onSave = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted) {
      const formData = {
        blackListCats: blackCategories,
        whiteListCats: []
      }

      savePodcastCategories(podcastMaskedId, formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  return <div className={classes.categoties}>
    <H1>Управление категориями рекламодателей</H1>
    <div className={classes.selectWrapper}>
      <MultiselectBlock
        // nodesWhite={OPTIONS_WHITE}
        selectedBl={blackCategories}
        // selectedWl={pcParams.whiteList}
        nodesBlack={pcParams.catsBlackList}
        // handleWhiteChange={setWhiteCategories}
        handleBlackChange={setBlackCategories}
      />
      <Button className='primary' style={{ width: '200px', margin: '15px 0 0 0' }} onClick={onSave}>
        Сохранить
      </Button>
    </div>
  </div>
}
