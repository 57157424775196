export const colors = {
    coral: '#F95050',
    primary_yellow: '#FFCE2F',
    primary_black: '#2B2A32',
    dark_grey: '#5C5B65',
    light_grey: '#EFF4F8',
    x_light_grey: '#f7fafc',
    text_black: '#211F30',
    text_grey: '#787A7B',
    form_grey: '#E7EBEE',
    primary_green: '#33D963',
    notice_green: '#08CC27',
    background_white: '#FFFFFF',
    icon_grey: '#B7C3CB',
    input_border: '#DBDDE8',
    custom_grey: '#939393',
    plug_grey: '#E1E8ED',
    disabled: '#AFAEB5'
}

export const fonts = {
    raleway: 'Raleway',
}

export const centered = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const justified = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

export const ellipsis = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}

export const boxShadow = {
    boxShadow: [0, 5, 5, 'rgba(54, 75, 99, 0.2)'],
}