import { combineReducers } from 'redux'
import { LOGOUT } from './accountReducer'

import { notificationReducer } from './notificationReducer'
import { podcastReducer } from './podcastReducer'
import { episodeReducer } from './episodeReducer'
import { statsReducer } from './statsReducer'
import { accountReducer } from './accountReducer'
import { adminReducer } from './adminReducer'
import { superadminReducer } from './superadminReducer'
import { advertiserReducer } from './advertiserReducer'

const appReducer = combineReducers({
  podcast: podcastReducer,
  notification: notificationReducer,
  episode: episodeReducer,
  stats: statsReducer,
  account: accountReducer,
  admin: adminReducer,
  superadmin: superadminReducer,
  advertiser: advertiserReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
    localStorage.clear();
  }

  return appReducer(state, action)
}