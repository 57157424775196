import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../theme'

const useStyles = createUseStyles({

})

const BEFORE_OFFSET_IN_SECONDS = 0.5
const AFTER_OFFSET_IN_SECONDS = 0.5

export const AudioPlayer = ({
  link,
  timestamp,
  midrollKey,
  highlightMidroll,
  dimMidroll
}) => {
  const classes = useStyles()
  const playerRef = useRef(null);

  const [canPlay, setCanPlay] = useState(false)

  useEffect(() => {
    playerRef.current.addEventListener('play', () => {
      highlightMidroll(midrollKey)
    }, false);
    playerRef.current.addEventListener('pause', () => {
      dimMidroll(midrollKey)
    }, false);
  }, [])

  useEffect(() => {
    const segmentEnd = timestamp + AFTER_OFFSET_IN_SECONDS

    if (playerRef.current) {
      playerRef.current.addEventListener('timeupdate', () => {
        if (segmentEnd && playerRef.current.currentTime >= segmentEnd) {
          playerRef.current.pause()
          playerRef.current.currentTime = timestamp - BEFORE_OFFSET_IN_SECONDS
        }
      }, false);
    }
  }, [timestamp, canPlay])

  const handlePlay = () => {
    playerRef.current.play()
  }

  const handleCanPlay = () => {
    setCanPlay(true)
  }

  return (
    <audio
      id="player"
      ref={playerRef}
      src={link + `#t=${timestamp - BEFORE_OFFSET_IN_SECONDS},${timestamp + AFTER_OFFSET_IN_SECONDS}`}
      onPlay={handlePlay}
      // onCanPlayThrough={handleCanPlay}
      controls={true}
      preload="true"
    />
  )
}