import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification } from "../../components";
import { AddMappingForm } from "./AddMappingForm";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { getSortIcon, getSortOrder } from "../../utils";

const useStyles = createUseStyles({
  mappingTable: {
    marginTop: 15,
  }
})

export const EpisodesMappingPage = () => {
  const classes = useStyles()
  const { fetchMappingList, cleanMappingList, deleteMapping } = useActions()

  const [mappingData, setMappingData] = useState([])

  const { mappingList, loading } = useSelector(state => state.superadmin)
  const { sortBy, sortOrder } = useSelector(state => state.superadmin.mappingFilter);

  useEffect(() => {
    fetchMappingList()
    return () => cleanMappingList()
  }, [])

  useEffect(() => {
    if (mappingList && mappingList.length > 0) {
      let mappingArr = []
      mappingList.map((mapping) => {
        mappingArr.push(
          {
            columns: [
              { key: 'id', type: 'text', value: mapping.id },
              { key: 'old_ep_url', type: 'text', value: mapping.ep_old_url },
              { key: 'new_ep_url', type: 'text', value: mapping.ep_new_url },
              { key: 'status', type: 'text', value: mapping.status, style: mapping.status === 'active' ? 'textGreen' : 'textRed' },
              { key: 'actions', type: 'actions', onClickHandler: () => handleDelete(mapping.id) },
            ]
          }
        )
        setMappingData(mappingArr)
      })
    } else {
      setMappingData([])
    }
  }, [mappingList])

  const handleSearch = (filter) => {
    fetchMappingList({ filter: filter })
  }

  const handleDelete = (id) => {
    if (window.confirm(`Маппинг с ID: ${id} будет удален.`)) {
      deleteMapping(id)
    }
  }

  const HEADERS = [
    {
      name: 'ID',
      colSpan: 1,
      sortIcon: getSortIcon('id', sortBy, sortOrder),
      onSort: () => fetchMappingList({ sortBy: 'id', sortOrder: getSortOrder('id', sortBy, sortOrder) }),
    },
    {
      name: 'Старый URL',
      colSpan: 1,
      sortIcon: getSortIcon('old_ep_url', sortBy, sortOrder),
      onSort: () => fetchMappingList({ sortBy: 'old_ep_url', sortOrder: getSortOrder('old_ep_url', sortBy, sortOrder) }),
    },
    {
      name: 'Новый URL',
      colSpan: 1,
      sortIcon: getSortIcon('new_ep_url', sortBy, sortOrder),
      onSort: () => fetchMappingList({ sortBy: 'new_ep_url', sortOrder: getSortOrder('new_ep_url', sortBy, sortOrder) }),
    },
    {
      name: 'Статус',
      colSpan: 1
    },
    {
      name: 'Действие',
      colSpan: 1
    },
  ]

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <H1>Маппинг эпизодов:</H1>
            <AddMappingForm />
            {!mappingData.length
              ? <div><H1>Маппинги отсутствуют</H1></div>
              : <div className={classes.mappingTable}>
                <Table headers={HEADERS} items={mappingData} scrollX={true}/>
              </div>
            }
            <Notification toast />
          </Page>
        }
      </Template>
    </>
  )
}