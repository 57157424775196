import API from '../../utils/Api'
import { REQUEST_NOTIFICATION_ERROR, REQUEST_NOTIFICATION_SUCCESS } from '../reducers/notificationReducer'

import {
  SUPERADMIN_REQUEST,
  SUPERADMIN_FETCH_DISTRO_SETTINGS_SUCCESS,
  SUPERADMIN_SAVE_DISTRO_SETTINGS_SUCCESS,
  SUPERADMIN_REQUEST_ERROR,
  SUPERADMIN_REQUEST_STATE_CLEAR,

  SUPERADMIN_CLEAN_MAPPING_LIST,
  SUPERADMIN_FETCH_MAPPING_LIST_SUCCESS,
  SUPERADMIN_MAPPING_ADD_SUCCESS,
  SUPERADMIN_REMOVE_MAPPING_SUCCESS,

  SUPERADMIN_FETCH_PROXY_HOSTINGS_LIST_SUCCESS,
  SUPERADMIN_PROXY_HOSTINGS_ADD_SUCCESS,
  SUPERADMIN_REMOVE_PROXY_HOSTINGS_SUCCESS,
  SUPERADMIN_CLEAN_PROXY_HOSTINGS_LIST,

  SUPERADMIN_FETCH_PODCAST_GROUP_LIST_SUCCESS,
  SUPERADMIN_REMOVE_PODCAST_GROUP_SUCCESS,
  SUPERADMIN_PODCAST_GROUP_ADD_SUCCESS,
  SUPERADMIN_CLEAN_PODCAST_GROUP_LIST,

  SUPERADMIN_AUDIO_FORMAT_ADD_SUCCESS,
  SUPERADMIN_REMOVE_AUDIO_FORMAT_SUCCESS
} from '../reducers/superadminReducer'

export const fetchDistroSettings = () => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user

    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.get('/superadmin/fetchdistrosettings', {}, access_token)

      if (response.data.success) {
        dispatch({ type: SUPERADMIN_FETCH_DISTRO_SETTINGS_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const saveDistroSettings = (changedSettings, changedTelecoms) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/savedistrosettings', { changedSettings, changedTelecoms })
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_SAVE_DISTRO_SETTINGS_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const fetchMappingList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { mappingFilter } = getState().superadmin;
    const params = {mappingFilter: {...mappingFilter, ...fetchParams }}

    try {
      dispatch({ type: SUPERADMIN_REQUEST, payload: params })
      const response = await API.get('/superadmin/mappinglist', params.mappingFilter, access_token)

      if (response.data.success) {
        dispatch({ type: SUPERADMIN_FETCH_MAPPING_LIST_SUCCESS, payload: response.data.mappingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const addMapping = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/mappingadd', formData)
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_MAPPING_ADD_SUCCESS, payload: response.data.mappingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deleteMapping = (mappingId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/mappingdelete', {mappingId})
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_REMOVE_MAPPING_SUCCESS, payload: response.data.mappingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const cleanMappingList = () => {
  return async (dispatch) => {
    dispatch({ type: SUPERADMIN_CLEAN_MAPPING_LIST })
  }
}

export const fetchProxyHostingList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { proxyHostingFilter } = getState().superadmin;
    const params = {proxyHostingFilter: {...proxyHostingFilter, ...fetchParams }}

    try {
      dispatch({ type: SUPERADMIN_REQUEST, payload: params })
      const response = await API.get('/superadmin/proxyhostinglist', params.proxyHostingFilter, access_token)

      if (response.data.success) {
        dispatch({ type: SUPERADMIN_FETCH_PROXY_HOSTINGS_LIST_SUCCESS, payload: response.data.proxyHostingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const addProxyHosting = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/proxyhostingadd', formData)
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_PROXY_HOSTINGS_ADD_SUCCESS, payload: response.data.proxyHostingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deleteProxyHosting = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/proxyhostingdelete', {id})
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_REMOVE_PROXY_HOSTINGS_SUCCESS, payload: response.data.proxyHostingList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const cleanProxyHostingList = () => {
  return async (dispatch) => {
    dispatch({ type: SUPERADMIN_CLEAN_PROXY_HOSTINGS_LIST })
  }
}

export const fetchPodcastGroupList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { podcastGroupFilter } = getState().superadmin;
    const params = {podcastGroupFilter: {...podcastGroupFilter, ...fetchParams }}

    try {
      dispatch({ type: SUPERADMIN_REQUEST, payload: params })
      const response = await API.get('/superadmin/podcastgrouplist', params.podcastGroupFilter, access_token)

      if (response.data.success) {
        dispatch({ type: SUPERADMIN_FETCH_PODCAST_GROUP_LIST_SUCCESS, payload: response.data.podcastGroupList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const addPodcastGroup = (formData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/podcastgroupadd', formData)
      
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_PODCAST_GROUP_ADD_SUCCESS, payload: response.data.podcastGroupList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deletePodcastGroup = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/podcastgroupdelete', {id})
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_REMOVE_PODCAST_GROUP_SUCCESS, payload: response.data.podcastGroupList })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const addAudioFormat = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/audioformatadd', formData)
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_AUDIO_FORMAT_ADD_SUCCESS, payload: response.data.audioFormats })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deleteAudioFormat = (formatId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUPERADMIN_REQUEST })
      const response = await API.post('/superadmin/audioformatdelete', {formatId})
      if (response.data.success) {
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
        dispatch({ type: SUPERADMIN_REMOVE_AUDIO_FORMAT_SUCCESS, payload: response.data.audioFormats })
      } else {
        dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
      }
    } catch (error) {
      dispatch({ type: SUPERADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const cleanPodcastGroupList = () => {
  return async (dispatch) => {
    dispatch({ type: SUPERADMIN_CLEAN_PODCAST_GROUP_LIST })
  }
}

export const clearSuperadminState = () => {
  return { type: SUPERADMIN_REQUEST_STATE_CLEAR }
}