import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button, Selector } from "../../components";
import { useActions } from "../../hooks";

const useStyles = createUseStyles({
  addProxyHostingsForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginTop: 30,
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddProxyHostingForm = () => {
  const classes = useStyles()

  const [hosting, setHosting] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { proxyHostingErrors, loading } = useSelector(state => state.superadmin)

  const { addProxyHosting, clearSuperadminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!hosting.trim() || proxyHostingErrors.hosting) errors_arr.hosting = proxyHostingErrors.hosting ? proxyHostingErrors.hosting : ERRORS.required

    setErrors(errors_arr);
  }, [hosting])

  const isValid = hosting !== ''

  const onAddProxyHosting = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        hosting: hosting,
      }

      clearSuperadminState()
      addProxyHosting(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'large',
      type: 'text',
      name: 'proxy_hosting',
      label: 'URL хостинга',
      placeholder: 'Введите URL хостинга',
      value: hosting,
      onChange: (e) => {
        setHosting(e.target.value);
      },
      error: submitted > 0 && errors.hosting,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addProxyHostingsForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              {item.type === 'select'
                ? <Selector height={48} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button className={'primary'} onClick={onAddProxyHosting}>Сохранить</Button>
          </div>
        </div>}
    </>
  )
}