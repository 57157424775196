import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { PrivateRoute } from './components'
import store from './store'
import { isSuperAdmin } from './utils'

import {
  PodcastListPage,
  PodcastPage,
  PodcastsImportPage,
  PodcastsCreatePage,
  
  StatsPage,
  EpisodeMonetizationPage,
  PodcastMonetizationPage,
  ProPage,

  ProfilePage,
  SettingsPage,

  LoginPage,
  SignUpPage,
  ResetPasswordPage,

  UsersPage,
  ExternalSystemsPage,
  EridSettingsPage,
  PretargetPage,
  PodcastSettingsPage,

  AdvStatsPage,

  DistributionSettingsPage,
  EpisodesMappingPage,
  ProxyIpPage,
  PodcastGroupsPage,
  ItunesSettingsPage,

  PartnerUsersPage
} from './pages'

import './assets/css/styles.scss'

const App = () => {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <BrowserRouter>
            <Switch>
              <PrivateRoute exact path='/' component={PodcastListPage} />

              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts' component={PodcastListPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts/create' component={PodcastsCreatePage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts/import' component={PodcastsImportPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts/:podcastMaskedId' component={PodcastPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts/:podcastMaskedId/monetization' component={PodcastMonetizationPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/podcasts/:podcastMaskedId/episodes/:episodeMaskedId' component={EpisodeMonetizationPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/stats' component={StatsPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/stats/:podcastMaskedId' component={StatsPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/stats/:podcastMaskedId/:episodeMaskedId' component={StatsPage} />
              <PrivateRoute exact roleAccess={['admin', 'pc_manager', 'podcaster', 'partner']} path='/monetization' component={EpisodeMonetizationPage} />

              <PrivateRoute exact roleAccess={['advertiser']} path='/statistics' component={AdvStatsPage} />

              <PrivateRoute exact roleAccess={['admin', 'pc_manager']} path='/users' component={UsersPage} />
              <PrivateRoute exact roleAccess={['admin']} path='/externalsystems' component={ExternalSystemsPage} />
              <PrivateRoute exact roleAccess={['admin']} path='/erid_settings' component={EridSettingsPage} />
              <PrivateRoute exact roleAccess={['admin']} path='/pretarget' component={PretargetPage} />
              <PrivateRoute exact roleAccess={['admin']} path='/podcast_settings' component={PodcastSettingsPage} />

              <PrivateRoute exact roleAccess={['super_admin']} path='/distribution_settings' component={DistributionSettingsPage} />
              <PrivateRoute exact roleAccess={['super_admin']} path='/episodes_mapping' component={EpisodesMappingPage} />
              <PrivateRoute exact roleAccess={['super_admin']} path='/proxy_hosting' component={ProxyIpPage} />
              <PrivateRoute exact roleAccess={['super_admin']} path='/podcast_groups' component={PodcastGroupsPage} />
              <PrivateRoute exact roleAccess={['super_admin']} path='/itunes_settings' component={ItunesSettingsPage} />

              <PrivateRoute exact roleAccess={['partner']} path='/my_users' component={PartnerUsersPage} />

              <PrivateRoute exact path='/profile' component={ProfilePage} />
              <PrivateRoute exact path='/settings' component={SettingsPage} />

              <Route exact path='/login' component={LoginPage} />
              <Route exact path='/sign-up' component={SignUpPage} />
              <Route exact path='/reset-password' component={ResetPasswordPage} />
              <Route exact path='/sign-up/:event' component={SignUpPage} />

            </Switch>
        </BrowserRouter>
      </PersistGate>

    </Provider>
  );
}

export default App;
