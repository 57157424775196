import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../Icon'
import { colors, centered, boxShadow } from '../../theme'

const useStyles = createUseStyles({
  action: {
    background: colors.white,
    width: 24,
    minWidth: 24,
    height: 5,
    borderRadius: 6,
    position: 'relative',
    ...centered,
    '&.hasClick': {
      cursor: 'pointer',
    },
    '&.round': {
      '& $icon': {
        width: 20,
        height: 20,
      },
    },
    '&.rotated': {
      '& $icon': {
        transform: 'rotateZ(-180deg)',
      }
    },
    '& + &': {
      marginLeft: 8,
    },
  },
  icon: {
    width: 24,
    height: 24,
    stroke: colors.primary_black,
    display: 'block',
    transition: 'transform 300ms ease, stroke 300ms ease',
  },
  image: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'block',
  },
})

export const Action = ({
  className,
  iconClassName,
  icon,
  customIcon,
  image,
  rotated,
  round,
  title,
  onClick,
  children,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(classes.action, { rotated, round, 'hasClick': !!onClick }, className)}
      onClick={onClick}
      title={title}
    >
      {icon &&
        <Icon className={classnames(classes.icon, iconClassName)} iconName={icon} />
      }
      {customIcon && customIcon}
      {image &&
        <img className={classes.image} src={image} alt='' />
      }
      {children}
    </div>
  )
}