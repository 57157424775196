import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useActions } from "../../hooks/useActions"
import { H1, H4 } from "../../components/Typography";
import { Template, Page } from "../../containers";
import { BreadCrumbs, ActionBar, FilterBar, Button, PodcastTile, Icon, Hint, Loader, ActionTile } from "../../components";
import { colors } from "../../theme";
import { isEmpty } from "lodash";

const useStyles = createUseStyles({
  actionBar: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: 46,
    marginBottom: 200,
    '& > *': {
      marginRight: 16
    }
  },
  subtitle: {
    marginTop: 5,
  },
  filterBar: {
    marginTop: 24
  },
  podcastList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'baseline',
  },
  rssImportBtn: {
    marginRight: 22,
  },
  rssIcon: {
    width: 22,
    height: 22,
    marginRight: 8,
    '& > svg': {
      stroke: colors.primary_black
    }
  },
  header: {
    '& div:last-child': {
      marginTop: 25,
    }
  },
  actionTilesBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 34
  }
})

const CRUMBS = [
  { name: 'Главная страница', path: '/' },
  { name: 'Подкасты', path: '' },
];

const FILTERS = [
  { name: 'Опубликованные', status: 'active', onClick: () => { }, count: 4 },
  { name: 'Отложенные', status: false, onClick: () => { }, count: 2 },
  { name: 'Скрытые', status: false, onClick: () => { }, count: 3 },
];

const SORT = {
  options: [
    { label: 'По дате: в начале новые', value: { sortBy: 'date', sortOrder: 'desc' } },
    { label: 'По дате: в начале старые', value: { sortBy: 'date', sortOrder: 'asc' } },
  ]
};

// const PODCAST_LIST = [
//   {
//     id: 1,
//     name: 'Название подкаста',
//     icon: 'podcast_small_preview',
//     created: '05.08.2022',
//     seriesCount: '1'
//   },
//   {
//     id: 2,
//     name: 'Название подкаста24534535345345',
//     icon: 'podcast_small_preview',
//     created: '05.08.2022',
//     seriesCount: '2'
//   },
//   {
//     id: 3,
//     name: 'Название подкаста3',
//     icon: 'podcast_small_preview',
//     created: '05.08.2022',
//     seriesCount: '3'
//   },
//   {
//     id: 4,
//     name: 'Название подкаста4',
//     icon: 'podcast_small_preview',
//     created: '05.08.2022',
//     seriesCount: '4'
//   }
// ]

export const PodcastListPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchPodcastList, cleanPodcastList } = useActions()

  const [sortValue, setSortValue] = useState(SORT.options[0])

  const { podcastList, loading } = useSelector(state => state.podcast)
  const user = useSelector(state => state.account.user)

  useEffect(() => {
    fetchPodcastList()
    // return () => cleanPodcastList()
  }, [])

  const START_ACTIONS = [
    {
      title: 'Скопировать данные из подкаста',
      description: 'Необходимо скопировать ссылку на RSS поток подкаста и указать ссылку на экране загрузки, чтобы наша платформа получила необходимые данные для настройки монетизации твоего подкаста.',
      btn: { text: 'Начать', clickHandler: () => { history.push('/podcasts/import') } },
      icon: 'import_podcast_tile'
    },
    // {
    //   title: 'Создать новый подкаст',
    //   description: 'Если нет подкаста и RSS',
    //   btn: { text: 'Создать новый', clickHandler: () => { history.push('/podcasts/create') } },
    //   icon: 'create_podcast_tile'
    // },
  ]

  const handleImportBtnClick = () => {
    history.push('/podcasts/import')
  }

  const handleCreateBtnClick = () => {
    history.push('/podcasts/create')
  }

  const handleTileClick = (masked_id) => {
    history.push('/podcasts/' + masked_id)
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <BreadCrumbs crumbs={CRUMBS} />
            {!podcastList.length
              ? <div>
                <H1>Добавить подкаст!</H1>
                <div className={classes.actionTilesBlock}>
                  {START_ACTIONS && START_ACTIONS.map((action, idx) => {
                    return <ActionTile
                      key={idx}
                      title={action.title}
                      description={action.description}
                      btn={action.btn}
                      icon={action.icon}
                    />
                  })}
                </div>
              </div>
              : <div>
                <div className={classes.header}>
                  <H1>Список ваших подкастов</H1>
                  <Button className={'primary'} onClick={handleImportBtnClick}>
                    <span>Импортировать из <b>RSS</b></span>
                  </Button>
                </div>
                {/* <H4 className={classes.subtitle}>Выберите импортировать или создать с нуля.</H4> */}
                {/* <FilterBar
              filters={FILTERS}
              sortList={SORT}
              sortValue={sortValue}
              onSortChange={(value) => setSortValue(value)}
            /> */}
                <div className={classes.podcastList}>
                  {podcastList && podcastList.map((podcast, idx) => {
                    return <PodcastTile
                      key={idx}
                      prefix_url_domain={user.prefix_url_domain}
                      podcast={podcast}
                      userType={user.user_type}
                      onClick={() => handleTileClick(podcast.masked_id)}
                    />
                  })}
                </div>
                <ActionBar className={classes.actionBar}>
                  {/* <Button className={classes.actionBarBtn} onClick={handleCreateBtnClick}>
                Создать подкаст
              </Button> */}
                  {/* <Hint text={'Как это сделать в один клик'} /> */}
                </ActionBar>
              </div>
            }
          </Page>
        }
      </Template>
    </>
  )
}