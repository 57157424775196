import React, { useState, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { Icon, Action, AccountMenu } from '../../components' //, Action, Notification
import { centered, justified, ellipsis, colors, fonts } from '../../theme'
// import { useOutsideClick } from '../../hooks'
// import { useActions } from '../../hooks/useActions'
// import { useSelector } from 'react-redux'
// import { Loader } from '../../components'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { isAdmin, isSuperAdmin } from '../../utils'

const HEADER_HEIGHT = 71
const SIDEBAR_WIDTH = 94
const SIDEBAR_WIDTH_ROLLED_UP = 72

const useStyles = createUseStyles({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
  },
  header: {
    fontFamily: fonts.raleway,
    background: colors.primary_black,
    height: HEADER_HEIGHT,
    padding: [18, 24],
    borderBottom: [1, 'solid', colors.primary_light_2],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    ...justified,
  },
  logo: {
    width: 148,
    height: 48,
    marginRight: 38,
    opacity: 1,
    cursor: 'pointer',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  helperNavBlock: {
    flexDirection: 'column',
    ...justified,
  },
  helperLinks: {
    width: '100%',
    flexDirection: 'column',
    ...justified,
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      stroke: colors.primary_black,
      display: 'inline-block',
      verticalAlign: 'top',
      transition: 'transform 300ms ease',
    }
  },
  user: {
    marginTop: 12,
    position: 'relative',
  },
  
  userAvatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'block',
  },
  helper: {
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  route: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: colors.light_grey,
    borderRadius: 8,
    width: 88,
    height: 65,
    '&:hover': {
      background: colors.primary_yellow,
    }
  },
  navButton: {
    width: '100%',
    height: 52,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      background: colors.primary_yellow,
    },
    ...centered
  },
  sidebar: {
    background: colors.light_grey,
    width: SIDEBAR_WIDTH,
    padding: [12, 5, 25, 5],
    fontSize: 12,
    position: 'absolute',
    left: 0,
    top: HEADER_HEIGHT,
    bottom: 0,
    transition: 'width 300ms ease',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& > ul': {
      marginBottom: 50,
    }
  },
  sublist: {
    display: 'none',
  },
  item: {
    ...centered,
    '& + &': {
      marginTop: 12,
    },
  },
  subitem: {
    '& + &': {
      marginTop: 0,
    },
  },
  link: {
    width: '100%',
    transition: 'background 300ms ease, width 300ms ease',
    '&.active': {
      background: colors.back_2,
      width: 'calc(100% + 12px)',
      color: colors.shade,
      borderRadius: [12, 0, 0, 12],
      '& $iconSubitem': {
        opacity: 1,
        stroke: colors.shade,
      },
      '& $iconCorner': {
        opacity: 1,
      },
    },
  },
  iconItem: {
    width: 36,
    height: 29,
    stroke: colors.primary_black,
    strokeWidth: 2,
    display: 'block',
  },
  textItem: {
    fontSize: 12,
    display: 'block',
    whiteSpace: 'initial',
    textAlign: 'center',
    marginTop: 4,
    padding: 1
  },
  iconSubitem: {
    width: 16,
    height: 16,
    marginRight: 10,
    display: 'block',
    opacity: 0,
    transition: 'opacity 300ms ease',
  },
  iconCorner: {
    width: 10,
    height: 10,
    display: 'block',
    fill: colors.back_2,
    position: 'absolute',
    right: 0,
    opacity: 0,
    '&:first-child': {
      top: -10,
    },
    '&:last-child': {
      bottom: -10,
      transform: 'rotateZ(-90deg)',
    },
  },
  iconArrow: {
    width: 20,
    height: 20,
    stroke: colors.primary_default,
    display: 'block',
  },
  workarea: {
    background: colors.background_white,
    minWidth: 1240,
    minHeight: '100%',
    paddingLeft: 120,
    paddingRight: 120,
    position: 'absolute',
    left: SIDEBAR_WIDTH,
    right: 0,
    top: HEADER_HEIGHT,
    bottom: 0,
    transition: 'left 300ms ease',
    overflowX: 'auto',
  },
  copyright: {
    width: '100%',
    padding: [0, 12],
    color: colors.primary_light_1,
    fontSize: 10,
    lineHeight: 15 / 10,
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20,
    opacity: 1,
    overflow: 'hidden',
    transition: 'width 300ms ease, padding 300ms ease, opacity 300ms ease',
  },
  outerLink: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const ROUTES = [
  {
    name: 'Подкасты',
    icon: 'mic',
    link: '/podcasts',
    roleAccess: ['admin', 'podcaster', 'pc_manager', 'partner'],
    items: []
  },
  {
    name: 'Статистика',
    icon: 'chart',
    link: '/stats',
    roleAccess: ['admin', 'podcaster', 'pc_manager', 'partner', 'advertiser'],
    items: []
  },
  {
    name: 'Пользователи',
    icon: 'users',
    link: '/users',
    roleAccess: ['admin', 'pc_manager'],
    items: []
  },
  {
    name: "Рекламные\nсистемы",
    icon: 'adsys',
    link: '/externalsystems',
    roleAccess: ['admin'],
    items: []
  },
  {
    name: 'Претаргет',
    icon: 'adsys',
    link: '/pretarget',
    roleAccess: ['admin'],
    items: []
  },
  {
    name: "Настройка\nподкастов",
    icon: 'adsys',
    link: '/podcast_settings',
    roleAccess: ['admin'],
    items: []
  },
  {
    name: "Настройка\nЕРИД",
    icon: 'adsys',
    link: '/erid_settings',
    roleAccess: ['admin'],
    items: []
  },
  {
    name: "Distribution\nsettings",
    icon: 'adsys',
    link: '/distribution_settings',
    roleAccess: ['super_admin'],
    items: []
  },
  {
    name: "Episodes\nmapping",
    icon: 'adsys',
    link: '/episodes_mapping',
    roleAccess: ['super_admin'],
    items: []
  },
  {
    name: "Proxy IP\nhostings",
    icon: 'adsys',
    link: '/proxy_hosting',
    roleAccess: ['super_admin'],
    items: []
  },
  {
    name: "Podcast\nGroups",
    icon: 'adsys',
    link: '/podcast_groups',
    roleAccess: ['super_admin'],
    items: []
  },
  {
    name: 'Мои\nПользователи',
    icon: 'users',
    link: '/my_users',
    roleAccess: ['partner'],
    items: []
  },
]

export const Template = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()

  const user = useSelector(state => state.account.user)

  const pageChange = (link) => {
    history.push(link)
  }

  return (
    <>
      <div className={classnames(classes.root)}>
        <div className={classes.header}>
          <div className={classes.helper}>
            <Link className={classes.logo} to='/'>
              <Icon iconName='logo' />
            </Link>
          </div>
        </div>
        <div className={classes.sidebar}>
          <div className={classes.wrapper}>
            <ul className={classes.list}>
              {ROUTES.map(({ name, icon, roleAccess, link }, index) => {
                if(!roleAccess.includes(user.user_type)) { return false }
                  return <li className={classes.item} key={index}>
                  <div className={classes.route} onClick={() => pageChange(link)}>
                    <Icon className={classes.iconItem} iconName={icon} />
                    <span className={classes.textItem}>{name}</span>
                  </div>
                </li>
                }
              )}
            </ul>
            <div className={classes.helperNavBlock}>
              <div className={classes.helperLinks}>
                <div className={classes.navButton}>
                  <Link to='/notifications' onClick={(e) => {e.preventDefault()}}>
                    <Icon iconName='bell' />
                  </Link>
                </div>
                <div className={classes.navButton}>
                  <a className={classes.outerLink} target="_blank" rel="noopener noreferrer" href='https://zayads.ru/faq'>
                    <Icon iconName='question_mark' />
                  </a>
                </div>
                {/* {isAdmin(user.user_type) && <div className={classes.navButton}>
                  <Link to='/settings' onClick={(e) => {e.preventDefault()}}>
                    <Icon iconName='gear' />
                  </Link>
                </div>} */}
              </div>
              <div className={classes.user}>
                <AccountMenu />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.workarea}>
          {children}
        </div>
      </div>
    </>
  )
}
