import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { H1 } from "../../components/Typography";
import { Template, Page } from "../../containers";
import { BreadCrumbs, Button, Input, Icon, Hint, Selector, Datepicker, Table, Loader } from "../../components";
import { colors } from "../../theme";
import { useActions, usePrevious } from "../../hooks"
import { useLocation, useParams } from 'react-router'
import _, { groupBy, isEmpty } from "lodash"
import { formattedNumberRub, isAdvertiser, numberWithQuoteSeparator } from "../../utils";
import { useHistory } from "react-router";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
  PieChart,
  Pie
} from 'recharts'

const useStyles = createUseStyles({
  pageTitle: {

  },
  sectionTitle: {
    marginTop: 80
  },
  filterBlock: {
    marginTop: 34,
    display: 'flex',
  },
  filter: {
    marginRight: 5,
    width: 300,
    '&:last-child': {
      width: 500
    }
  },
  totalsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 34,
    '& > div:first-child': {
      margin: [0, 15, 10, 0],
      '& table': {
        minWidth: 520,
        background: colors.primary_black,
        color: colors.background_white,
        lineHeight: '130%',
        borderRadius: 8,
        '& td': {
          borderRight: [1, 'solid', colors.background_white],
        }
      }
    }
  },
  getReportBtn: {
    display: 'block',
    width: 189,
    // margin: [0, 0, 0, 94]
  },
  cellWrapper: {
    width: 520,
    padding: [8, 16],
    display: 'flex',
    justifyContent: 'space-between'
  },
  totalsCount: {
    color: colors.primary_yellow
  },
  filterBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  groupBlock: {
    width: 245,
    margin: [10, 0, 0, 0],
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    '& > label': {
      marginRight: 9
    }
  },
  dateFilterBlock: {
    display: 'flex',
    marginTop: 10,
    marginRight: 43,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  datepickerBlock: {
    display: 'flex'
  },
  datepickerWrapper: {
    minWidth: 250,
    marginRight: 35,
  },
  datepickerLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    whiteSpace: 'nowrap'
  },
  dateBtns: {
    display: 'flex',
    '& > div:last-child': {
      marginRight: 0
    }
  },
  statsBlock: {
    marginTop: 20
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    padding: [30, 0],
    width: '100%'
  },
  chart: {
    height: 250,
    fontSize: 12,
    marginTop: 40,
    marginLeft: -40,
  },
  pieChart: {
    height: 350,
    width: 695,
    fontSize: 12,
    marginTop: 40,
  },
  pieChartGroup: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  pieChartPlaceholder: {
    '& > p': {
      marginTop: 10
    },
    '& > svg': {
      margin: [90, 0, 45, 70]
    },
  },
  barChartPlaceholder: {
    '& > p': {
      margin: [10, 0, 20, 0]
    },
    '& > svg': {
      marginLeft: '30%'
    },
  },
  statsViewCtrl: {
    display: 'flex',
    margin: [10, 0, 10, 0],
    alignItems: 'center',
    justifyContent: 'start',
    '& > p': {
      marginRight: 10
    }
  },
  chartTitle: {
    marginTop: 45
  },
  pieChartBlock: {
    width: '50%'
  }
})

const GROUP_BY = [
  { label: 'Источники', value: 'sources' },
]

const DATE_BTNS = [
  { label: 'сегодня', status: 'active', periodName: 'today' },
  { label: 'вчера', periodName: 'yesterday' },
  { label: 'неделя', periodName: 'last_week' },
  { label: 'месяц', periodName: 'last_month' },
  { label: 'год', periodName: 'last_year' },
  { label: 'за всё время', periodName: 'all_time' },
]

export const AdvStatsPage = () => {
  const classes = useStyles()
  const {
    fetchAdvStats,
    resetAdvStats,
    resetAdvStatTotals
  } = useActions()

  const initialDate = new Date();

  const [startDate, setStartDate] = useState(new Date(initialDate.getFullYear(), initialDate.getMonth(), 1))
  const [endDate, setEndDate] = useState(new Date())
  const [statsList, setstatsList] = useState([])
  const [itunesStatsList, setItunesStatsList] = useState([])
  const [showPreTotal, setShowPreTotal] = useState(0)
  const [showMidTotal, setShowMidTotal] = useState(0)
  const [showPostTotal, setShowPostTotal] = useState(0)
  const [currentPeriodName, setcurrentPeriodName] = useState('last_month')
  const [placeholder, setPlaceholder] = useState('Выберите период и сформируйте отчет')
  const [reportGenerated, setReportGenerated] = useState(false)
  const [statsView, setStatsView] = useState('chart')
  const [selectedGroup, setSelectedGroup] = useState('')

  const stats = useSelector(state => state.advertiser?.stats)
  const chartLegends = useSelector(state => state.advertiser?.chartLegends)
  const showsByPlacement = useSelector(state => state.advertiser?.showsByPlacement)
  const showsByPlacementPie = useSelector(state => state.advertiser?.showsByPlacementPie)
  const showsByGeoCities = useSelector(state => state.advertiser?.showsByGeoCities)
  const showsByDevice = useSelector(state => state.advertiser?.showsByDevice)
  const showsByApps = useSelector(state => state.advertiser?.showsByApps)
  const showsByItunesCat = useSelector(state => state.advertiser?.showsByItunesCat)
  const showsByPlacementLegend = useSelector(state => state.advertiser?.showsByPlacementLegend)
  const showsByErid = useSelector(state => state.advertiser?.showsByErid)
  const totals = useSelector(state => state.advertiser?.totals)
  const { loading } = useSelector(state => state.advertiser)

  const CRUMBS = [
    { name: 'Общая статистика' },
  ];

  const getStats = () => {
    let filter = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      groupBy: selectedGroup,
    }
    resetAdvStats()
    fetchAdvStats(filter)
  }

  useEffect(() => {
    getStats()

    return () => {
      resetAdvStats()
      resetAdvStatTotals()
    }
  }, [])

  useEffect(() => {
    if (showsByPlacement && showsByPlacement.length > 0) {
      let showPreTotal = 0
      let showMidTotal = 0
      let showPostTotal = 0

      showsByPlacement.map(el => {
        showPreTotal = showPreTotal + el.show_pre_roll
        showMidTotal = showMidTotal + el.show_mid_roll
        showPostTotal = showPostTotal + el.show_post_roll
      })

      setShowPreTotal(showPreTotal)
      setShowMidTotal(showMidTotal)
      setShowPostTotal(showPostTotal)
    }
  }, [showsByPlacement])

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  }

  const onGenerateReportButtonClick = () => {
    setReportGenerated(true)
    getStats()
  }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 3;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) - 3;

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0) >= 1 ? (percent * 100).toFixed(0) : '<1'}%`}
      </text>
    );
  }

  const HEADERS = [
    { name: 'Дата', colSpan: 1 },
    { name: 'Рекламные вставки', colSpan: 1 },
  ]

  const HEADERS_ITUNES = [
    { name: 'Категория itunes', colSpan: 1 },
    { name: 'Вставки', colSpan: 1 },
    { name: 'Уникальные', colSpan: 1 },
  ]

  useEffect(() => {
    if (!isEmpty(stats)) {
      let list = [];
      list = stats.map(item => {
        return {
          columns: [
            { key: 'date', type: 'text', value: item.date },
            { key: 'show', type: 'text', value: numberWithQuoteSeparator(item.show) },
          ]
        }
      })

      setPlaceholder("")
      setstatsList(list)
    } else {
      setstatsList([])
      reportGenerated && setPlaceholder("Нет данных за выбранный период")
    }
  }, [stats])

  useEffect(() => {
    if (!isEmpty(showsByItunesCat)) {
      let list = [];
      list = showsByItunesCat.map(item => {
        return {
          columns: [
            { key: 'itunes_category', type: 'text', value: item.name },
            { key: 'sum_show1_avg', type: 'text', value: numberWithQuoteSeparator(item.sum_show1_avg) },
            { key: 'sum_unique_show_avg', type: 'text', value: numberWithQuoteSeparator(item.sum_unique_show_avg) },
          ]
        }
      })

      setPlaceholder("")
      setItunesStatsList(list)
    } else {
      setItunesStatsList([])
      reportGenerated && setPlaceholder("Нет данных за выбранный период")
    }
  }, [showsByItunesCat])


  const setPeriod = (periodName) => {
    const today = new Date();
    let date = new Date();

    switch (periodName) {
      case 'today':
        setStartDate(new Date());
        setEndDate(new Date());
        setcurrentPeriodName('today')
        break;

      case 'yesterday':
        date.setDate(today.getDate() - 1)
        setStartDate(date);
        setEndDate(date);
        setcurrentPeriodName('yesterday')
        break;

      case 'last_week':
        date.setDate(today.getDate() - 7)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_week')

        break;

      case 'last_month':
        date.setDate(today.getDate() - 30)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_month')

        break;

      case 'last_year':
        date.setDate(today.getDate() - 365)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_year')

        break;

      case 'all_time':
        date.setDate(today.getDate() - 1000)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('all_time')

        break;
      default:
        break;
    }
  }

  return (
    <Template>
      {loading
        ? <Loader show={loading} />
        : <Page>
          <BreadCrumbs crumbs={CRUMBS} />
          <H1 className={classes.pageTitle}>Статистика по вашим ЕРИД</H1>
          <div className={classes.totalsBlock}>
            <div>
              <table className={classes.totalsTable}>
                <tbody>
                  <tr>
                    <td>
                      <div className={classes.cellWrapper}>
                        <div>Всего рекламных вставок за период</div>
                        <div className={classes.totalsCount}>{numberWithQuoteSeparator(totals.shows)}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.getReportBtn}>
              <Button className={'primary'} onClick={onGenerateReportButtonClick}>Показать отчёт</Button>
            </div>
          </div>
          <div className={classes.filterBar}>
            <div className={classes.dateFilterBlock}>
              <div className={classes.datepickerBlock}>
                <div className={classes.datepickerLabel}>Данные за период</div>
                <div className={classes.datepickerWrapper}>
                  <Datepicker
                    className={classes.datepicker}
                    startDate={startDate}
                    endDate={endDate}
                    name='date'
                    prefix=''
                    value={''}
                    minDate={moment().subtract(365, 'day').toDate()}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className={classes.dateBtns}>
                {DATE_BTNS.map((btn, idx) => {
                  return <Button key={idx} className={'dateFilter'} status={btn.periodName === currentPeriodName ? 'active' : ''} onClick={() => setPeriod(btn.periodName)}>{btn.label}</Button>
                })}
              </div>
            </div>
          </div>
          <div className={classes.statsViewCtrl}>
            <p>Вид:</p>
            <Button className={'toggle'} status={statsView === 'chart' ? 'active' : ''} onClick={() => setStatsView('chart')}>График</Button>
            <Button className={'toggle'} status={statsView !== 'chart' ? 'active' : ''} onClick={() => setStatsView('table')}>Таблица</Button>
          </div>
          {(statsView === 'chart')
            ? <>
              <H1 className={classes.chartTitle}>Количество рекламных вставок и прослушиваний</H1>
              {!isEmpty(showsByPlacement)
                ? <>
                  <div className={classes.chart}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart data={showsByPlacement}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' />
                        <YAxis tickFormatter={(num) => numberWithQuoteSeparator(num)} />
                        <Tooltip cursor={{ fill: colors.form_grey }} />
                        <Legend />
                        {showsByPlacementLegend && showsByPlacementLegend.map((item, index) => {
                          return <Bar
                            dataKey={item.field}
                            name={item.label}
                            stackId="a"
                            fill={item.color}
                            key={index} />
                        })}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div style={{ lineHeight: '130%' }}>
                    <p>pre-roll: <strong>{numberWithQuoteSeparator(showPreTotal)}</strong></p>
                    <p>mid-roll: <strong>{numberWithQuoteSeparator(showMidTotal)}</strong></p>
                    <p>post-roll: <strong>{numberWithQuoteSeparator(showPostTotal)}</strong></p>
                    <p style={{ fontWeight: '500' }}>Всего вставок за выбранный период: <strong>{numberWithQuoteSeparator(showPreTotal + showMidTotal + showPostTotal)}</strong></p>
                  </div>
                </>
                : <div className={classes.barChartPlaceholder}>
                  <p>Данные отсутствуют</p>
                  <Icon iconName='stat_chart' />
                </div>
              }
              <H1 className={classes.sectionTitle}>Статистика по всем вашим ЕРИД</H1>
              <div className={classes.pieChartGroup}>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по ЕРИД</H1>
                  {!isEmpty(showsByErid)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByErid}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByErid.map((item, index) => {
                              // const colors = ['#0088FE', '#00C49F', '#A79EE1', '#FFBB28', '#FF8042', '#594BBB', '#17BAEE']
                              const colors = ['#17BAEE', '#00CFE8', '#00E1D1', '#62EEAE', '#B0F689',
                                '#3FA3EB', '#6A89DD', '#8C6DC2', '#A24D9C', '#A92B6E', '#0085B6']
                              return <Cell key={`cell-${index}`} fill={colors[Math.abs(index - (colors.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по рекламным местам</H1>
                  {!isEmpty(showsByPlacementPie)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByPlacementPie}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByPlacementPie.map((item, index) => {
                              const colors = { pre_roll: '#0085B6', mid_roll: '#A92B6E', post_roll: '#A24D9C' }
                              return <Cell key={`cell-${index}`} fill={colors[item.name]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по городам</H1>
                  {!isEmpty(showsByGeoCities)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByGeoCities}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByGeoCities.map((item, index) => {
                              // const colors = ['#356aff', '#e92828', '#3bd973', '#604fe9', '#50f5ed', '#fb8d3d', '#799cff',
                              // '#b554ff', '#8cffb4', '#a55f2a', '#000000', '#494949', '#ff7de3', '#002fb4']
                              const colors = ['#17BAEE', '#00CFE8', '#00E1D1', '#62EEAE', '#B0F689',
                                '#3FA3EB', '#6A89DD', '#8C6DC2', '#A24D9C', '#A92B6E', '#0085B6']
                              return <Cell key={`cell-${index}`} fill={colors[Math.abs(index - (colors.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по устройствам</H1>
                  {!isEmpty(showsByDevice)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByDevice}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByDevice.map((item, index) => {
                              // const colors = ['#356aff', '#e92828', '#3bd973', '#604fe9', '#50f5ed', '#fb8d3d', '#799cff',
                              // '#b554ff', '#8cffb4', '#a55f2a', '#000000', '#494949', '#ff7de3', '#002fb4']
                              const colors = ['#17BAEE', '#00CFE8', '#00E1D1', '#62EEAE', '#B0F689',
                                '#3FA3EB', '#6A89DD', '#8C6DC2', '#A24D9C', '#A92B6E', '#0085B6']
                              return <Cell key={`cell-${index}`} fill={colors[Math.abs(index - (colors.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по приложениям</H1>
                  {!isEmpty(showsByApps)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByApps}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByApps.map((item, index) => {
                              // const colors = ['#356aff', '#e92828', '#3bd973', '#604fe9', '#50f5ed', '#fb8d3d', '#799cff',
                              // '#b554ff', '#8cffb4', '#a55f2a', '#000000', '#494949', '#ff7de3', '#002fb4']
                              const colors = ['#17BAEE', '#00CFE8', '#00E1D1', '#62EEAE', '#B0F689',
                                '#3FA3EB', '#6A89DD', '#8C6DC2', '#A24D9C', '#A92B6E', '#0085B6']
                              return <Cell key={`cell-${index}`} fill={colors[Math.abs(index - (colors.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Вставки по категориям iTunes</H1>
                  {!isEmpty(showsByItunesCat)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="sum_show1_avg"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByItunesCat}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByItunesCat.map((item, index) => {
                              // const colors = ['#356aff', '#e92828', '#3bd973', '#604fe9', '#50f5ed', '#fb8d3d', '#799cff',
                              // '#b554ff', '#8cffb4', '#a55f2a', '#000000', '#494949', '#ff7de3', '#002fb4']
                              const colors = ['#17BAEE', '#00CFE8', '#00E1D1', '#62EEAE', '#B0F689',
                                '#3FA3EB', '#6A89DD', '#8C6DC2', '#A24D9C', '#A92B6E', '#0085B6']
                              return <Cell key={`cell-${index}`} fill={colors[Math.abs(index - (colors.length - 1))]} />
                            })}
                          </Pie>
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
              </div>
            </>
            : <div className={classes.statsBlock}>
              {placeholder
                ? <div className={classes.placeholder}>
                  <H1>{placeholder}</H1>
                </div>
                : <>
                  <Table headers={HEADERS} items={statsList} />
                  <Table headers={HEADERS_ITUNES} items={itunesStatsList} />
                </>
              }
            </div>}
        </Page>
      }
    </Template>
  )
}