import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { colors } from '../../../theme'
import { Input, Button, Hint, Checkbox } from '../../../components'
import { SwitchButton } from '../../../components/Form/SwitchButton'
import { Numeric } from '../../../components/Form/Numeric'
import { isEmpty } from 'lodash'

const useStyles = createUseStyles({
  tagsSection: {
    marginBottom: 48,
  },
  sectionTitle: {
    marginBottom: 16,
  },
  tagSelect: {
    width: 1037,
    height: 46,
    background: colors.background_white,
    padding: [8, 16],
    border: [1, 'solid', colors.input_border],
    borderRadius: 8,
  },
  copyPrefixBlock: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    marginBottom: 15
  },
  copyProxyBlock: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    marginBottom: 15
  },
  elUrl: {
    '& input': {
      color: colors.dark_grey,
    },
    minWidth: 330,
    maxWidth: 448,
    width: '100%',
    marginTop: 20,
    marginRight: 24,
  },
  btnWrapper: {
    position: 'relative',
    margin: [4, 27, 4, 0],
    '& > div.button': {
      minWidth: 126
    }
  },
  hintWrapper: {
    margin: [11, 0]
  },
  rollsBlock: {
    marginTop: 50,
    marginBottom: 30
  },
  rollsWrapper: {
    display: 'flex',
  },
  rollsParams: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 448,
    border: [1, 'solid', colors.input_border],
    borderRadius: 8,
    padding: 16,
    marginRight: 24,
  },
  rollsChip: {
    width: 'fit-content',
    '& span': {
      fontSize: 16,
      lineHeight: '130%',
    },
    background: colors.primary_black,
    color: colors.background_white,
    borderRadius: 4,
    padding: [4, 8],
    marginBottom: 26,
  },
  activeAds: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  integrBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  adsCount: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    marginTop: '-5px',
    '& span': {
      marginRight: 8,
    }
  },
  autoOpt: {
    width: 'fit-content'
  },
  clipboardNotice: {
    position: 'absolute',
    display: 'block',
    top: -35,
    left: -17,
    width: 'fit-content',
    whiteSpace: 'nowrap',
    color: colors.background_white,
    background: colors.text_black,
    fontSize: 13,
    borderRadius: 8,
    padding: [4, 10],
  },
  checkbox: {

  },
})

export const TagsSection = ({
  className,
  podcast,
  pcParams,
  setPcAds,
  getPodcastParams,
  prefixUrl,
  proxyRss,
  disableControls,
  setIntegration
}) => {
  const classes = useStyles()
  const [activePreRolls, setActivePreRolls] = useState(false)
  const [activePostRolls, setActivePostRolls] = useState(false)
  const [showCopyPrefixNotice, setShowCopyPrefixNotice] = useState(false)
  const [showCopyProxyNotice, setShowCopyProxyNotice] = useState(false)
  const [autoOpt, setAutoOpt] = useState(true)
  const [adsCount, setAdsCount] = useState(2)

  useEffect(() => {
    if (!isEmpty(pcParams)) {
      if (pcParams.activePreRolls)
        setActivePreRolls(pcParams.activePreRolls)
      if (pcParams.activePostRolls)
        setActivePostRolls(pcParams.activePostRolls)
    }
  }, [pcParams])

  const rolls = [
    {
      title: 'Pre-Rolls',
      activeAds: !!activePreRolls,
      count: 2,
      value: activePreRolls,
      toggle: () => {
        setActivePreRolls(!activePreRolls)
        setPcAds(podcast.masked_id, 'preroll', !activePreRolls, getPodcastParams)
      },
    },
    {
      title: 'Post-Rolls',
      activeAds: !!activePostRolls,
      count: 1,
      value: activePostRolls,
      toggle: () => {
        setActivePostRolls(!activePostRolls)
        setPcAds(podcast.masked_id, 'postroll', !activePostRolls, getPodcastParams)
      },
    }
  ]

  const handleCopyPrefixUrl = () => {
    navigator.clipboard.writeText(prefixUrl)
    setShowCopyPrefixNotice(true)
    setTimeout(() => setShowCopyPrefixNotice(false), 1500)
  }

  const handleCopyProxyRss = () => {
    navigator.clipboard.writeText(proxyRss)
    setShowCopyProxyNotice(true)
    setTimeout(() => setShowCopyProxyNotice(false), 1500)
  }

  const handleChangeIntegrationPrefix = (e) => {
    if (window.confirm(`Prefix URL добавлен для подкаста "${podcast.title}"?`)) {
      setIntegration('prefix', podcast.masked_id, getPodcastParams)
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const handleChangeIntegrationProxy = (e) => {
    if (window.confirm(`Proxy RSS добавлен для подкаста "${podcast.title}" в настройках дистрибуционных платформ?`)) {
      setIntegration('proxy', podcast.masked_id, getPodcastParams)
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const handleAutoOptToggle = () => {
    setAutoOpt(!autoOpt)
  }

  return <div className={classes.tagsSection}>
    {/* <H1 className={classes.sectionTitle}>Добавить теги (для таргета)</H1> */}
    {/* <div className={classes.tagSelect}></div> */}
    <div className={classes.copyPrefixBlock}>
      <div className={classes.elUrl}>
        <Input
          type='text'
          label='Prefix URL:'
          labelStyle='bold'
          value={prefixUrl}
          disabled={true}
        />
      </div>
      <div className={classes.btnWrapper}>
        {showCopyPrefixNotice && <div className={classes.clipboardNotice}>Prefix URL скопирован!</div>}
        <Button className={'primary'} onClick={handleCopyPrefixUrl}>
          Копировать
        </Button>
      </div>
      <div className={classes.hintWrapper}>
        <Hint text={'Подробная инструкция для различных хостингов по добавлению Prefix URL'} link='https://zayads.ru/instr_prefixurl'/>
      </div>
    </div>
    <div className={classes.integrBlock}>
      <Checkbox
        className={classes.checkbox}
        id={`integr_prefix`}
        label={parseInt(podcast.prefix_integration) ? 'Prefix URL добавлен в RSS подкаста' : 'Prefix URL сейчас не добавлен в RSS подкаста.'}
        checked={parseInt(podcast.prefix_integration)}
        onChange={handleChangeIntegrationPrefix}
        disabled={disableControls}
      />
    </div>
    <div className={classes.copyProxyBlock}>
      <div className={classes.elUrl}>
        <Input
          type='text'
          label='Proxy RSS:'
          labelStyle='bold'
          value={proxyRss}
          disabled={true}
        />
      </div>
      <div className={classes.btnWrapper}>
        {showCopyProxyNotice && <div className={classes.clipboardNotice}>Proxy RSS скопирован!</div>}
        <Button className={'primary'} onClick={handleCopyProxyRss}>
          Копировать
        </Button>
      </div>
      <div className={classes.hintWrapper}>
        <Hint text={'Подробная инструкция для различных дистрибуционных платформ по добавлению Proxy RSS'} link='https://zayads.ru/instr_proxyrss'/>
      </div>
    </div>
    <div className={classes.integrBlock}>
      <Checkbox
        className={classes.checkbox}
        id={`integr_proxy`}
        label={parseInt(podcast.proxy_integration) ? 'Proxy RSS добавлен в дистр. платформу' : 'Proxy RSS сейчас не добавлен в настройках дистр. платформ'}
        checked={parseInt(podcast.proxy_integration)}
        onChange={handleChangeIntegrationProxy}
        disabled={disableControls}
      />
    </div>
    <div className={classes.rollsBlock}>
      <div className={classes.rollsWrapper}>
        {rolls.map((item, idx) => {
          return <div key={idx} className={classes.rollsParams}>
            <div className={classes.rollsChip}>
              <span>{item.title}</span>
            </div>
            <div className={classes.activeAds}>
              <Checkbox
                className={classes.checkbox}
                id={`${item.title}-${idx}`}
                label={`Включены вставки ${item.title === 'Pre-Rolls' ? 'pre-roll' : 'post-roll'} во всех эпизодах`}
                checked={item.activeAds}
                onChange={item.toggle}
                disabled={disableControls}
              />
              {/* {item.title === 'Post-Rolls' && <div className={classes.adsCount}> */}
              {/* <span>Кол-во</span>
                <Numeric min={1} max={10} value={adsCount}/> */}
              {/* </div>} */}
            </div>
          </div>
        })}
      </div>
    </div>
    {/* <div className={classes.autoOpt}>
        <SwitchButton 
          handleChange={handleAutoOptToggle}
          checked={autoOpt}
          label={'Автоматическая оптимизация рекламы в подкасте'}
        />
    </div> */}
  </div>
}
