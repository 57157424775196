import moment from "moment";
import { DOMAIN } from "../configs/mainconf";

export const formattedNumberRub = (number) => number
  ? (Math.floor(number).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedNumberRubFloat = (number) => number
  ? (Number(number).toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  : 0

export const formattedDate = (date) => date
  ? moment(date).format('DD.MM.YYYY')
  : ''

export const numberWithQuoteSeparator = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

export const useOutsideClickHandler = (ref, hide) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      hide()
    }
  }

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  }
}

export const PHONE_PROPS = {
  mask: '+7 (999) 999-99-99',
  maskChar: '_',
  formatChars: {
    '9': '[0-9]',
  },
  alwaysShowMask: true,
}

export const checkEmail = (value) =>
  //eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())

export const checkFio = (fio) => {
  const fioArr = fio.split(' ')
  return (fioArr.length > 2)
}

export const isPressEscape = (e) => e.keyCode === 27

export const bytesToKb = (bytes) => bytes / 1024 >= 1 ? (bytes / 1024).toFixed(0) : 1

export const inputNumericFilter = (e) => {
  if (e.key.length === 1) {
    if (!/\d/.test(e.key)) e.preventDefault()
  }
}

export const inputFloatFilter = (e) => {
  let newValue = ''

  if (e.key.length === 1) {
    if (!/\d|[.]|[,]/.test(e.key)) e.preventDefault()
    else if (e.target.value.length === 0) {
      if (e.key === '.' || e.key === ',' || e.key === '0') {
        e.preventDefault()
        e.target.value = '0.'
      }
    } else {
      if (e.key === ',' || e.key === '.') {
        e.preventDefault()
        const pos = e.target.selectionStart
        newValue = [e.target.value.slice(0, pos), '.', e.target.value.slice(pos)].join('')
        if (/^([0-9]*.)?([0-9]+)?$/.test(newValue)) {
          e.target.value = newValue
          e.target.selectionStart = pos + 1
          e.target.selectionEnd = pos + 1
        }
      }
    }
  }
}

export const isAdmin = (type) => {
  return type === 'admin'
}

export const isSuperAdmin = (type) => {
  return type === 'super_admin'
}

export const isAdvertiser = (type) => {
  return type === 'advertiser'
}

export const isPodcastManager = (type) => {
  return type === 'pc_manager'
}

export const isPartner = (type) => {
  return type === 'partner'
}

export const specialCharsParser = (string) => {
  const parser = new DOMParser();
  return parser.parseFromString(string, 'text/html').body.textContent;
}

export const generatePrefixUrl = (prefix_url_domain, prefix_url_id) => {
  return `${prefix_url_domain}${prefix_url_id}/`
}

export const generateProxyRss = (prefix_url_domain, prefix_url_id) => {
  return `${prefix_url_domain}rss/${prefix_url_id}/`
}

export const transformMsTimeMask = (ms) => {
  const minutes = parseInt(ms / 60000)
  const seconds = parseInt((parseInt(ms) - minutes * 60000) / 1000)
  const milliseconds = parseInt(ms) - (minutes * 60000 + seconds * 1000)

  return `${minutes <= 9 ? '0' + minutes : minutes}:${seconds <= 9 ? '0' + seconds : seconds}:${milliseconds <= 9 ? '00' + milliseconds : milliseconds <= 99 ? '0' + milliseconds : milliseconds}`
}

export const transformTimeMaskToMs = (offset) => {
  let offsetArr = []
  offsetArr = offset.split(':')

  return parseInt(offsetArr[0]) * 60 * 1000 + parseInt(offsetArr[1]) * 1000 + parseInt(offsetArr[2])
}

export const generateAudioLink = (masked_id) => {
  const userData = JSON.parse(JSON.parse(localStorage['persist:root']).account).user
  const access_token = (userData && userData.access_token) ? userData.access_token : ''

  return DOMAIN + '/stream?id=' + masked_id + '&access_token=' + access_token
}

// Table sorting
export const getSortOrder = (newSort, sortBy, sortOrder) => {
  if (sortBy !== newSort)
    return 'DESC'
  else
    return sortOrder === 'DESC' ? 'ASC' : 'DESC'
}

export const getSortIcon = (field, sortBy, sortOrder) => {
  if (sortBy === field)
    return sortOrder === 'DESC' ? 'sort-desc' : 'sort-asc'
}