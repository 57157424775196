import API from '../../utils/Api'

import { REQUEST_NOTIFICATION_ERROR, REQUEST_NOTIFICATION_SUCCESS } from '../reducers/notificationReducer'

import {
  ADMIN_REQUEST,
  ADMIN_REQUEST_ERROR,
  ADMIN_FETCH_USER_LIST_SUCCESS,
  ADMIN_FETCH_EXTERNAL_SYSTEMS_LIST_SUCCESS,
  ADMIN_EXTERNAL_SYSTEMS_EDIT_SUCCESS,
  ADMIN_CLEAN_USER_LIST,
  ADMIN_REQUEST_STATE_CLEAR,
  ADMIN_USER_ADD_SUCCESS,
  ADMIN_EXTERNAL_SYSTEM_ADD_SUCCESS,
  ADMIN_CLEAN_EXTERNAL_SYSTEMS_LIST,
  ADMIN_USER_TOGGLE_SEND_ADFOX_PC_ID_SUCCESS,
  ADMIN_REQUEST_PRETARGET,
  ADMIN_FETCH_PRETARGET_LIST_SUCCESS,
  ADMIN_PRETARGET_ADD_SUCCESS,
  ADMIN_CLEAN_PRETARGET_LIST,
  ADMIN_REQUEST_ERID,
  ADMIN_ADD_ERID_SUCCESS,
  ADMIN_FETCH_ERID_LIST_SUCCESS,
  ADMIN_REMOVE_ERID_SUCCESS,
  ADMIN_CLEAN_ERID_LIST,
  ADMIN_REMOVE_PRETARGET_SUCCESS,
  ADMIN_SWITCH_USER_PODCASTS_SUCCESS,
  ADMIN_PODCAST_EDIT_SUCCESS,
  ADMIN_REQUEST_PODCAST_LIST,
  ADMIN_FETCH_PODCAST_LIST_SUCCESS,
  ADMIN_PODCAST_STATUS_SWITCH_SUCCESS,
  ADMIN_RESET_USER_PASSWORD_SUCCESS
} from '../reducers/adminReducer'

export const fetchUserList = () => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user

    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.get('/admin/userlist', {}, access_token)

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: ADMIN_FETCH_USER_LIST_SUCCESS, payload: response.data.userList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const fetchExternalSystems = () => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user

    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.get('/admin/externalsystemslist', {}, access_token)

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: ADMIN_FETCH_EXTERNAL_SYSTEMS_LIST_SUCCESS, payload: response.data.externalSystemsList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const editAdsSystem = (system_id, data = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/externalsystemedit', {system_id, data})
      if (response.data.success) {
        dispatch({ type: ADMIN_EXTERNAL_SYSTEMS_EDIT_SUCCESS, payload: response.data.externalSystemsList })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const addUser = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/useradd', formData)
      if (response.data.success) {
        dispatch({ type: ADMIN_USER_ADD_SUCCESS, payload: response.data.userList })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const addExternalSystem = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/externalsystemadd', formData)
      if (response.data.success) {
        dispatch({ type: ADMIN_EXTERNAL_SYSTEM_ADD_SUCCESS, payload: response.data.externalSystemsList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const toggleSendAdfoxPcId = (userId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/usersendadfoxpcid', {userId})
      if (response.data.success) {
        dispatch({ type: ADMIN_USER_TOGGLE_SEND_ADFOX_PC_ID_SUCCESS, payload: response.data.userList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const fetchPretargetList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { pretargetFilter } = getState().admin;
    const params = {...pretargetFilter, ...fetchParams }

    try {
      dispatch({ type: ADMIN_REQUEST_PRETARGET, payload: params })
      const response = await API.get('/admin/pretargetlist', params, access_token)

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: ADMIN_FETCH_PRETARGET_LIST_SUCCESS, payload: response.data.pretargetList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const addPretargets = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/pretargetadd', formData)
      if (response.data.success) {
        dispatch({ type: ADMIN_PRETARGET_ADD_SUCCESS, payload: response.data.pretargetList })
      } else {
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deletePretarget = (pretargetId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/pretargetdelete', {pretargetId})
      if (response.data.success) {
        dispatch({ type: ADMIN_REMOVE_PRETARGET_SUCCESS, payload: response.data.pretargetList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}


export const fetchEridList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { eridFilter } = getState().admin;
    const params = {...eridFilter, ...fetchParams }

    try {
      dispatch({ type: ADMIN_REQUEST_ERID, payload: params })
      const response = await API.get('/admin/eridlist', params, access_token)

      if (response.data.success) {
        dispatch({ type: ADMIN_FETCH_ERID_LIST_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}

export const addErid = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/eridadd', formData)
      if (response.data.success) {
        dispatch({ type: ADMIN_ADD_ERID_SUCCESS, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: REQUEST_NOTIFICATION_ERROR, payload: response.data.message })
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const deleteErid = (pretargetId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/pretargetdelete', {pretargetId})
      if (response.data.success) {
        dispatch({ type: ADMIN_REMOVE_PRETARGET_SUCCESS, payload: response.data.pretargetList })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const cleanEridList = () => {
  return async (dispatch) => {
    dispatch({ type: ADMIN_CLEAN_ERID_LIST })
  }
}

export const cleanUserList = () => {
  return async (dispatch) => {
    dispatch({ type: ADMIN_CLEAN_USER_LIST })
  }
}

export const cleanExternalSystemsList = () => {
  return async (dispatch) => {
    dispatch({ type: ADMIN_CLEAN_EXTERNAL_SYSTEMS_LIST })
  }
}

export const cleanPretargetList = () => {
  return async (dispatch) => {
    dispatch({ type: ADMIN_CLEAN_PRETARGET_LIST })
  }
}

export const clearAdminState = () => {
  return { type: ADMIN_REQUEST_STATE_CLEAR }
}

export const switchUserPodcasts = (user_id, podcast_status) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/switchuserpodcasts', {user_id, podcast_status})
      if (response.data.success) {
        dispatch({ type: ADMIN_SWITCH_USER_PODCASTS_SUCCESS })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const resetUserPassword = (user_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/resetuserpassword', {user_id})
      if (response.data.success) {
        dispatch({ type: ADMIN_RESET_USER_PASSWORD_SUCCESS })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const podcastStatusSwitch = (podcast_id, podcast_status) => {
  return async (dispatch, getState) => {
    const { podcastFilter } = getState().admin;
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/switchpodcaststatus', {podcast_id, podcast_status, ...podcastFilter})
      if (response.data.success) {
        dispatch({ type: ADMIN_PODCAST_STATUS_SWITCH_SUCCESS, payload: response.data.podcastList })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const editPodcast = (podcast_id, field = {}) => {
  return async (dispatch, getState) => {
    const { podcastFilter } = getState().admin;
    try {
      dispatch({ type: ADMIN_REQUEST })
      const response = await API.post('/admin/editpodcast', {podcast_id, field, ...podcastFilter})
      if (response.data.success) {
        dispatch({ type: ADMIN_PODCAST_EDIT_SUCCESS, payload: response.data })
        dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: response.data.message })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { field: 'api', message: error } })
    }
  }
}

export const fetchAdminPodcastList = (fetchParams = {}) => {
  return async (dispatch, getState) => {
    const { access_token } = getState().account.user
    const { podcastFilter } = getState().admin;
    const params = {...podcastFilter, ...fetchParams }

    try {
      dispatch({ type: ADMIN_REQUEST_PODCAST_LIST, payload: params })
      const response = await API.get('/admin/podcastlist', params, access_token)

      if (response.data.success) {
        // dispatch({ type: REQUEST_NOTIFICATION_SUCCESS, payload: notifications.success.message })
        dispatch({ type: ADMIN_FETCH_PODCAST_LIST_SUCCESS, payload: response.data })
      } else {
        dispatch({ type: ADMIN_REQUEST_ERROR, payload: response.data.errors })
      }
    } catch (error) {
      dispatch({ type: ADMIN_REQUEST_ERROR, payload: { api: error } })
    }
  }
}
