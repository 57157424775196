import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors } from '../../../theme'

const useStyles = createUseStyles({
  h4: {
    color: colors.text_black,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '130%'
  },
})

export const H4 = ({
  children,
  className,
  dangerouslySetInnerHTML,
}) => {
  const classes = useStyles()
  return dangerouslySetInnerHTML
    ? <div className={classnames(classes.h4, className)} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
    : <div className={classnames(classes.h4, className)}>{children}</div>
}
