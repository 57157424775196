import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Button } from '../Button'
import { Selector } from '../Form/Selector'
import { colors, centered } from '../../theme'

const useStyles = createUseStyles({
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 24,
    padding: [0, 19, 0, 0],
  },
  filterBlock: {
    display: 'flex',
    height: 38,
  },
  sortBlock: {
    width: 230
  },
  btnLabel: {
    fontSize: 14,
    marginRight: 10
  },
  count: {
    lineHeight: '19px',
    fontWeight: 700,
  },
})

export const FilterBar = ({ filters, sortList, sortValue, onSortChange, className }) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.filterBar, className)}>
      <div className={classes.filterBlock}>
        {filters && filters.map((filter, idx) => {
          return <Button className={classnames('filter')} status={filter.status ? filter.status : ''} key={idx}>
            <span className={classes.btnLabel}>{filter.name}</span>
            <span className={classes.count}>{filter.count}</span>
          </Button>
        })}
      </div>
      {/* <div className={classes.sortBlock}>
        {sortList && <Selector
          value={sortValue}
          options={sortList.options}
          onChange={onSortChange}
        />}
      </div> */}
    </div>
  )
}