import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '..'
import { centered, colors } from '../../theme'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { NOTIFICATIONS_LIFETIME } from '../../configs/mainconf'

const useStyles = createUseStyles({
  event: {
    background: colors.primary_black,
    padding: [24, 24],
    border: [1, 'solid', colors.icon_grey],
    borderRadius: 12,
    textAlign: 'center',
    ...centered,
    flexDirection: 'row',
    '&.toast': {
      position: 'fixed',
      right: 12,
      top: 12,
      zIndex: 1
    },
    '&.success': {
      borderColor: colors.primary_green,
      '& div > span': {
        color: colors.primary_green,
      },
    },
    '&.error': {
      borderColor: colors.coral,
      '& div > span': {
        color: colors.coral,
      },
    },
    '&.info': {
      borderColor: colors.icon_grey,
      '& div > span': {
        color: colors.icon_grey,
      },
    },
    '&.warning': {
      borderColor: colors.primary_yellow,
      '& div > span': {
        color: colors.primary_yellow,
      },
    },
    '& > svg': {
      width: 36,
      height: 36,
      display: 'block',
    },
    '& div > span': {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: 700,
    },
  },
  title: {
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 700,
  },
  messageWrapper: {
    ...centered,
    alignItems: 'start',
    flexDirection: 'column',
    padding: [8, 12, 8, 0],
    marginLeft: 24
  },
  message: {
    color: colors.background_white,
    fontSize: 16,
    maxWidth: '300px',
    lineHeight: 24 / 16,
    fontWeight: 400,
    whiteSpace: 'break-spaces',
    textAlign: 'left'
  },
})

export const Notification = ({ toast }) => {
  const classes = useStyles()
  const [currentTimeout, setCurrentTimeout] = useState('');
  const { show, title, message, status } = useSelector(state => state.notification)
  const { hideRequestNotification } = useActions()

  useEffect(() => {
    if (show) {
      clearTimeout(currentTimeout)
      setCurrentTimeout(setTimeout(hideRequestNotification, NOTIFICATIONS_LIFETIME))
    } else {
      clearTimeout(currentTimeout)
    }
    return () => {clearTimeout(currentTimeout)}
  }, [show]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    clearTimeout(currentTimeout)
    hideRequestNotification()
  }

  return (
    <>
      {show &&
        <div className={classnames(classes.event, { [status]: status, toast })} onClick={handleClick}>
          <Icon iconName={`notification_${status}`} />
          <div className={classes.messageWrapper}>
            <span className={classes.title}>{title ?? 'Уведомление:'}</span>
            <p className={classes.message}>{message}</p>
          </div>
        </div>
      }
    </>
  )
}