import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { colors, fonts } from '../../theme'
import { Icon } from '../Icon'

const useStyles = createUseStyles({
  statusBlock: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: 18,
      height: 18
    }
  },
  statusText: {
    fontSize: 12,
    padding: 6
  }
})

export const Status = ({
  className,
  text,
  icon = '',
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.statusBlock, className)}>
      <Icon iconName={icon ? icon : 'status'} />
      <span className={classes.statusText}>{text}</span>
    </div>
  )
}