import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button } from "../../components";
import { useActions } from "../../hooks";

const useStyles = createUseStyles({
  addExternalSystemForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddExternalSystemForm = () => {
  const classes = useStyles()
  const history = useHistory()

  const [systemName, setSystemName] = useState('')
  const [prerollLink, setPrerollLink] = useState('')
  const [midrollLink, setMidrollLink] = useState('')
  const [postrollLink, setPostrollLink] = useState('')
  const [priority, setPriority] = useState('')
  const [s2s, setS2s] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { externalSystemsErrors, loading } = useSelector(state => state.admin)

  const { addExternalSystem, clearAdminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!systemName.trim() || externalSystemsErrors.systemName) errors_arr.systemName = externalSystemsErrors.systemName ? externalSystemsErrors.systemName : ERRORS.required
    if (!prerollLink.trim() || externalSystemsErrors.prerollLink) errors_arr.prerollLink = externalSystemsErrors.prerollLink ? externalSystemsErrors.prerollLink : ERRORS.required
    if (!midrollLink.trim() || externalSystemsErrors.midrollLink) errors_arr.midrollLink = externalSystemsErrors.midrollLink ? externalSystemsErrors.midrollLink : ERRORS.required
    if (!postrollLink.trim() || externalSystemsErrors.postrollLink) errors_arr.postrollLink = externalSystemsErrors.postrollLink ? externalSystemsErrors.postrollLink : ERRORS.required
    if (!priority.trim() || externalSystemsErrors.priority) errors_arr.priority = externalSystemsErrors.priority ? externalSystemsErrors.priority : ERRORS.required

    setErrors(errors_arr);
  }, [systemName, prerollLink, midrollLink, postrollLink, priority])

  const isValid = systemName !== '' && prerollLink !== '' && midrollLink !== '' && postrollLink !== '' && priority !== ''

  const onAddExternalSystem = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        systemName: systemName,
        prerollLink: prerollLink,
        midrollLink: midrollLink,
        postrollLink: postrollLink,
        s2s: s2s,
        priority: priority,
      }

      clearAdminState()
      addExternalSystem(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'large',
      type: 'text',
      name: 'systemName',
      label: 'Название системы',
      placeholder: '',
      value: systemName,
      onChange: (e) => {
        setSystemName(e.target.value);
      },
      error: submitted > 0 && errors.systemName,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 'prerollLink',
      label: 'Preroll тег',
      placeholder: '',
      value: prerollLink,
      onChange: (e) => {
        setPrerollLink(e.target.value);
      },
      error: submitted > 0 && errors.prerollLink,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 'midrollLink',
      label: 'Midroll тег',
      placeholder: '',
      value: midrollLink,
      onChange: (e) => {
        setMidrollLink(e.target.value);
      },
      error: submitted > 0 && errors.midrollLink,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 'postrollLink',
      label: 'Postroll тег',
      placeholder: '',
      value: postrollLink,
      onChange: (e) => {
        setPostrollLink(e.target.value);
      },
      error: submitted > 0 && errors.postrollLink,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'number',
      name: 'priority',
      label: 'Приоритет',
      min: '0',
      placeholder: '',
      value: priority,
      onChange: (e) => {
        setPriority(e.target.value);
      },
      error: submitted > 0 && errors.priority,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'text',
      name: 's2s',
      label: 'S2S ключ',
      placeholder: '',
      value: s2s,
      onChange: (e) => {
        setS2s(e.target.value);
      },
      error: submitted > 0 && errors.s2s,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addExternalSystemForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
              <Input {...item} />
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button size='medium' className={'primary'} onClick={onAddExternalSystem}>Добавить систему</Button>
          </div>
        </div>}
    </>
  )
}