import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { centered, colors } from '../../theme'
import { Icon, More } from '../../components'
import { isAdmin, formattedDate } from '../../utils'

const useStyles = createUseStyles({
  card: {
    color: colors.text_black,
    padding: [18, 15],
    borderRadius: 8,
    background: colors.background_white,
    '&:hover': {
      background: colors.light_grey
    },
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrapper: {
    display: 'flex',
  },
  info: {
    padding: [5, 0]
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 16,
  },
  statusPublished: {
    fontSize: 16,
    color: colors.primary_green
  },
  statusPending: {
    fontSize: 16,
    color: colors.coral
  },
  statusFailed: {
    fontSize: 16,
    color: colors.coral
  },
  notice: {
    fontSize: 13,
    lineHeight: '130%',
    marginTop: 8,
  },
  playerBtn: {
    marginRight: 23,
    cursor: 'pointer',
    ...centered,
    '& svg': {
      width: 25,
      height: 25,
    }
  },
  playerBigBtn: {
    marginRight: 18,
    cursor: 'pointer',
    ...centered,
    '& svg': {
      width: 32,
      height: 32,
    }
  },
  cover: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    '& img': {
      objectFit: 'cover',
      width: 77,
      height: 77,
      borderRadius: 4
    },
    '& > svg': {
      width: 45,
      height: 45,
      fill: colors.plug_grey,
      stroke: colors.plug_grey,
    }
  },
  actions: {
    display: 'flex',
  },
  actionBtn: {
    position: 'relative',
    marginLeft: 20,
    height: 28,
    cursor: 'pointer',
    '& svg': {
      width: 28,
      height: 28,
      // stroke: colors.icon_grey,
      fill: colors.icon_grey,
      '&:hover': {
        // stroke: colors.primary_yellow,
        fill: colors.primary_yellow,
      }
    },
  },
})

const STATUSES = {
  published: {
    class: 'statusPublished',
    text: 'Опубликовано'
  },
  pending: {
    class: 'statusPending',
    text: 'Загрузка...'
  },
  failed: {
    class: 'statusFailed',
    text: 'Не загружен'
  },
  deleted: {
    class: 'statusFailed',
    text: 'Не загружен'
  },
}

export const EpisodeCard = ({
  className,
  episode,
  podcastMaskedId,
  description,
  noActions = false,
}) => {
  const MORE_OPTIONS = [
    {
      title: 'Скрыть выпуск',
      clickHandler: () => { alert(episode.masked_id) }
    },
    {
      title: 'Редактировать выпуск',
      clickHandler: () => { alert(episode.masked_id) }
    },
    {
      title: 'Скопировать выпуск',
      clickHandler: () => { alert(episode.masked_id) }
    },
    {
      title: 'Удалить',
      clickHandler: () => { alert(episode.masked_id) }
    },
  ]

  const classes = useStyles()

  const [isPlaying, setIsPlaying] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const user =  useSelector(state => state.account.user)

  const handleClose = () => {
    setShowOptions(false)
  }

  const handleMenuClick = (e) => {
    setShowOptions(!showOptions)
    e.stopPropagation()
  }

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying)
  }

  const playerBtnClass = noActions
    ? 'playerBigBtn'
    : 'playerBtn'

  return (
    <div className={classnames(classes.card, className)}>
      <div className={classes.main}>
        <div className={classes.wrapper}>
          {/* <div className={classes[playerBtnClass]} onClick={handlePlayClick}>
            <Icon iconName={isPlaying ? 'pause_btn' : 'play_btn'} />
          </div> */}
          <div className={classes.cover}>
            {episode.image_orig
              ? <img src={episode.image_orig} alt="Episode Cover" />
              : <Icon iconName={'img_plug'} />
            }
          </div>
          <div className={classes.info}>
            <span className={classes.title}>{episode.title}</span>
            {isAdmin(user.user_type) && <span className={classes[STATUSES[episode.status].class]}>{STATUSES[episode.status].text}</span>}
            <p className={classes.notice}><strong>Опубликовано:</strong> {formattedDate(episode.published_at)}</p>
            <p className={classes.notice}>{description}</p>
          </div>
        </div>
        {!noActions && <div className={classes.actions}>
          <div className={classes.actionBtn}>
            <Link to={`/stats/${podcastMaskedId}/${episode.masked_id}`}>
              <Icon iconName='chart_btn' />
            </Link>
          </div>
          <div className={classes.actionBtn}>
            <Link to={`/podcasts/${podcastMaskedId}/episodes/${episode.masked_id}`}>
              <Icon iconName='dollar' />
            </Link>
          </div>
          {/* <div className={classes.actionBtn} onClick={handleMenuClick}>
            <More options={MORE_OPTIONS} show={showOptions} hide={handleClose} />
          </div> */}
        </div>
        }
      </div>
      <div className={classes.player}>

      </div>
    </div >
  )
}