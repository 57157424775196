import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { H1 } from "../../components/Typography";
import { Loader, Table, Notification, Pagination } from "../../components";
import { TableFilterBar } from "./TableFIlterBar";
import { Page, Template } from "../../containers";
import { useActions } from "../../hooks";
import { getSortIcon, getSortOrder } from "../../utils";
import { EditPodcastModal } from "./EditPodcastModal";
import { isEmpty } from "lodash";

const useStyles = createUseStyles({
  podcastsTable: {
    marginTop: 30
  }
})

export const PodcastSettingsPage = () => {
  const classes = useStyles()
  const { fetchAdminPodcastList, podcastStatusSwitch, editPodcast } = useActions()

  const [podcasts, setPodcasts] = useState([])
  const [editingPodcast, setEditingPodcast] = useState({})
  const [editingField, setEditingField] = useState('')
  const [editingValue, setEditingValue] = useState(null)

  const { podcastList, podcastGroups, podcastFilter, loading } = useSelector(state => state.admin)
  const { sortBy, sortOrder } = useSelector(state => state.admin.podcastFilter);

  useEffect(() => {
    fetchAdminPodcastList()
  }, [])

  useEffect(() => {
    if (podcastList.length) {
      let podcastsArr = []
      podcastList.map((podcast) => {
        podcastsArr.push(
          {
            columns: [
              { key: 'podcast_id', type: 'text', value: podcast.id },
              { key: 'masked_id', type: 'text200', value: podcast.masked_id },
              {
                key: 'pc_group',
                type: 'text',
                value: podcast.group.name,
                onCellClickHandler: () => handleCellClick(podcast, 'pc_group', {label: podcast.group.name, value: podcast.group.id})
              },

              {
                key: 'puid45',
                type: 'text',
                value: podcast.puid45,
                style: 'text_centered',
                onCellClickHandler: () => handleCellClick(podcast, 'puid45', podcast.puid45)
              },

              { key: 'title', type: 'text200', value: podcast.title },
              { key: 'username', type: 'text', value: podcast.user_name },
              { key: 'email', type: 'text', value: podcast.user_email },
              { key: 'status', type: 'text', value: podcast.status, style: podcast.status === 'published' ? 'textGreen' : 'textRed' },
              { key: 'actions', type: 'pc_actions', onPrimaryAction: (e) => handleSwitchPodcastStatus(e, podcast.id, podcast.title, 'published'), onSecondaryAction: (e) => handleSwitchPodcastStatus(e, podcast.id, podcast.title, 'pending') },
            ]
          }
        )
        setPodcasts(podcastsArr)
      })
    }
  }, [podcastList])

  const handleCellClick = (podcast, field, value) => {
    setEditingPodcast(podcast)
    setEditingField(field)
    setEditingValue(value)
  }

  const handleEditModalSave = (podcast_id, field = {}) => {
    editPodcast(podcast_id, field)
  }

  const handleCloseModal = () => {
    setEditingPodcast({})
    setEditingField('')
    setEditingValue(null)
  }

  const HEADERS = [
    { name: 'ID', colSpan: 1, sortIcon: getSortIcon('podcast', sortBy, sortOrder), onSort: () => fetchAdminPodcastList({ sortBy: 'podcast', sortOrder: getSortOrder('podcast', sortBy, sortOrder) }) },
    { name: 'Zayads ID', colSpan: 1 },
    { name: 'Группа', colSpan: 1 },
    { name: 'puid45', colSpan: 1 },
    { name: 'Название', colSpan: 1 },
    { name: 'Пользователь', colSpan: 1 },
    { name: 'Email', colSpan: 1 },
    { name: 'Статус', colSpan: 1, sortIcon: getSortIcon('status', sortBy, sortOrder), onSort: () => fetchAdminPodcastList({ sortBy: 'status', sortOrder: getSortOrder('status', sortBy, sortOrder) }) },
    { name: 'Действия', colSpan: 1 },
  ]

  const handleSwitchPodcastStatus = (e, podcast_id, podcast_title, status) => {
    if (window.confirm(`Подкаст "${podcast_title}" будет переведен в статус "${status}"`)) {
      podcastStatusSwitch(podcast_id, status)
    }
    e.stopPropagation()
  }

  const handleSearch = (filter) => {
    fetchAdminPodcastList({ filter: filter })
  }

  const handlePaginationChange = (pagination) => {
    fetchAdminPodcastList({ pagination: pagination })
  }

  return (
    <>
      <Template>
        {loading
          ? <Loader show={loading} />
          : <Page>
            <H1>Подкасты:</H1>
            <TableFilterBar handleSearch={handleSearch} />
            {!podcastList.length
              ? <div><H1>Список подкастов пуст.</H1></div>
              : <div>
                <div className={classes.podcastsTable}>
                  <Pagination
                    changePagination={handlePaginationChange}
                  />
                  <Table headers={HEADERS} items={podcasts} />
                </div>
              </div>
            }
            <Notification toast />
            {(!isEmpty(editingPodcast) && editingField) && <EditPodcastModal
              onClose={() => handleCloseModal()}
              onSave={handleEditModalSave}
              podcast={editingPodcast}
              field={editingField}
              value={editingValue}
              pc_groups={podcastGroups}
            />}
          </Page>
        }
      </Template>
    </>
  )
}