import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon, Label, Select, Error } from '../../../components'
import { colors, fonts, centered, justified, boxShadow } from '../../../theme'
import { formattedDate } from '../../../utils'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'

const useStyles = createUseStyles({
  datepicker: {
    background: colors.background_white,
    height: 36,
    border: [1, 'solid', colors.light_grey],
    borderRadius: 20,
    fontSize: 14,
    lineHeight: 22 / 14,
    position: 'relative',
    '& $datepicker': {
      height: 'auto',
      border: 'none',
      borderRadius: 0,
    },
    '& $select': {
      fontSize: 14,
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: colors.primary_yellow,
        '& + svg': {
          stroke: colors.light_grey,
        },
      },
      '& option': {
        color: colors.text_black,
      },
    },
    '&.disabled': {
      background: colors.background_white,
      '& > $date': {
        cursor: 'default',
      },
      '& > $icon': {
        cursor: 'default',
      },
    },
    '&.error': {
      background: colors.coral,
      borderColor: colors.coral,
    },
    '& + &': {
      marginLeft: 6,
    },
    '& input': {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      border: 'none',
      color: colors.text_black,
      fontFamily: fonts.notoSans,
      borderRadius: 'inherit',
      position: 'relative',
      zIndex: 1,
      opacity: 0,
      '&:disabled': {
        cursor: 'default',
      },
    },
    '& .react-datepicker-wrapper': {
      height: 'inherit',
    },
    '& .react-datepicker__input-container': {
      height: 'inherit',
    },
    '& .react-datepicker-popper': {
      paddingTop: 10,
      zIndex: 999,
    },
    '& .react-datepicker': {
      width: '100%',
      fontFamily: fonts.notoSans,
      borderRadius: 12,
      border: 'none',
    },
    '& .react-datepicker__triangle': {
      display: 'none',
    },
    '& .react-datepicker__month-container': {
      background: colors.background_white,
      float: 'none',
      borderRadius: 12,
      boxShadow: [
        [0, 4, 4, 'rgba(54, 75, 99, 0.05)'],
        [0, 5, 20, 'rgba(54, 75, 99, 0.1)'],
        [0, 30, 30, 'rgba(54, 75, 99, 0.1)'],
      ],
    },
    '& .react-datepicker__header': {
      background: colors.background_white,
      padding: [24, 24, 0, 24],
      borderRadius: [12, 12, 0, 0],
      border: 'none',
      textAlign: 'left',
    },
    '& .react-datepicker__day-names': {
      ...justified,
    },
    '& .react-datepicker__day-name': {
      width: 40,
      height: 40,
      margin: 0,
      padding: 0,
      color: colors.light_grey,
      fontSize: 12,
      lineHeight: 18 / 12,
      fontWeight: 600,
      ...centered,
    },
    '& .react-datepicker__month': {
      margin: 0,
      padding: [16, 24],
    },
    '& .react-datepicker__week': {
      ...justified,
    },
    '& .react-datepicker__day': {
      width: 40,
      height: 40,
      margin: 0,
      ...centered,
    },
    // '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range': {
    //   background: colors.primary_yellow,
    //   color: colors.background_white,
    //   borderRadius: '50%',
    // },
   
    '& .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range': {
      background: colors.light_grey,
      color: colors.primary_yellow,
      borderRadius: 0
    },
    '& .react-datepicker__day--today': {
      background: colors.light_grey,
      color: colors.background_white,
      borderRadius: '50%',
    },
    '& .react-datepicker__day--range-start, .react-datepicker__day--range-end': {
      background: colors.primary_yellow,
      color: colors.background_white,
      borderRadius: '50%',
    },
    '& .react-datepicker__day--keyboard-selected': {
      background: colors.primary_yellow,
      color: colors.background_white,
      borderRadius: '50%',
    },
    '& .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text': {
      borderRadius: '50%',
      transition: 'color 300ms ease, background 300ms ease',
      '&:hover': {
        background: colors.light_grey,
        color: colors.shade,
        borderRadius: '50%',
      },
    },
  },
  title: {
    fontSize: 18,
    lineHeight: 28 / 18,
    color: colors.text_black,
  },
  selects: {
    marginTop: 12,
    ...justified,
  },
  select: {},
  value: {
    padding: [0, 36, 2, 14],
    color: colors.text_black,
    fontSize: 14,
    lineHeight: '130%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    ...centered,
    justifyContent: 'left',
  },
  icon: {
    background: colors.primary_yellow,
    width: 36,
    stroke: colors.light_grey,
    border: [1, 'solid', colors.light_grey],
    borderRadius: [0, 20, 20, 0],
    position: 'absolute',
    top: -1,
    right: -1,
    bottom: 1,
    zIndex: 0,
    transition: 'border 300ms ease',
    ...centered,
    '&.error': {
      background: colors.coral,
      borderColor: colors.coral,
    },
    '&:hover:not(.disabled)': {
      borderColor: colors.primary_yellow,
      cursor: 'pointer',
      ...boxShadow,
      '& > svg': {
        stroke: colors.primary_yellow,
      },
    },
    '& > svg': {
      width: 20,
      height: 20,
      display: 'block',
      transition: 'stroke 300ms ease',
    },
  },
})

const YEARS = [...Array(3).keys()].map((_, index) => new Date().getFullYear() + 1 - index)
const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

export const Datepicker = ({
  className,
  id,
  prefix = '',
  value,
  label,
  disabled,
  name,
  onChange,
  minDate = moment().toDate(),
  maxDate,
  error = '',
  startDate,
  endDate,
}) => {
  const classes = useStyles()
  const CustomInput = forwardRef(({ value, onClick, disabled }, ref) =>
    <>
      <div className={classes.value}>
        от {formattedDate(startDate)} до {formattedDate(endDate)}
      </div>
      <div className={classnames(classes.icon, { disabled, error })} onClick={onClick} ref={ref}>
        <Icon iconName='calendar' />
      </div>
    </>
  )
  return (
    <>
      {label &&
        <Label htmlFor={id}>{label}</Label>
      }
      <div className={classnames(classes.datepicker, className, { disabled, error })}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          customInput={<CustomInput disabled={disabled} />}
          disabled={disabled}
          name={name}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          selectsRange={true}
          renderCustomHeader={({ date, changeYear, changeMonth }) =>
            <div className={classes.datepicker}>
              <div className={classes.title}>Выберите дату</div>
              <div className={classes.selects}>
                <Select
                  className={classes.select}
                  value={MONTHS[new Date(date).getMonth()]}
                  onChange={(e) => changeMonth(MONTHS.indexOf(e.target.value))}
                  options={MONTHS.map(month => ({ label: month, value: month }))}
                />
                <Select
                  className={classes.select}
                  value={new Date(date).getFullYear()}
                  onChange={(e) => changeYear(e.target.value)}
                  options={YEARS.map(year => ({ label: year, value: year }))}
                />
              </div>
            </div>
          }
        />
      </div>
      {error && <Error className={classnames(classes.errorMessage)}>{error}</Error>}
    </>
  )
}