import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { H1 } from "../../components/Typography";
import { Template, Page } from "../../containers";
import { BreadCrumbs, Button, Input, Icon, Hint, Selector, Datepicker, Table, Loader } from "../../components";
import { colors } from "../../theme";
import { useActions, usePrevious } from "../../hooks"
import { useLocation, useParams } from 'react-router'
import _, { groupBy, isEmpty } from "lodash"
import { formattedNumberRub, numberWithQuoteSeparator } from "../../utils";
import { useHistory } from "react-router";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
  PieChart,
  Pie
} from 'recharts'

const useStyles = createUseStyles({
  pageTitle: {

  },
  sectionTitle: {
    marginTop: 80
  },
  filterBlock: {
    marginTop: 34,
    display: 'flex',
  },
  filter: {
    marginRight: 5,
    width: 300,
    '&:last-child': {
      width: 500
    }
  },
  totalsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 34,
    '& > div:first-child': {
      margin: [0, 15, 10, 0],
      '& table': {
        minWidth: 1084,
        background: colors.primary_black,
        color: colors.background_white,
        lineHeight: '130%',
        borderRadius: 8,
        '& td': {
          borderRight: [1, 'solid', colors.background_white],
        }
      }
    }
  },
  getReportBtn: {
    display: 'block',
    width: 189,
    // margin: [0, 0, 0, 94]
  },
  cellWrapper: {
    width: 520,
    padding: [8, 16],
    display: 'flex',
    justifyContent: 'space-between'
  },
  totalsCount: {
    color: colors.primary_yellow
  },
  filterBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  groupBlock: {
    width: 245,
    margin: [10, 0, 0, 0],
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    '& > label': {
      marginRight: 9
    }
  },
  dateFilterBlock: {
    display: 'flex',
    marginTop: 10,
    marginRight: 43,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  datepickerBlock: {
    display: 'flex'
  },
  datepickerWrapper: {
    minWidth: 250,
    marginRight: 35,
  },
  datepickerLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    whiteSpace: 'nowrap'
  },
  dateBtns: {
    display: 'flex',
    '& > div:last-child': {
      marginRight: 0
    }
  },
  statsBlock: {
    marginTop: 20
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    padding: [30, 0],
    width: '100%'
  },
  chart: {
    height: 250,
    fontSize: 12,
    marginTop: 40,
    marginLeft: -40,
  },
  pieChart: {
    height: 350,
    width: 695,
    fontSize: 12,
    marginTop: 40,
  },
  pieChartBlock: {
    width: '50%'
  },
  pieChartGroup: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  pieChartPlaceholder: {
    '& > p': {
      marginTop: 10
    },
    '& > svg': {
      margin: [90, 0, 45, 70]
    },
  },
  barChartPlaceholder: {
    '& > p': {
      margin: [10, 0, 20, 0]
    },
    '& > svg': {
      marginLeft: '30%'
    },
  },
  statsViewCtrl: {
    display: 'flex',
    margin: [10, 0, 10, 0],
    alignItems: 'center',
    justifyContent: 'start',
    '& > p': {
      marginRight: 10
    }
  },
  chartTitle: {
    marginTop: 45
  }
})

const GROUP_BY = [
  { label: 'Источники', value: 'sources' },
]

const DATE_BTNS = [
  { label: 'сегодня', status: 'active', periodName: 'today' },
  { label: 'вчера', periodName: 'yesterday' },
  { label: 'неделя', periodName: 'last_week' },
  { label: 'месяц', periodName: 'last_month' },
  { label: 'год', periodName: 'last_year' },
  { label: 'за всё время', periodName: 'all_time' },
]

const COLORS = ['#EA6676', '#59aBBB', '#f79ee2', '#0088FE', '#00C49F', '#A79EE1', '#FFBB28', '#FF8042', '#594BBB', '#17BAEE'];

export const StatsPage = () => {
  const classes = useStyles()
  const {
    fetchPodcastList,
    fetchStats,
    fetchStatsTotals,
    resetStats,
    resetStatTotals
  } = useActions()
  const { podcastMaskedId, episodeMaskedId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [podcastList, setPodcastList] = useState([])
  const [episodeList, setEpisodeList] = useState([])
  const [selectedPodcast, setSelectedPodcast] = useState('')
  const [selectedEpisode, setSelectedEpisode] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [statsList, setstatsList] = useState([])
  const [totalListeners, setTotalListeners] = useState(0)
  const [showPreTotal, setShowPreTotal] = useState(0)
  const [showMidTotal, setShowMidTotal] = useState(0)
  const [showPostTotal, setShowPostTotal] = useState(0)
  const [currentPeriodName, setcurrentPeriodName] = useState('today')
  const [placeholder, setPlaceholder] = useState('Выберите период и сформируйте отчет')
  const [reportGenerated, setReportGenerated] = useState(false)
  const [isGenerateReportBtnDisabled, setisGenerateReportBtnDisabled] = useState(true)
  const [statsView, setStatsView] = useState('chart')
  const [listenersLegends, setListenersLegends] = useState([])
  const [showByPlacementLegends, setShowByPlacementLegends] = useState([])

  const podcasts = useSelector(state => state.podcast?.podcastList)
  const stats = useSelector(state => state.stats?.stats)
  const listenerStats = useSelector(state => state.stats?.listenerStats)
  const chartLegends = useSelector(state => state.stats?.chartLegends)
  const showsByPlacement = useSelector(state => state.stats?.showsByPlacement)
  const showsByPlacementLegend = useSelector(state => state.stats?.showsByPlacementLegend)
  const showsByPodcast = useSelector(state => state.stats?.showsByPodcast)
  const listenersByPodcast = useSelector(state => state.stats?.listenersByPodcast)
  const listenersByItunesCategory = useSelector(state => state.stats?.listenersByItunesCategory)
  const listenersByCities = useSelector(state => state.stats?.listenersByCities)
  const listenersByApps = useSelector(state => state.stats?.listenersByApps)
  const listenersByDevice = useSelector(state => state.stats?.listenersByDevice)
  const totals = useSelector(state => state.stats?.totals)
  const { loading } = useSelector(state => state.stats)

  const CRUMBS = [
    { name: 'Подкасты', path: '/podcasts' },
    { name: 'Общая статистика' },
  ];

  const getStats = () => {
    let filter = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      groupBy: selectedGroup,
      podcastMaskedId: !isEmpty(selectedPodcast) ? selectedPodcast.value : '',
      episodeMaskedId: !isEmpty(selectedEpisode) ? selectedEpisode.value : '',
    }
    resetStats()
    fetchStats(filter)
  }

  const getStatsTotals = () => {
    let filter = {
      podcastMaskedId: !isEmpty(selectedPodcast) ? selectedPodcast.value : '',
      episodeMaskedId: !isEmpty(selectedEpisode) ? selectedEpisode.value : '',
    }
    fetchStatsTotals(filter)
  }

  useEffect(() => {
    fetchPodcastList()
    return () => {
      resetStats()
      resetStatTotals()
    }
  }, [])

  useEffect(() => {
    if (listenerStats && listenerStats.length > 0) {
      let lTotal = 0

      listenerStats.map(el => lTotal = lTotal + el.listeners)
      setTotalListeners(lTotal)
    }
  }, [listenerStats])

  useEffect(() => {
    if (showsByPlacement && showsByPlacement.length > 0) {
      let showPreTotal = 0
      let showMidTotal = 0
      let showPostTotal = 0

      showsByPlacement.map(el => {
        showPreTotal = showPreTotal + el.show_pre_roll
        showMidTotal = showMidTotal + el.show_mid_roll
        showPostTotal = showPostTotal + el.show_post_roll
      })

      setShowPreTotal(showPreTotal)
      setShowMidTotal(showMidTotal)
      setShowPostTotal(showPostTotal)
    }
  }, [showsByPlacement])

  useEffect(() => {
    setSelectedEpisode('')
    setSelectedPodcast('')
  }, [location])

  useEffect(() => {
    if (selectedPodcast.value) {
      setisGenerateReportBtnDisabled(false)

      if (selectedPodcast.value === 'all') {
        setEpisodeList([])
      }
    } else {
      setisGenerateReportBtnDisabled(true)
    }
  }, [selectedPodcast.value])

  useEffect(() => {
    if (!isEmpty(podcasts)) {
      let podcastsArr = [{ label: 'По всем подкастам', value: 'all' }]
      let episodesArr = []

      podcasts.map((el) => {
        podcastsArr.push({ label: el.title, value: el.masked_id })
        if (isEmpty(episodeList)) {
          if (el.masked_id === podcastMaskedId) {
            if (!isEmpty(el.episodes)) {
              el.episodes.map((ep) => {
                episodesArr.push({ label: ep.title, value: ep.masked_id })
              })
              setEpisodeList(episodesArr)
            }
          }
        }
      })

      setPodcastList(podcastsArr)
    }
  }, [podcasts])

  useEffect(() => {
    if (isEmpty(selectedPodcast) && !isEmpty(podcastList)) {
      if (podcastMaskedId) {
        setSelectedPodcast(podcastList.filter(el => el.value === podcastMaskedId)[0])
      }
    }
  }, [podcastList])

  useEffect(() => {
    if (!isEmpty(episodeList) && episodeMaskedId) {
      setSelectedEpisode(episodeList.filter(el => el.value === episodeMaskedId)[0])
    }
  }, [episodeList])

  const prevSelected = usePrevious({ selectedPodcast, selectedEpisode })

  useEffect(() => {
    if (!isEmpty(selectedPodcast)) {
      getStatsTotals()
      setReportGenerated(true)
      getStats()
      if (selectedPodcast.value === 'all') {

      }
    }
  }, [selectedPodcast])

  useEffect(() => {
    if (!isEmpty(selectedPodcast) && !isEmpty(selectedEpisode)) {
      getStatsTotals()
      setReportGenerated(true)
      getStats()
    }
  }, [selectedEpisode])

  useEffect(() => {
    setListenersLegends(chartLegends)
  }, [chartLegends])

  useEffect(() => {
    setShowByPlacementLegends(showsByPlacementLegend)
  }, [showsByPlacementLegend])

  const handleSelectPodcast = (podcast) => {
    setSelectedPodcast(podcast)

    if (!isEmpty(selectedEpisode))
      setSelectedEpisode('')

    let episodesArr = []
    podcasts.map((el) => {
      if (el.masked_id === podcast.value) {
        if (!isEmpty(el.episodes)) {
          el.episodes.map((ep) => {
            episodesArr.push({ label: ep.title, value: ep.masked_id })
          })
          setEpisodeList(episodesArr)
          return
        }
      }
    })
  }

  const handleSelectEpisode = (ep) => {
    setSelectedEpisode(ep)
  }

  const handleSelectGroup = (ep) => {
  }

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  }

  const onGenerateReportButtonClick = () => {
    setReportGenerated(true)
    getStats()
  }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + 3;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) - 3;

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0) >= 1 ? (percent * 100).toFixed(0) : '<1'}%`}
      </text>
    );
  }

  const HEADERS = [
    { name: 'Дата', colSpan: 1 },
    { name: 'Прослушивания', colSpan: 1 },
    { name: 'Рекламные вставки', colSpan: 1 },
  ]

  useEffect(() => {
    if (!isEmpty(stats)) {
      let list = [];
      list = stats.map(item => {
        return {
          columns: [
            { key: 'date', type: 'text', value: item.date },
            { key: 'listeners', type: 'text', value: numberWithQuoteSeparator(item.listeners) },
            { key: 'show', type: 'text', value: numberWithQuoteSeparator(item.show) },
          ]
        }
      })

      setPlaceholder("")
      setstatsList(list)
    } else {
      setstatsList([])
      reportGenerated && setPlaceholder("Нет данных за выбранный период")
    }
  }, [stats])


  const setPeriod = (periodName) => {
    const today = new Date();
    let date = new Date();

    switch (periodName) {
      case 'today':
        setStartDate(new Date());
        setEndDate(new Date());
        setcurrentPeriodName('today')
        break;

      case 'yesterday':
        date.setDate(today.getDate() - 1)
        setStartDate(date);
        setEndDate(date);
        setcurrentPeriodName('yesterday')
        break;

      case 'last_week':
        date.setDate(today.getDate() - 7)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_week')

        break;

      case 'last_month':
        date.setDate(today.getDate() - 30)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_month')

        break;

      case 'last_year':
        date.setDate(today.getDate() - 365)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('last_year')

        break;

      case 'all_time':
        date.setDate(today.getDate() - 1000)
        setStartDate(date);
        setEndDate(new Date());
        setcurrentPeriodName('all_time')

        break;
      default:
        break;
    }
  }

  return (
    <Template>
      {loading
        ? <Loader show={loading} />
        : <Page>
          <BreadCrumbs crumbs={CRUMBS} />
          <H1 className={classes.pageTitle}>Количество прослушиваний подкаста и рекламных вставок</H1>
          <div className={classes.filterBlock}>
            <div className={classes.filter}>
              <Selector
                value={selectedPodcast}
                options={podcastList}
                onChange={handleSelectPodcast}
              />
            </div>
            <div className={classes.filter}>
              <Selector
                value={selectedEpisode}
                options={episodeList}
                onChange={handleSelectEpisode}
              />
            </div>
          </div>
          <div className={classes.totalsBlock}>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className={classes.cellWrapper}>
                        <span>Всего прослушиваний</span>
                        <span className={classes.totalsCount}>{numberWithQuoteSeparator(totals.listens)}</span>
                      </div>
                    </td>
                    <td>
                      <div className={classes.cellWrapper}>
                        <div>Всего рекламных вставок</div>
                        <div className={classes.totalsCount}>{numberWithQuoteSeparator(totals.shows)}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.getReportBtn}>
              <Button disabled={isGenerateReportBtnDisabled} className={'primary'} onClick={onGenerateReportButtonClick}>Сформировать отчет</Button>
            </div>
          </div>
          <div className={classes.filterBar}>
            <div className={classes.dateFilterBlock}>
              <div className={classes.datepickerBlock}>
                <div className={classes.datepickerLabel}>Данные за период</div>
                <div className={classes.datepickerWrapper}>
                  <Datepicker
                    className={classes.datepicker}
                    startDate={startDate}
                    endDate={endDate}
                    name='date'
                    prefix=''
                    value={''}
                    minDate={moment().subtract(365, 'day').toDate()}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className={classes.dateBtns}>
                {DATE_BTNS.map((btn, idx) => {
                  return <Button key={idx} className={'dateFilter'} status={btn.periodName === currentPeriodName ? 'active' : ''} onClick={() => setPeriod(btn.periodName)}>{btn.label}</Button>
                })}
              </div>
            </div>
            {/* <div className={classes.groupBlock}>
            <Selector
              label={'Группировать по:'}
              value={selectedGroup}
              options={GROUP_BY}
              onChange={handleSelectGroup}
            />
          </div> */}
          </div>
          <div className={classes.statsViewCtrl}>
            <p>Вид:</p>
            <Button className={'toggle'} status={statsView === 'chart' ? 'active' : ''} onClick={() => setStatsView('chart')}>График</Button>
            <Button className={'toggle'} status={statsView !== 'chart' ? 'active' : ''} onClick={() => setStatsView('table')}>Таблица</Button>
          </div>
          {(statsView === 'chart' && !isEmpty(stats))
            ? <>
              <H1 className={classes.chartTitle}>Прослушивания</H1>
              {!isEmpty(listenerStats)
                ? <>
                  <div className={classes.chart}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart data={listenerStats}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey='date' />
                        <YAxis tickFormatter={(num) => numberWithQuoteSeparator(num)} />
                        <Tooltip cursor={{ fill: colors.form_grey }} />
                        <Legend />
                        {listenersLegends && listenersLegends.map((item, index) => {
                          if (item.field === 'listeners')
                            return <Bar
                              dataKey={item.field}
                              name={item.label}
                              fill={item.color}
                              key={index} />
                        })}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div>
                    <p style={{ fontWeight: '500' }}>Итого: <strong>{numberWithQuoteSeparator(totalListeners)}</strong></p>
                  </div>
                </>
                : <div className={classes.barChartPlaceholder}>
                  <p>Данные отсутствуют</p>
                  <Icon iconName='stat_chart' />
                </div>
              }
              <H1 className={classes.chartTitle}>Рекламные вставки</H1>
              {!isEmpty(showsByPlacement)
                ? <>
                  <div className={classes.chart}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart data={showsByPlacement}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' />
                        <YAxis tickFormatter={(num) => numberWithQuoteSeparator(num)} />
                        <Tooltip cursor={{ fill: colors.form_grey }} />
                        <Legend />
                        {showByPlacementLegends && showByPlacementLegends.map((item, index) => {
                          return <Bar
                            dataKey={item.field}
                            name={item.label}
                            stackId="a"
                            fill={item.color}
                            key={index} />
                        })}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div style={{ lineHeight: '130%' }}>
                    <p>pre-roll: <strong>{numberWithQuoteSeparator(showPreTotal)}</strong></p>
                    <p>mid-roll: <strong>{numberWithQuoteSeparator(showMidTotal)}</strong></p>
                    <p>post-roll: <strong>{numberWithQuoteSeparator(showPostTotal)}</strong></p>
                    <p style={{ fontWeight: '500' }}>Всего вставок: <strong>{numberWithQuoteSeparator(showPreTotal + showMidTotal + showPostTotal)}</strong></p>
                  </div>
                </>
                : <div className={classes.barChartPlaceholder}>
                  <p>Данные отсутствуют</p>
                  <Icon iconName='stat_chart' />
                </div>
              }
              <H1 className={classes.sectionTitle}>Статистика по всем вашим подкастам</H1>
              <div className={classes.pieChartGroup}>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Прослушивания по подкастам</H1>
                  {!isEmpty(listenersByPodcast)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={listenersByPodcast}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {listenersByPodcast.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Рекламные вставки по подкастам</H1>
                  {!isEmpty(showsByPodcast)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={showsByPodcast}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {showsByPodcast.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Прослушивания по категориям</H1>
                  {!isEmpty(listenersByItunesCategory)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={listenersByItunesCategory}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {listenersByItunesCategory.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Прослушивания по городам</H1>
                  {!isEmpty(listenersByCities)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={listenersByCities}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {listenersByCities.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Прослушивания по приложениям</H1>
                  {!isEmpty(listenersByApps)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={listenersByApps}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {listenersByApps.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
                <div className={classes.pieChartBlock}>
                  <H1 className={classes.chartTitle}>Прослушивания по устройствам</H1>
                  {!isEmpty(listenersByDevice)
                    ? <div className={classes.pieChart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            legendType="rect"
                            dataKey="value"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            data={listenersByDevice}
                            cx={120}
                            cy={120}
                            innerRadius={40}
                            outerRadius={120}
                          >
                            {listenersByDevice.map((item, index) => {
                              return <Cell key={`cell-${index}`} fill={COLORS[Math.abs(index - (COLORS.length - 1))]} />
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend width={300} align="right" verticalAlign="end" layout="vertical" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    : <div className={classes.pieChartPlaceholder}>
                      <p>Данные отсутствуют</p>
                      <Icon iconName='stat_chart' />
                    </div>
                  }
                </div>
              </div>
            </>
            : <div className={classes.statsBlock}>
              {placeholder
                ? <div className={classes.placeholder}>
                  <H1>{placeholder}</H1>
                </div>
                : <Table headers={HEADERS} items={statsList} />
              }
            </div>}
        </Page>
      }
    </Template>
  )
}
