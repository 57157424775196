import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: 'fit-content',
    maxWidth: 1390,
    minWidth: 957,
    paddingBottom: 150,
    paddingTop: 22,
  },
})

export const Page = ({
  className,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.page, className)}>
      {children}
    </div>
  )
}