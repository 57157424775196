import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { H1, H4 } from "../../components/Typography";
import { Template, Page } from "../../containers";
import { BreadCrumbs, ActionBar, FilterBar, Button, PodcastTile, Icon } from "../../components";
import { colors } from "../../theme";

const useStyles = createUseStyles({
  actionBar: {

  },
})

export const PodcastsCreatePage = () => {
  return (
    <Template>
      <Page>
        <H1>Создание нового подкаста</H1>
      </Page>
    </Template>
  )
}