import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { H1, H4 } from '../../../components/Typography'
import { colors } from '../../../theme'
import { Icon, SeriesChip } from '../../../components'
import { CategoriesBlock } from '../CategoriesBlock'

const useStyles = createUseStyles({
  info: {
    marginBottom: 48,
  },
  subtitle: {
    margin: [10, 0, 26, 0],
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    borderRadius: 8,
    background: colors.light_grey,
  },
  description: {
    fontSize: 13,
    lineHeight: '150%',
    marginBottom: 16
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    marginBottom: 16,
  },
  cover: {
    width: 92,
    height: 92,
    marginRight: 16,
    '& img': {
      width: 92,
      height: 92,
      borderRadius: 8,
      objectFit: 'cover',
    },
    '& > svg': {
      width: 92,
      height: 92,
      fill: colors.plug_grey,
      stroke: colors.plug_grey,
    }

  },
  mainWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    // paddingBottom: 15,
  },
  mainInfo: {
    minWidth: 160,
    // maxHeight: 77,
    marginRight: 15,
    '& > p': {
      marginBottom: 5,
      color: colors.dark_grey,
      fontSize: 12,
      lineHeight: '150%',
    },
  },
  summary: {
    marginBottom: 15,
    fontSize: 14,
    lineHeight: '130%',
  },
  rssUrl: {
    '& > a': {
      cursor: 'text',
      textDecoration: 'underline'
    }
  },
  titleWrapper: {
    display: 'flex',
    marginBottom: 12,
  },
  podcastTitle: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: '130%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chips: {
    marginLeft: 8,
  },
  podcastActions: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'end',
  },
  actionBtn: {
    marginLeft: 20,
    '& svg': {
      width: 21,
      height: 21,
      fill: colors.icon_grey,
      '&:hover': {
        fill: colors.primary_yellow,
      }
    },
  },
  categoriesBlock: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: 26
    }
  }
})

export const InfoSection = ({
  podcast,
  className,
}) => {
  const classes = useStyles()

  return <div className={classes.info}>
    <H1>Параметры рекламы подкаста</H1>
    <H4 className={classes.subtitle}>
      Управление настройками рекламы в подкасте. Для редактирования параметров эпизода необходимо перейти на страницу эпизода.
    </H4>
    <div className={classes.container}>
      <div className={classes.flexRow}>
        <div className={classes.cover}>
          {podcast.cover
            ? <img src={podcast.cover} alt="Podcast Cover" />
            : <Icon iconName={'img_plug'} />
          }
        </div>
        <div className={classes.mainWrapper}>
          <div className={classes.infoWrapper}>
            <div className={classes.mainInfo}>
              <div className={classes.titleWrapper}>
                <H4 className={classes.podcastTitle}>{podcast.title}</H4>
                <div className={classes.chips}>
                  <SeriesChip count={podcast.episodes?.length} type={'counter'} />
                </div>
              </div>
              <div className={classes.summary}>{podcast.summary}</div>
              <p className={classes.rssUrl}>RSS URL: <a>{podcast.rss}</a></p>
              <p>Дата создания: {podcast.created}</p>
            </div>
            <div className={classes.podcastActions}>
              <div className={classes.actionBtn}>
                <Link to={'/stats/' + podcast.masked_id}>
                  <Icon iconName='chart_btn' />
                </Link>
              </div>
              {/* <div className={classes.actionBtn}>
                <Link to='/'>
                  <Icon iconName='edit' />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.description}>
        <p>{podcast.description}</p>
      </div>
      <div className={classes.categoriesBlock}>
        {/* <CategoriesBlock title='Категория подкаста из RSS' categories={podcast.rssCategory} /> */}
        <CategoriesBlock title='Категории iTunes из RSS:' categories={podcast.itunesCategories} />
      </div>
    </div>
  </div>
}
