import React, {useState} from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Input } from '../Form/Input' 
import { colors } from '../../theme'

const useStyles = createUseStyles({
  search: {
    
  },
  searchInput: {
    background: colors.background_white,
    height: 38,
    borderRadius: 20
  },
})

export const Search = ({ 
  className,
  placeholder
}) => {
  const classes = useStyles()

  const [query, setQuery] = useState('')

  const onCHangeHandler = (e) => {
    setQuery(e.target.value)
  }

  return (
    <div className={classnames(classes.searchInput, className)}>
      <Input
        className={classes.searchInput}
        type='search'
        value={query}
        placeholder={placeholder}
        onChange={onCHangeHandler}
      />
    </div>
  )
}