import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Link, useHistory } from 'react-router-dom'
import { colors } from '../../theme'
import { Icon } from '../Icon'

const useStyles = createUseStyles({
  breadcrumbs: {
    display: 'flex',
    height: 16,
    marginBottom: 34
  },
  crumb: {
    fontSize: 12,
    color: colors.text_black,
    lineHeight: '130%',
    marginRight: 14,
  },
  separator: {
    '& > svg': {
      stroke: colors.icon_grey,
      width: 4,
      height: 10
    }
  },
  current: {
    '& > svg': {
      stroke: colors.primary_yellow
    }
  },
  active: {
    '& > a, > div': {
      color: colors.text_black,
    }
  },
  link: {
    color: colors.dark_grey,
  }
})

export const BreadCrumbs = ({ crumbs }) => {
  const classes = useStyles()
  const history = useHistory()

  const buildCrumbs = () => {
    if (crumbs?.length) {
      let elementList = []

      crumbs.forEach((crumb, idx) => {
        if (crumb.name) {
          const activeCrumb = (crumb.path && (history.location.pathname == crumb.path)) ? 'active' : ''

          elementList.push(
            <div key={idx} className={classnames(classes.crumb, classes[activeCrumb])}>
              {crumb.path 
                ? <Link to={crumb.path} className={classes.link}>{crumb.name}</Link>
                : <div>{crumb.name}</div>
              }
            </div>
          )
          if (idx !== (crumbs.length - 1)) {
            const activeSep = (idx == (crumbs.length - 2)) ? 'current' : ''
            elementList.push(
              <div key={idx + 'q'} className={classnames(classes.crumb, classes.separator, classes[activeSep])}>
                <Icon iconName='breadcrumb_separator' />
              </div>
            )
          }
        }

      })

      return elementList
    }
  }

  const crumbsList = buildCrumbs();

  return (
    <div className={classnames(classes.breadcrumbs)}>
      {crumbsList}
    </div>
  )
}