import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Loader, Fieldset, Input, Button, Selector } from "../../components";
import { useActions } from "../../hooks";

const useStyles = createUseStyles({
  addExternalSystemForm: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    marginBottom: 30,
  },
  fieldset: {
    marginTop: '0!important',
    margin: [0, 30, 30, 0]
  },
  addBtn: {
    marginBottom: 35
  },
  error: {
    position: 'absolute',
    left: 0,
    bottom: -24,
  },
})

const ERRORS = {
  required: 'Это обязательное поле.'
}

export const AddEridForm = ({advertiserList}) => {
  const classes = useStyles()
  const history = useHistory()

  const [erid, setErid] = useState('')
  const [title, setTitle] = useState('')
  const [advertiser, setAdvertiser] = useState('')
  const [campaign, setCampaign] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(0)

  const { eridErrors, loading } = useSelector(state => state.admin)

  const { addErid, clearAdminState } = useActions()

  useEffect(() => {
    let errors_arr = {}
    if (!erid.trim() || eridErrors.erid) errors_arr.erid = eridErrors.erid ? eridErrors.erid : ERRORS.required
    if (!title.trim() || eridErrors.title) errors_arr.title = eridErrors.title ? eridErrors.title : ERRORS.required
    if (!advertiser || eridErrors.advertiser) errors_arr.advertiser = eridErrors.advertiser ? eridErrors.advertiser : ERRORS.required

    setErrors(errors_arr);
  }, [erid, title, advertiser])

  const isValid = erid !== '' && title !== '' && advertiser.value !== ''

  const onAddErid = () => setSubmitted(submitted + 1)

  useEffect(() => {
    if (submitted > 0 && isValid) {
      const formData = {
        erid: erid,
        title: title,
        advertiser: advertiser.value,
        campaign: campaign,
      }

      clearAdminState()
      addErid(formData)
    }
    // eslint-disable-next-line
  }, [submitted])

  const INPUTS = [
    {
      size: 'medium',
      type: 'text',
      name: 'erid',
      label: 'ЕРИД',
      placeholder: '',
      value: erid,
      onChange: (e) => {
        setErid(e.target.value);
      },
      error: submitted > 0 && errors.systemName,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'text',
      name: 'title',
      label: 'Название ЕРИД',
      placeholder: '',
      value: title,
      onChange: (e) => {
        setTitle(e.target.value);
      },
      error: submitted > 0 && errors.systemName,
      errorClassName: classes.error,
    },
    {
      size: 'medium',
      type: 'text',
      name: 'campaign',
      label: 'Название РК (для отчетов)',
      placeholder: '',
      value: campaign,
      disabled: true,
      onChange: (e) => {
        setCampaign(e.target.value);
      },
      error: submitted > 0 && errors.systemName,
      errorClassName: classes.error,
    },
    {
      size: 'large',
      type: 'select',
      name: 'advertiser',
      label: 'Рекламодатель (email пользователя)',
      options: advertiserList.map(adv => ({label: adv.email, value: adv.id})),
      placeholder: '',
      value: advertiser,
      onChange: (adv) => {
        setAdvertiser(adv);
      },
      error: submitted > 0 && errors.advertiser,
      errorClassName: classes.error,
    },
  ]

  return (
    <>
      {loading
        ? <Loader />
        : <div className={classes.addExternalSystemForm}>
          {INPUTS.map((item, index) =>
            <Fieldset className={classes.fieldset} key={index}>
               {item.type === 'select'
                ? <Selector height={48} width={250} {...item} />
                : <Input {...item} />
              }
            </Fieldset>
          )}
          <div className={classes.addBtn}>
            <Button size='medium' className={'primary'} onClick={onAddErid}>Добавить ЕРИД</Button>
          </div>
        </div>}
    </>
  )
}